import React, { useState, useContext } from "react";
import { Modal, Alert } from "react-bootstrap";
import clsx from "clsx";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { axiosAPI } from "../../../utils";

import { useHistory } from "react-router-dom";
import { Button } from "../../atoms";

export const CancelSubscription = ({
  isEdit,
  show,
  title,
  handleClose,
  handleCancelSubscription,
  ...rest
}) => {
  const [selectedEndDay, setSelectedEndDay] = useState(null);
  const [selectedStartDay, setSelectedStartDay] = useState(null);
  const { push } = useHistory();

  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);

  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      aria-labelledby="updateCard"
      className={clsx(
        "updateCard",
        openStart || openEnd ? "updateCard_hidden" : "updateCard_visible"
      )}
      {...rest}
    >
      <Modal.Title id="updateCard">{title}</Modal.Title>

      <Modal.Body style={{ alignItems: "normal" }}>
        <h6 style={{ textAlign: "center" }}>
          Are You sure You wont to cancel your Subscription
        </h6>
        <Button
          style={{ width: "100%", marginTop: 20, backgroundColor: "red" }}
          onClick={handleCancelSubscription}
        >
          Cancel Subscription
        </Button>
      </Modal.Body>
    </Modal>
  );
};
