import React from "react";

export const Fluid = (props) => (
  <div className="row">
    <div
      className="col-lg-12 fluid-wrapper py-0"
      style={{
        backgroundColor: props.bgcolor || "#ffffff",
        padding: props.noPadding ? "15px" : "50px 15px 50px",
      }}
    >
      {props.children}
    </div>
  </div>
);
