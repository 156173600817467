import { useState, useContext } from "react";

// react icons
import { FaPlus } from "react-icons/fa";

// context
// hooks
import { usePaginationItemIndex } from "../../../hooks";

// atoms
import { Button, Ternary } from "../../atoms";

// molecules
import { Pagination, JobCard, AddJob, EmptyData } from "../../molecules";
import { AuthContext } from "../../../context/AuthContext";

export const JobsCards = ({ isUser = false, title, jobs }) => {
  const [show, setShow] = useState(false);
  const { auth } = useContext(AuthContext);
  const { firstTwoHundred, hasSubscription, isTrial } = auth;

  const handleToggle = (value) => () => setShow(value);

  // hooks
  const { firstItemIndex, lastItemIndex } = usePaginationItemIndex();

  return (
    <div className="jobs">
      <div className="d-flex flex-row justify-content-between align-items-baseline">
        <h3 className="jobs__title">{title}</h3>
        {!isUser && (
          <Button
            onClick={handleToggle(true)}
            icon={<FaPlus className="ml-2" />}
            className="jobs__add d-flex flex-row justify-content-between align-items-center"
            disabled={
              !(
                firstTwoHundred == true ||
                hasSubscription == true ||
                isTrial == true
              )
            }
          >
            New
          </Button>
        )}
      </div>
      <AddJob
        show={show}
        title="Add new job"
        handleClose={handleToggle(false)}
      />
      <div className="jobs__jobsWrapper">
        <Ternary condition={jobs.length !== 0} fallback={<EmptyData />}>
          {jobs
            .slice(firstItemIndex || 0, lastItemIndex || jobs.length)
            .map((job, idx) => (
              <JobCard key={idx} data={job} />
            ))}
        </Ternary>
      </div>

      <Pagination defaultPage={1} numberOfItems={jobs.length} />
    </div>
  );
};
