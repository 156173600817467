// react router
import { Link } from 'react-router-dom';

import moment from 'moment';

// atoms
import { JobCardImage } from '../../atoms';
import { useAuthorization } from '../../../hooks/useAuthorization';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

export const JobCard = ({ data }) => {
  const {
    auth: { status: authStatus },
  } = useAuthorization();
  const {
    location: { search },
  } = useHistory();
  const { status: jobStatus } = queryString.parse(search);
  let jobStatusTitle;
  if (jobStatus === 'active') jobStatusTitle = 'Active';
  else if (jobStatus === 'upcoming') jobStatusTitle = 'Upcoming';
  else jobStatusTitle = 'Past';

  return (
    // Change the to path later
    !!data && (
      <>
        <Link
          to={`/jobs/${data.company ? data.id : data.job.id}`}
          className="job__card"
        >
          <div className="job__card__wrapper">
            <JobCardImage
              src={
                (data &&
                  (data.company
                    ? data.company.image
                    : data.job.company.image)) ||
                'https://worqman.fra1.digitaloceanspaces.com/images/job-placeholder.jpg'
              }
            />
            <div className="job__card__info">
              {authStatus === 1 ? (
                <>
                  {jobStatus && (
                      <h4 className={jobStatusTitle}>{jobStatusTitle}</h4>
                    )}
                  {data && data.name && <h3>{data && data.name}</h3>}
                  <p>{moment(data.start).format('MMM D')}</p>
                    
                </>
              ) : (
                <>
                  {jobStatus && (
                    <h4 className={jobStatusTitle}>{jobStatusTitle}</h4>
                  )}
                  {data && data.job && <h3>{data && data.job.name}</h3>}
                  {data && data.status && data && data.status === 1 ? (
                    <h4>Accepted</h4>
                  ) : (
                    <h4>Declined</h4>
                  )}
                </>
              )}
            </div>
          </div>
        </Link>
      </>
    )
  );
};
