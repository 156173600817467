// atoms
import { NotificationItem } from '../../atoms';

export const NotificationList = ({ data }) => (
  <ul className="notification__list overflow-y">
    {data.map( 
      ({
        id: jobId,
        status: jobStatus,
        seen: seen,
        user: { name: userName },
        job: {id:id , name: jobName}, 
        seen_by_company: seen_by_company,
        seen_by_crew: seen_by_crew,
       
      }) => (

        <NotificationItem
          key={jobId}
          jobStatus={jobStatus}
          seen={seen}
          jobId={jobId}
          jobName={jobName}
          userName={userName}
          id = {id}
          seen_by_company = {seen_by_company}
          seen_by_crew = {seen_by_crew}
        />
      )
    )}
  </ul>
);
