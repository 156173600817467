import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Calendar } from "react-modern-calendar-datepicker";
import { Button } from "../index";
import dayjs from "dayjs";
import toObject from "dayjs/plugin/toObject";

dayjs.extend(toObject);

export const UserAvailableDates = ({
  show,
  currentDate,
  title,
  value,
  onChange,
  handleClose,
  ...props
}) => {
  const disabledDays = (e) => {
    const dateObj = e.split("-");
    return { year: +dateObj[0], month: +dateObj[1], day: +dateObj[2] };
  };

  const [selectedDay, setSelectedDay] = useState(
    currentDate ? disabledDays(dayjs(currentDate).format("YYYY-MM-DD")) : value
  );

  const handleDateToString = (date) => `${date.year}-${date.month}-${date.day}`;
  const handleDateToJSON = (date) => {
    return dayjs(handleDateToString(date)).add(1, "hour").format();
  };
  const handleSubmit = () => {
    onChange(handleDateToJSON(selectedDay));
    handleClose();
  };

  return (
    <Modal
      size="lg"
      centered
      show={show}
      aria-labelledby="available-dates"
      className="availableDates p-0"
      {...props}
    >
      <Modal.Title id="available-dates" className="mb-4">
        {title}
      </Modal.Title>

      <Modal.Body className="d-flex flex-column justify-content-center align-items-center w-100 p-0">
        <Calendar
          className="crew-calendar"
          value={selectedDay}
          onChange={setSelectedDay}
          shouldHighlightWeekends
        />
      </Modal.Body>
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <Button variant="outlined" onClick={handleClose} className="m-3 py-2">
          Cancel
        </Button>
        <Button
          disabled={!selectedDay}
          onClick={handleSubmit}
          className="m-3 py-2"
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};
