import React from "react";

export const EasyUseIcon = () => (
  <svg
    id="Capa_1"
    enableBackground="new 0 0 511.615 511.615"
    height="512"
    viewBox="0 0 511.615 511.615"
    fill="#78849E"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m404.365 176.499c-8.956 0-17.199 3.093-23.735 8.255-2.558-18.693-18.621-33.146-38.005-33.146-10.28 0-19.628 4.063-26.524 10.669-5.906-13.601-19.468-23.136-35.216-23.136-8.79 0-16.893 2.978-23.37 7.969v-73.862c0-21.157-17.212-38.37-38.37-38.37s-38.37 17.213-38.37 38.37v124.083c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-124.083c0-12.886 10.484-23.37 23.37-23.37s23.37 10.484 23.37 23.37v168.21c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-63.948c0-12.886 10.484-23.37 23.37-23.37s23.37 10.483 23.37 23.37v63.948c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-51.48c0-12.886 10.483-23.37 23.37-23.37s23.37 10.484 23.37 23.37v51.48c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-26.59c0-12.886 10.484-23.37 23.37-23.37s23.37 10.483 23.37 23.37v110.502l-.036 31.867c-.362 26.193-5.385 51.812-14.931 76.146l-2.391 6.095c-1.597 4.072-2.407 8.354-2.407 12.729v51.908c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-51.908c0-2.492.461-4.931 1.371-7.25l2.391-6.096c10.207-26.02 15.579-53.413 15.966-81.512l.036-31.971v-110.51c.001-21.157-17.212-38.369-38.369-38.369z" />
      <path d="m220.816 435.026c-21.146-4.642-39.523-17.04-51.744-34.909l-80.751-118.067c-7.357-10.758-5.345-25.15 4.681-33.477 10.293-8.55 25.453-7.531 34.51 2.32l47.476 51.629c3.364 3.659 8.532 4.857 13.163 3.051s7.624-6.185 7.624-11.156v-65.124c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v57.362l-42.221-45.916c-14.471-15.736-38.689-17.366-55.137-3.705-16.018 13.303-19.233 36.296-7.478 53.483l80.75 118.066c13.898 20.321 34.556 34.63 58.385 40.504v55.026c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-57.576c.001-5.492-3.893-10.333-9.258-11.511z" />
      <path d="m288.073 83.484c0 11.023-2.557 21.564-7.6 31.33-1.9 3.681-.458 8.205 3.223 10.105 1.101.568 2.276.837 3.435.837 2.716 0 5.338-1.48 6.67-4.06 6.152-11.915 9.272-24.771 9.272-38.212 0-18.279-5.806-35.642-16.79-50.211-2.493-3.307-7.196-3.967-10.504-1.474-3.307 2.494-3.967 7.196-1.474 10.504 9.008 11.947 13.768 26.187 13.768 41.181z" />
      <path d="m152.048 125.757c1.159 0 2.334-.27 3.435-.838 3.68-1.9 5.123-6.425 3.222-10.105-5.043-9.765-7.6-20.306-7.6-31.33 0-37.762 30.722-68.484 68.484-68.484 11.486 0 22.852 2.903 32.869 8.395 3.631 1.992 8.191.661 10.182-2.971 1.992-3.632.662-8.19-2.971-10.182-12.221-6.7-26.08-10.242-40.08-10.242-46.034 0-83.484 37.451-83.484 83.484 0 13.442 3.12 26.298 9.273 38.212 1.332 2.581 3.954 4.061 6.67 4.061z" />
      <path d="m110.045 142.959c1.079 0 2.175-.234 3.214-.728 3.741-1.778 5.332-6.252 3.554-9.994-7.27-15.294-10.955-31.697-10.955-48.753s3.686-33.459 10.955-48.753c1.778-3.741.187-8.215-3.554-9.994-3.743-1.779-8.215-.187-9.994 3.554-8.233 17.322-12.407 35.891-12.407 55.192s4.174 37.871 12.407 55.192c1.285 2.703 3.975 4.284 6.78 4.284z" />
      <path d="m325.92 142.231c1.039.494 2.135.728 3.214.728 2.805 0 5.495-1.581 6.779-4.283 8.233-17.323 12.407-35.892 12.407-55.192s-4.174-37.869-12.407-55.192c-1.778-3.742-6.252-5.333-9.993-3.555s-5.333 6.252-3.555 9.993c7.269 15.295 10.955 31.698 10.955 48.753s-3.686 33.458-10.955 48.753c-1.778 3.743-.186 8.217 3.555 9.995z" />
    </g>
  </svg>
);
