// router
import { useHistory } from "react-router-dom";

// hook form
import { useForm } from "react-hook-form";

import queryString from "query-string";

// react icons
import { BsBriefcase } from "react-icons/bs";
import { TiLocationArrowOutline } from "react-icons/ti";

// atoms
import { Button, InputField } from "../../atoms";

import { useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { SelectField } from "../../../components/atoms/index";
import { MdOutlineLocationOn } from "react-icons/md";
import { ranges } from "../../../data";

export const JobSearch = (props) => {
  const { push } = useHistory();
  const { register, handleSubmit } = useForm();
  const [address, setAddress] = useState(null);
  const [range, setRange] = useState("2");

  const handleSelect = (address) => {
    setAddress(address);
  };
  const onSubmit = ({ job }) => {
    if (!job && !address) return;
    push({
      pathname: "/search/results",
      search: `${queryString.stringify({
        ...(job && { job }),
        ...(address && { address }),
        ...(range && { range }),
      })}`,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={props.className}>
      {/* <div
        className="search__job"
        style={{ position: "relative", marginBottom: 24 }}
      >

      </div> */}
      <div className="search__job" style={{ position: "relative" }}>
        <div className="search__job__input_control">
          <InputField
            icon={<BsBriefcase />}
            divider="true"
            placeholder="Search jobs"
            {...register("job")}
          />
        </div>

        <PlacesAutocomplete
          value={address}
          onChange={(value) => setAddress(value)}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="search--location" style={{ position: "relative" }}>
              <div className="search__job__input_control">
                <InputField
                  icon={<TiLocationArrowOutline />}
                  divider="true"
                  placeholder="Town, city or postcode"
                  {...register("location")}
                  {...getInputProps({
                    placeholder: "Location",
                    className: "location-search-input",
                  })}
                />{" "}
              </div>
              {loading && (
                <div className="autocomplete-dropdown-container">
                  <div style={{ padding: "15px 32px" }}>Loading...</div>
                </div>
              )}

              {suggestions && suggestions.length >= 1 && (
                <div className="autocomplete-dropdown-container">
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        style={{
                          padding: "15px 32px",
                          cursor: "pointer",
                        }}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </PlacesAutocomplete>
        <div className="search__job__input_control">
          <SelectField
            onChange={(e) => setRange(e.target.value)}
            name="job"
            className="w-100"
            icon={<MdOutlineLocationOn />}
            options={ranges}
            value={range}
          />
        </div>

        <Button type="submit">Find Jobs</Button>
      </div>
    </form>
  );
};
