// react icons
import { MdModeEdit, MdDelete } from "react-icons/md";

// classnames
import clsx from "clsx";

// atoms
import { Button } from "../../atoms";

export const Feeds = ({ children }) => <div className="feeds">{children}</div>;

export const Feed = ({ icon, editMode, children, className }) => (
  <div className={clsx("feed", className)}>
    {!editMode && <span className="feed__line" />}
    <div className={clsx("feed__icon", editMode && "feed__icon__edit")}>
      {icon}
    </div>
    <div className="feed__content">{children}</div>

    {editMode && (
      <FeedActions>
        <Button variant="text" icon={<MdModeEdit />} iconOnly />
        <Button variant="text" icon={<MdDelete />} iconOnly />
      </FeedActions>
    )}
  </div>
);

export const FeedTitle = ({ children }) => (
  <p className="feed__title">{children}</p>
);

export const FeedDescription = ({ children }) => (
  <p className="feed__description">{children}</p>
);

export const FeedHelptext = ({ children }) => (
  <p className="feed__helptext">{children}</p>
);

export const FeedActions = ({ children }) => (
  <div className="feed__actions">{children}</div>
);
