import { useState } from "react";
import { FiSettings } from "react-icons/fi";
import { Button, Wallet } from "../../atoms";

// components
import { CrewProfileActions } from "./CrewProfileActions";
import { AccountSettings } from "../AccountSettings/AccountSettings";
import { useHistory } from "react-router-dom";

export const ProfileActions = (props) => {
  const [show, setShow] = useState(false);
  const history = useHistory();

  const handleToggle = (value) => () => setShow(value);

  console.log(process.env.NODE_ENV);

  return (
    <div className="profileActions">
      {props.userRole === 2 && !!props.user && (
        <CrewProfileActions
          isCrew={props.isCrew}
          user={props.user}
          isAuthentication={props.isAuthentication}
        />
      )}
      {props.isAuthentication && (
        <>
          <Button
            onClick={handleToggle(true)}
            variant="text"
            className="profileActions_chat my-2 py-1"
          >
            <FiSettings /> <span>Account settings</span>
          </Button>
          <AccountSettings
            title="Your personal information"
            show={show}
            user={props.user}
            handleClose={handleToggle(false)}
          />
          {props.userRole !== 2 && process.env.REACT_APP_PRODUCTION == "false" && (
            <Button
              onClick={() => history.push("/subscription")}
              variant="text"
              className="profileActions_chat my-2 py-1"
            >
              <Wallet /> <span>Subscription</span>
            </Button>
          )}
        </>
      )}

      {/* {props.userRole === 1 && props.isAuthentication && (
        <Button
          onClick={props.handleLogout}
          variant="text"
          className="px-2 py-1 my-2"
        >
          Logout
        </Button>
      )} */}

      {/* {props.userRole === 2 && props.isAuthentication && !!props.handleLogout && (
        <Button
          onClick={props.handleLogout}
          variant="text"
          className="px-2 py-1 my-2"
        >
          Logout
        </Button>
      )} */}
    </div>
  );
};
