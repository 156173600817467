import { useHistory, useLocation } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Button } from "../../atoms";

export const SearchResultsCard = ({
  id,
  image,
  name,
  slug,
  city,
  description,
  location,
}) => {
  console.log('name', name)
  console.log('image', image)
  // hooks
  const { push } = useHistory();
  const { auth } = useAuthorization();
  const search = useLocation().search;
  const job = new URLSearchParams(search).get("job");

  // will change later with propper path
  const handleViewProfile = () =>
    push(auth.status === 2 ? `/jobs/${id}` : `/crew/${slug}?job=${job}`);

  return (
    <div className="search__results__card">
      <img
        src={
          
                image
                ? image
                : auth.status === 1
                ? "https://worqman.fra1.digitaloceanspaces.com/images/profile-placeholder.png"
                : "https://worqman.fra1.digitaloceanspaces.com/images/job-placeholder.jpg"
        }
        alt="card profile"
      />
      <div className="card__info">
        <h3>{name}</h3>
        {city && <p>{city}</p>}
        {location && <p className="mb-2">{location}</p>}
        {description && <p>{description}</p>}
      </div>
      <Button variant="outlined" onClick={handleViewProfile}>
        {auth.status === 1 ? "see profile" : "see job"}
      </Button>
    </div>
  );
};
