import React, { forwardRef, useState, useEffect } from "react";

// icons
import { MdModeEdit } from "react-icons/md";

export const AvatarInput = forwardRef((props, ref) => {
  // local state
  const [filePreview, setFilePreview] = useState(props.preview || "");

  // handler
  const handlePreview = (file) => setFilePreview(URL.createObjectURL(file));

  const handleOnChange = (event) => {
    if (event?.target.files?.length) {
      handlePreview(event?.target.files[0]);
    }
    props.onChange && props.onChange(event);
  };

  useEffect(() => {
    if (props.preview) setFilePreview(props.preview);
  }, [setFilePreview, props]);

  return (
    <div className="avatar_label">
      {!!filePreview && (
        <img src={filePreview} alt={`File Preview ${filePreview}`} />
      )}
      <MdModeEdit height="20" width="20" />
      <label htmlFor={props.name}>Add an image</label>
      <input
        onChange={handleOnChange}
        ref={ref}
        type="file"
        name={props.name}
        id={props.name}
        aria-label="input file"
      />
    </div>
  );
});
