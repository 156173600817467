import React from "react";
import { NavLink } from "react-router-dom";
import { Data } from "./Data";
import { CheckIcon } from "../../../components/atoms/index";

const PricingCard = () => {
  return (
    <div className="pricing-card">
      {Data?.length &&
        Data.map(({ title, price, info }) => (
          <div key={title} className="pricing-card-content">
            <h1>{title}</h1>
            <div className="pricing-card-price-content">
              <h1>{price}</h1>
              <p>/ user</p>
            </div>
            <hr className="pricing-card-hr" />
            <div className="pricing-card-info-content">
              <span>
                <CheckIcon />
              </span>
              <p>{info}</p>
            </div>
            <div className="pricing-card-button">
              <NavLink to="/">
                <button>Choose Plan </button>
              </NavLink>
            </div>
          </div>
        ))}
    </div>
  );
};

export default PricingCard;
