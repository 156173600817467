import React from "react";

export const Container = (props) => (
  <div className="row">
    <div className="container">
      <div className="row justify-content-center">
        <div
          className="col-lg-12 container-wrapper"
          style={{
            backgroundColor: props.bgColor || "#ffffff",
            padding: props.noPadding ? "15px" : "50px 15px 50px",
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  </div>
);
