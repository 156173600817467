import React from "react";

export const ExploreIcon = () => (
  <svg
    id="compass"
    xmlns="http://www.w3.org/2000/svg"
    width="22.682"
    height="22.682"
    viewBox="0 0 22.682 22.682"
  >
    <g id="Group_763" data-name="Group 763">
      <g id="Group_762" data-name="Group 762">
        <path
          id="Path_8785"
          data-name="Path 8785"
          d="M191.114,192.361a1.248,1.248,0,1,0-1.248-1.248A1.248,1.248,0,0,0,191.114,192.361Z"
          transform="translate(-179.772 -179.772)"
          fill="#bab8c6"
        />
        <path
          id="Path_8786"
          data-name="Path 8786"
          d="M11.341,0A11.341,11.341,0,1,0,22.682,11.341,11.338,11.338,0,0,0,11.341,0Zm2.484,13.825L4.536,18.146,8.857,8.858l9.288-4.321Z"
          fill="#bab8c6"
        />
      </g>
    </g>
  </svg>
);
