import { useState, useCallback } from "react";

// router
import { useHistory } from "react-router-dom";

// query string
import queryString from "query-string";

// atoms
import { Loading, Ternary } from "../../components/atoms";

// molecules
import { HeaderBar, EmptyData, JobsTabs } from "../../components/molecules";

// organisms
import { JobsCards } from "../../components/organisms";

import { axiosAPI } from "../../utils";
import { useEffect } from "react";
import { useAuthorization } from "../../hooks/useAuthorization";

export const Jobs = () => {
  const [jobsData, setJobsData] = useState([]);
  const [loading, setLoading] = useState(true);

  // hooks
  const {
    location: { search },
  } = useHistory();
  const {
    auth: { token, status },
  } = useAuthorization();

  // query string
  const { status: jobStatus } = queryString.parse(search);

  const getData = useCallback(async () => {
    try {
      if (token) {
        if (status === 1) {
            // const { data } = await axiosAPI.get("/job/company-jobs-dropdown", {
            //   headers: { Authorization: `Bearer ${token}` },
            // });
            // setJobsData(data.reverse());
            // setLoading(false);
            let pathRequest;
            switch (jobStatus) {
              case "active":
                pathRequest = "/job/company-current-jobs";
                break;
              case "upcoming":
                pathRequest = "/job/company-upcoming-jobs";
                break;
              case "past":
                pathRequest = "/job/company-previous-jobs";
                break;
              default:
                console.log("Reached the end");
            }
             const { data } = await axiosAPI.get(pathRequest, {
               headers: { Authorization: `Bearer ${token}` },
             });

              setJobsData(data.jobs);
              setLoading(false);
          
        } else {
          if (jobStatus) {
            let pathRequest;
            switch (jobStatus) {
              case "active":
                pathRequest = "/job/user-current-jobs";
                break;
              case "upcoming":
                pathRequest = "/job/user-upcoming-jobs";
                break;
              case "past":
                pathRequest = "/job/user-previous-jobs";
                break;
              default:
                console.log("Reached the end");
            }
            const { data } = await axiosAPI.get(pathRequest, {
              headers: { Authorization: `Bearer ${token}` },
            });
            setJobsData(data);
            setLoading(false);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [jobStatus, status, token]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (loading) return <Loading />;

  return (
    <>
      <HeaderBar title="Jobs" />
      {status === 2 ? (
        <>
          <JobsTabs />
          <Ternary condition={jobsData?.length > 0} fallback={<EmptyData />}>
            <div className="job__cards">
              <JobsCards isUser={true} jobs={jobsData} />
            </div>
          </Ternary>
        </>
      ) : (
        <>
        <JobsTabs />
        <Ternary
          condition={jobsData?.length > 0}
          fallback={<EmptyData addButton buttonPlaceholder="Jobs" />}
        >
          <JobsCards title="All Jobs" jobs={jobsData} />
        </Ternary>
        </>
      )}
    </>
  );
};
