/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaBuilding, FaLayerGroup } from "react-icons/fa";
import { InputField } from "..";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { axiosAPI } from "../../../utils";
import { WorkIcon, Button, UserAvailableDates, TextareaField } from "../index";
import clsx from "clsx";
import { BsCalendarEvent } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

export const EditWorkExperience = (props) => {
  // local state
  const [selectedEndDay, setSelectedEndDay] = useState(props.experience.end);
  const [selectedStartDay, setSelectedStartDay] = useState(
    props.experience.start
  );
  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);

  const { push } = useHistory();

  const {
    auth: { token },
  } = useAuthorization();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // handler
  const handleStartDate = (value) => () => setOpenStart(value);
  const handleEndDate = (value) => () => setOpenEnd(value);

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      id: props.experience.id,
      start: selectedStartDay,
      ...(selectedEndDay && { end: selectedEndDay }),
    };

    try {
      if (!!formData) {
        let { data } = await axiosAPI.put(
          "experience/update-experience",
          formData,
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        if (data.id === props.experience.id) {
          if (props.editMode) {
            push("/profile");
          } else {
            props.handleSetExperience(formData);
          }
          props.handleClose();
          reset();
        }
      }
    } catch (err) {
      return console.error(err);
    }
  };

  useEffect(() => {
    if (props.experience) {
      reset({
        position: props.experience.position,
        description: props.experience.description,
        company: props.experience.company,
      });
    }
  }, [props.experience]);

  return (
    <Modal
      size="lg"
      centered
      show={props.show}
      onHide={props.handleClose}
      aria-labelledby="AddWorkExperience"
      className={clsx(
        "addJob AddWorkExperience",
        openStart || openEnd ? "addJob_hidden" : "addJob_visible"
      )}
    >
      <Modal.Header closeButton className="w-100">
        <Modal.Title id="projects">Edit your Work Experience</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column w-100">
        <form className="w-100">
          <InputField
            {...register("position", { required: true })}
            placeholder="Position of the job"
            icon={<WorkIcon />}
          />
          {errors.position && (
            <div className="error-message">Position is required</div>
          )}
          <InputField
            {...register("company", { required: true })}
            placeholder="Company"
            icon={<FaBuilding />}
          />
          {errors.company && (
            <div className="error-message">Company is required</div>
          )}
          <TextareaField
            maxLength={200}
            {...register("description", { required: true })}
            placeholder="Description"
            icon={<FaLayerGroup />}
          />
          {errors.description && (
            <div className="error-message mb-2">Description is required</div>
          )}

          <div className="d-flex flex-row justify-content-start align-items-center w-100 my-3">
            <label htmlFor="start-date">
              <button
                type="button"
                className="addJob_btn"
                onClick={handleStartDate(true)}
              >
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <BsCalendarEvent className="mr-2" />
                  <p className="m-0 mr-1">Start date </p>
                  {selectedStartDay && (
                    <p className="m-0">
                      {" - "}
                      {dayjs(selectedStartDay).format("DD-MM-YYYY")}
                    </p>
                  )}
                </div>
              </button>

              <UserAvailableDates
                currentDate={props.experience && props.experience.start}
                id="start-date"
                title="Start date"
                show={openStart}
                onChange={setSelectedStartDay}
                value={selectedStartDay}
                handleClose={handleStartDate(false)}
              />
            </label>
            <label htmlFor="end-date" className="mx-2">
              <button
                type="button"
                className="addJob_btn"
                onClick={handleEndDate(true)}
              >
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <BsCalendarEvent className="mr-2" />
                  <p className="m-0 mr-1">End date </p>
                  {selectedEndDay && (
                    <p className="m-0">
                      {" - "}
                      {dayjs(selectedEndDay).format("DD-MM-YYYY")}
                    </p>
                  )}
                </div>
              </button>

              <UserAvailableDates
                id="end-date"
                title="End date"
                show={openEnd}
                onChange={setSelectedEndDay}
                value={selectedEndDay}
                handleClose={handleEndDate(false)}
                {...(!!props.experience &&
                  props.experience?.end && {
                    currentDate: props.experience?.end,
                  })}
              />
            </label>
          </div>

          <Button
            type="button"
            onClick={handleSubmit(onSubmit)}
            className="my-2"
            fullWidth
          >
            Submit
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};
