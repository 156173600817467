import React, { useEffect, useState, useContext } from "react";
import { axiosAPI } from "../../utils/axios";
import { useAuthorization } from "../../hooks/useAuthorization";
import {
  Button,
  Wrapper,
  CheckIcon,
  Loading,
  OpenPage,
} from "../../components/atoms";
import moment from "moment";
import { Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

// molecules
import {
  HeaderBar,
  SearchBar,
  UpdateCard,
  CancelSubscription,
} from "../../components/molecules";

function Index({ history }) {
  const {
    auth: { token },
  } = useAuthorization();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [firstTwoHundred, setFirstTwoHundred] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const { login } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    if (token) {
      axiosAPI
        .get(`/stripe/dashboard`, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((res) => {
          if (res.data.data.hasSubscription == false) {
            history.push("/stripe/subscription");
          }
          setCustomer(res.data?.data?.customer);
          setSubscription(res.data?.data?.subscription);
          setInvoices(res.data?.data?.invoices);
          setPaymentMethod(res.data?.data?.paymentMethod);
          setFirstTwoHundred(res.data?.data?.firstTwoHundred);
          setLoading(false);
        });
    }
  }, [token]);

  const handleCancelSubscription = () => {
    setLoading(true);
    axiosAPI
      .delete(`/stripe/subscription`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        axiosAPI
          .get(`/stripe/dashboard`, {
            headers: { Authorization: "Bearer " + token },
          })
          .then((res) => {
            setCustomer(res.data?.data?.customer);
            setSubscription(res.data?.data?.subscription);
            setInvoices(res.data?.data?.invoices);
            setPaymentMethod(res.data?.data?.paymentMethod);
            setFirstTwoHundred(res.data?.data?.firstTwoHundred);
            setCancelSubscription(false);
            login({
              isTrial: true,
              hasSubscription: false,
              firstTwoHundred: res.data?.data?.firstTwoHundred,
            });
            setLoading(false);
          });
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <HeaderBar title="Subscription" />
      <UpdateCard
        show={open}
        title="Update Card"
        handleClose={() => setOpen(false)}
      />
      <CancelSubscription
        show={cancelSubscription}
        title="Cancel Subscription"
        handleClose={() => setCancelSubscription(false)}
        handleCancelSubscription={handleCancelSubscription}
      />
      {firstTwoHundred ? (
        <div className="subscription">
          <div className=" wrapper">
            <h6 style={{ textAlign: "center" }}>
              You are one of our first Companies. For you, all of our services
              will be Subscription Free.
            </h6>
          </div>
        </div>
      ) : (
        <>
          <div className="subscription row">
            <div className="subscription_wrapper col-6">
              <h3 className="title"> Customer Data</h3>
              <div className="wrapper">
                <p>
                  <span className="title">E-mail:</span>{" "}
                  {customer?.email ? customer.email : "-"}
                </p>
                <p>
                  <span className="title">Address:</span>
                  {customer?.address ? customer.address : "-"}
                </p>
                <p>
                  <span className="title">Currency:</span>
                  {customer?.currency ? (
                    <span style={{ textTransform: "uppercase" }}>
                      {customer.currency}
                    </span>
                  ) : (
                    "-"
                  )}
                </p>
                <p>
                  <span className="title">Balance:</span>
                  {customer?.balance ? customer.balance : "-"}
                </p>
                <p>
                  <span className="title">Invoice Prefix:</span>
                  {customer?.invoice_prefix ? customer.invoice_prefix : "-"}
                </p>
                <p>
                  <span className="title">Created:</span>
                  {customer?.created
                    ? moment(Number(customer.created) * 1000).format(
                        "DD/MM/YYYY"
                      )
                    : "-"}
                </p>
              </div>
            </div>
            <div className="subscription_wrapper col-6">
              <h3 className="title"> Subscription Data</h3>
              <div className="wrapper">
                <p>
                  <span className="title">Status:</span>{" "}
                  {subscription?.status ? (
                    <span style={{ textTransform: "uppercase" }}>
                      {subscription.status}
                    </span>
                  ) : (
                    "-"
                  )}
                </p>
                <p>
                  <span className="title">Current Period:</span>
                  {subscription?.current_period_start
                    ? moment(
                        Number(subscription.current_period_start) * 1000
                      ).format("DD/MM/YYYY")
                    : "-"}
                  {subscription?.current_period_end
                    ? `-${moment(
                        Number(subscription.current_period_end) * 1000
                      ).format("DD/MM/YYYY")}`
                    : "-"}
                </p>
                <p>
                  <span className="title">Created:</span>
                  {subscription?.created
                    ? moment(Number(subscription.created) * 1000).format(
                        "DD/MM/YYYY"
                      )
                    : "-"}
                </p>
                <p>
                  <span className="title">Amount:</span>
                  {subscription?.plan?.amount
                    ? subscription?.plan?.amount / 100
                    : "-"}
                </p>
                <p>
                  <span className="title">Interval:</span>
                  {subscription?.plan?.interval ? (
                    <span style={{ textTransform: "uppercase" }}>
                      {subscription?.plan?.interval}
                    </span>
                  ) : (
                    "-"
                  )}
                </p>
                <p>
                  <span className="title">Canceled:</span>
                  {subscription?.canceled_at
                    ? moment(Number(subscription.canceled_at) * 1000).format(
                        "DD/MM/YYYY"
                      )
                    : "-"}
                </p>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => {
                    history.push("/stripe/subscription");
                  }}
                >
                  Change Subscription
                </Button>
                <Button
                  onClick={() => setCancelSubscription(true)}
                  style={{
                    width: "100%",
                    backgroundColor: "red",
                    marginTop: 20,
                  }}
                  disabled={subscription?.status == "canceled"}
                >
                  Cancel Subscription
                </Button>
              </div>
            </div>

            <div className="subscription_wrapper col-6">
              <h3 className="title"> Billing Details</h3>
              <div className="wrapper">
                <p>
                  <span className="title">Email:</span>
                  {paymentMethod?.billing_details.email ? (
                    <span>{paymentMethod?.billing_details.email}</span>
                  ) : (
                    "-"
                  )}
                </p>
                <p>
                  <span className="title">Name:</span>
                  {paymentMethod?.billing_details.name ? (
                    <span>{paymentMethod?.billing_details.name}</span>
                  ) : (
                    "-"
                  )}
                </p>
                <p>
                  <span className="title">Phone:</span>
                  {paymentMethod?.billing_details.phone ? (
                    <span>{paymentMethod?.billing_details.phone}</span>
                  ) : (
                    "-"
                  )}
                </p>
              </div>
            </div>
            <div className="subscription_wrapper col-6">
              <h3 className="title"> Credit Card Details</h3>
              <div className="wrapper">
                <p>
                  <span className="title">Brand:</span>
                  {paymentMethod?.card.brand ? (
                    <span style={{ textTransform: "uppercase" }}>
                      {paymentMethod?.card.brand}
                    </span>
                  ) : (
                    "-"
                  )}
                </p>
                <p>
                  <span className="title">Name:</span>
                  {paymentMethod?.card.last4 ? (
                    <span>************{paymentMethod?.card.last4}</span>
                  ) : (
                    "-"
                  )}
                </p>
                <p>
                  <span className="title">Expire Year:</span>
                  {paymentMethod?.card.exp_year ? (
                    <span>{paymentMethod?.card.exp_year}</span>
                  ) : (
                    "-"
                  )}
                </p>
                <p>
                  <span className="title">Expire Month:</span>
                  {paymentMethod?.card.exp_month ? (
                    <span>{paymentMethod?.card.exp_month}</span>
                  ) : (
                    "-"
                  )}
                </p>
                <Button onClick={() => setOpen(true)} style={{ width: "100%" }}>
                  Update Card
                </Button>
              </div>
            </div>
            <div className="subscription_wrapper col-12">
              <h3 className="title"> Latest Invoices</h3>
              <div className="wrapper">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Created</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th style={{ textAlign: "end" }}>Invoice</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices?.data.map((el) => (
                      <tr>
                        <td>
                          {el?.created
                            ? moment(Number(el.created) * 1000).format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </td>
                        <td>
                          {el?.amount_due ? Number(el.amount_due / 100) : "-"}
                        </td>
                        <td>{el?.paid ? "Paid" : "Not Paid"}</td>
                        <td style={{ textAlign: "end" }}>
                          <a
                            href={el.invoice_pdf}
                            target="_blank"
                            style={{ paddingTop: 0 }}
                          >
                            <OpenPage />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default withRouter(Index);
