import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

const NewsBox = (props) => {
  return (
    <div className={`${props.class} news-box`}>
      <img
        src={props.featured_image}
        alt={props.title}
        className="news-box__image"
      />
      <p className="news-box__date">{props.date}</p>
      <p className="news-box__title">{props.title}</p>
      <NavLink
        to={`/blog/${props.slug}`}
        className="btn-c btn--white icon--right"
      >
        Read More
        <FontAwesomeIcon icon={faLongArrowAltRight} />
      </NavLink>
    </div>
  );
};

export default NewsBox;
