import React from "react";

export const BellIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 19.127 22.954"
  >
    <g id="bell" transform="translate(-42.675)">
      <g id="Group_771" data-name="Group 771" transform="translate(42.675)">
        <g id="Group_770" data-name="Group 770">
          <path
            id="Path_8793"
            data-name="Path 8793"
            d="M61.735,19.36l-1.642-2.737a8.131,8.131,0,0,1-1.159-4.183v-2.4A6.7,6.7,0,0,0,54.151,3.63V1.913a1.913,1.913,0,0,0-3.826,0V3.63a6.7,6.7,0,0,0-4.782,6.413v2.4a8.136,8.136,0,0,1-1.158,4.182l-1.642,2.737a.479.479,0,0,0,.409.725H61.324a.478.478,0,0,0,.41-.724Z"
            transform="translate(-42.675)"
            fill="#454f63"
          />
        </g>
      </g>
      <g
        id="Group_773"
        data-name="Group 773"
        transform="translate(49.227 21.041)"
      >
        <g id="Group_772" data-name="Group 772">
          <path
            id="Path_8794"
            data-name="Path 8794"
            d="M188.815,469.333a3.327,3.327,0,0,0,6.023,0Z"
            transform="translate(-188.815 -469.333)"
            fill="#454f63"
          />
        </g>
      </g>
    </g>
  </svg>
);
