import React from "react";

export const CommunicateIcon = () => (
  <svg
    id="question_1_"
    data-name="question (1)"
    xmlns="http://www.w3.org/2000/svg"
    width="63.242"
    height="63.235"
    viewBox="0 0 63.242 63.235"
  >
    <g id="Group_309" data-name="Group 309" transform="translate(0 21.489)">
      <path
        id="Path_1460"
        data-name="Path 1460"
        d="M42.732,191.908a1.235,1.235,0,0,0-1.235,1.235v8.522a6.182,6.182,0,0,1-6.175,6.175H21.988a1.235,1.235,0,0,0-1.235,1.235v2.456l-2.948-2.948a1.236,1.236,0,0,0-1.132-.742H8.645a6.182,6.182,0,0,1-6.175-6.175v-19.02a6.182,6.182,0,0,1,6.175-6.175H21.119a1.235,1.235,0,0,0,0-2.47H8.645A8.655,8.655,0,0,0,0,182.645v19.019a8.655,8.655,0,0,0,8.645,8.646h7.393l5.073,5.072a1.235,1.235,0,0,0,2.108-.873v-4.2h12.1a8.655,8.655,0,0,0,8.645-8.645v-8.52a1.235,1.235,0,0,0-1.236-1.238Z"
        transform="translate(0 -174)"
        fill="#78849e"
      />
    </g>
    <g id="Group_310" data-name="Group 310" transform="translate(24.206)">
      <path
        id="Path_1461"
        data-name="Path 1461"
        d="M217.077,0h-3.126a17.951,17.951,0,1,0,0,35.9h3.126a17.985,17.985,0,0,0,4.561-.581l4.621,4.615a1.235,1.235,0,0,0,2.108-.874V31.909a18.122,18.122,0,0,0,4.718-5.817,17.757,17.757,0,0,0,1.951-8.142A17.971,17.971,0,0,0,217.077,0Zm9.3,30.324a1.236,1.236,0,0,0-.492.99v4.768l-3.023-3.02a1.235,1.235,0,0,0-1.236-.307,15.485,15.485,0,0,1-4.553.681h-3.126a15.48,15.48,0,1,1,0-30.961h3.126a15.48,15.48,0,0,1,9.3,27.854Z"
        transform="translate(-196)"
        fill="#78849e"
      />
    </g>
    <g id="Group_311" data-name="Group 311" transform="translate(38.837 8.652)">
      <path
        id="Path_1462"
        data-name="Path 1462"
        d="M324.906,74.924a5.241,5.241,0,0,0-4.848-4.848,5.188,5.188,0,0,0-3.935,1.393,5.248,5.248,0,0,0-1.661,3.823,1.235,1.235,0,0,0,2.47,0,2.759,2.759,0,1,1,3.365,2.694,2.337,2.337,0,0,0-1.841,2.291v2.971a1.235,1.235,0,1,0,2.47,0V80.374A5.2,5.2,0,0,0,324.906,74.924Z"
        transform="translate(-314.462 -70.062)"
        fill="#78849e"
      />
    </g>
    <g
      id="Group_312"
      data-name="Group 312"
      transform="translate(42.829 25.483)"
    >
      <path
        id="Path_1463"
        data-name="Path 1463"
        d="M348.908,206.692a1.236,1.236,0,1,0,.362.874,1.236,1.236,0,0,0-.362-.874Z"
        transform="translate(-346.799 -206.33)"
        fill="#78849e"
      />
    </g>
    <g id="Group_313" data-name="Group 313" transform="translate(6.669 38.286)">
      <path
        id="Path_1464"
        data-name="Path 1464"
        d="M81.294,310H55.235a1.235,1.235,0,1,0,0,2.47H81.294a1.235,1.235,0,1,0,0-2.47Z"
        transform="translate(-54 -310)"
        fill="#78849e"
      />
    </g>
    <g
      id="Group_314"
      data-name="Group 314"
      transform="translate(32.729 45.202)"
    >
      <path
        id="Path_1465"
        data-name="Path 1465"
        d="M267.108,366.362a1.235,1.235,0,1,0,.362.874A1.235,1.235,0,0,0,267.108,366.362Z"
        transform="translate(-265.001 -366.001)"
        fill="#78849e"
      />
    </g>
    <g id="Group_315" data-name="Group 315" transform="translate(6.669 45.201)">
      <path
        id="Path_1466"
        data-name="Path 1466"
        d="M76.437,366h-21.2a1.235,1.235,0,1,0,0,2.47h21.2a1.235,1.235,0,0,0,0-2.47Z"
        transform="translate(-54 -366)"
        fill="#78849e"
      />
    </g>
    <g id="Group_316" data-name="Group 316" transform="translate(6.669 31.369)">
      <path
        id="Path_1467"
        data-name="Path 1467"
        d="M73.267,254H55.235a1.235,1.235,0,1,0,0,2.47H73.267a1.235,1.235,0,1,0,0-2.47Z"
        transform="translate(-54 -254)"
        fill="#78849e"
      />
    </g>
  </svg>
);
