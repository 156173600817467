export const pdfs = [
  {
    label: "CSCS Card",
    value: "CSCS Card",
  },
  {
    label: "Forklift Licence",
    value: "Forklift Licence",
  },
  {
    label: "Right to work document",
    value: "Right to work document",
  },
  {
    label: "Passport",
    value: "Passport",
  },
  {
    label: "Driving Licence",
    value: "Driving Licence",
  },
  {
    label: "IPAF card",
    value: "IPAF card",
  },
  {
    label: "CPCS/NPORS card",
    value: "CPCS/NPORS card",
  },
];
