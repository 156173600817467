import { useCallback, useEffect, useState, useContext } from "react";

// contexts
import { AuthContext } from "../../context/AuthContext";

// router
import { useParams } from "react-router-dom";

import { Loading } from "../../components/atoms";

// molecules
import {
  AvatarProfile,
  CreateProfile,
  ProfileActions,
} from "../../components/molecules";

// organisms
import { ProfileDetails } from "../../components/organisms";

// templates
import { ProfileLayout } from "../../components/templates";

import { axiosAPI, setStorageData, getStorageData } from "../../utils";

export const Profile = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const [user, setUser] = useState(null);
  const [activeJobs, setActiveJobs] = useState(0);

  // context
  const { auth, isLoggedIn, logout } = useContext(AuthContext);

  const getProfileData = useCallback(
    async (statusRole, slug) => {
      try {
        if (!!auth.image) {
          const { data } = await axiosAPI.get(
            `${statusRole === 1 ? "/companies/company" : "users/user"}-by-slug`,
            { params: { slug } }
          );
          setUser(statusRole === 1 ? data.company : data.user);
        } else {
          const { data } = await axiosAPI.get(
            `${statusRole === 1 ? "companies/company" : "users/user"}`,
            { headers: { authorization: `Bearer ${auth.token}` } }
          );
console.log("ss",data);
          setUser(statusRole === 1 ? data.company : data.user);
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    },
    [auth.image, auth.token]
  );
  const getCompanyActiveJobs = useCallback(async () => {
    try {
      if (auth.token) {
        const { data } = await axiosAPI.get("/job/company-current-jobs", {
          headers: { authorization: `Bearer ${auth.token}` },
        });
        setActiveJobs(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [auth.token]);

  useEffect(() => {
    if (!!user && (!!user.city || !!user.website)) {
      setStorageData("completed-account", !!user.city || !!user.website);
    }
  }, [user]);

  useEffect(() => {
    if (auth.token) {
      getCompanyActiveJobs();
    }
    if (!!auth.status) {
      getProfileData(auth.status, id || auth.slug);
    }
  }, [auth, id, getProfileData, getCompanyActiveJobs]);

  const handleClose = () => setShow(false);

  if (loading) {
    return <Loading />;
  }

  return (
    <ProfileLayout isAuthentication={isLoggedIn} userRole={auth.status}>
      <>
        {!!user && (
          <>
            <div className="d-flex flex-column align-items-center justify-content-start col-xs-12 col-sm-6 col-md-5 col-lg-5 py-5 md-py-2 border-end">
              <AvatarProfile
                src={
                  user.image ||
                  "https://worqman.fra1.digitaloceanspaces.com/images/profile-placeholder-3.png"
                }
              />

              <h3 className="h3 text-center my-3 mt-5">
                {user.status === 1 ? user?.name : user.name}
              </h3>

              {user.status === 1 ? (
                <h6 className="h6 m-0">{activeJobs.count} active jobs</h6>
              ) : (
                user.user_professions && (
                  <h6 className="h6 m-0 mb-2">
                    {user.user_professions.map((i) => ` ${i.name}`).toString()}
                  </h6>
                )
              )}
              <p className="m-0">{user?.city}</p>

              <ProfileActions
                user={user}
                isAuthentication={isLoggedIn}
                userRole={auth.status}
                handleLogout={logout}
              />
            </div>
            <ProfileDetails
              user={user}
              isAuthentication={isLoggedIn}
              userRole={auth.status}
            />
          </>
        )}

        <CreateProfile
          name={auth.name}
          token={auth.token}
          user={user}
          roleuser={auth.status}
          show={show && !getStorageData("completed-account")}
          handleclose={handleClose}
        />
      </>
    </ProfileLayout>
  );
};
