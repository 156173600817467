/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const RelatedPosts = () => {
  const [posts, setPosts] = useState([{}]);
  const [cats, setCats] = useState([{}]);

  useEffect(() => {
    getCats();
    getPosts();
  }, []);

  const getCats = () => {
    axios
      .get(`${process.env.REACT_APP_WP}/v2/categories`)
      .then((res) => {
        setCats(res.data);
      })
      .catch((err) => {});
  };

  const getPosts = () => {
    axios
      .get(`${process.env.REACT_APP_WP}/v2/10?posts_per_page=3`)
      .then((res) => {
        setPosts(res.data.posts);
      })
      .catch((err) => {});
  };
  return (
    <div className="col-lg-4">
      <div className="sidebar">
        <h6 className="sidebar--title">Search News</h6>

        <div className="search--input--wrapper">
          <FontAwesomeIcon icon={faSearch} />
          <input type="text" placeholder="Search" className="search--input" />
        </div>
        <p className="sidebar--title">Categories</p>
        <ul className="categories--list">
          {cats.map((cat, index) => (
            <li key={index}>
              <NavLink to={/categories/ + cat.name} className="list-link" exact>
                {cat.name}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className="subscribe">
          <h6>Subscribe to receive weekly updates on the industry</h6>
          <div className="subscribe--input--wrapper">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              placeholder="Your email address"
              className="search--input"
            />
          </div>
          <p className="check-box">
            <input id="check" type="checkbox" />
            <label htmlFor="check">
              I agree to store my email according to Privacy Policy
            </label>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RelatedPosts;
