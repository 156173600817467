import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Animate from "react-smooth";

import Banner from "../../../shared/Components/Banner/Banner";
import BlogSidebar from "../components/blogSidebar";

import axios from "axios";

import Pagination from "../../../shared/Components/Pagination/Pagination";
import { Container, Loading } from "../../../components/atoms/index";

export const Blog = (props) => {
  const [posts, setPosts] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getPosts();
  }, []);

  const getPosts = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_WP}/v2/posts?_embed`, {
        headers: { "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        setPosts(res.data);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  return (
    <Animate to="3" from="0" attributeName="opacity">
      <div>
        <Banner
          image="https://worqman.fra1.digitaloceanspaces.com/images/blog-banner.png"
          title="Blog Articles"
        />
        <Container>
          <div className="row">
            <div className="col-lg-8">
              {!isLoading &&
                posts.length > 0 &&
                posts.map((post, index) => (
                  <div className="col-lg-12" key={index}>
                    <div className="blog--card">
                      <img
                        src={post._embedded?.["wp:featuredmedia"][0].source_url}
                        className="img-fluid"
                        alt=""
                      />
                      <h4>{post.title?.rendered}</h4>
                      <div className="blog--post--content">
                        {post.excerpt?.rendered.slice(
                          3,
                          post.excerpt?.rendered.length - 15
                        )}
                        ...
                      </div>
                      <NavLink to={/blog/ + post.slug} exact>
                        Continue Read
                      </NavLink>
                    </div>
                  </div>
                ))}
              {!isLoading && posts.length === 0 && <p>No post found.</p>}
              {isLoading && <Loading />}
            </div>
            <BlogSidebar />
            <div className="col-md-8">
              <Pagination
                onChange={props.changePage}
                defaultPageSize={3}
                current={parseInt(props.current)}
                total={props.length}
                showSizeChanger={true}
              />
            </div>
          </div>
        </Container>
      </div>
    </Animate>
  );
};
