import React, { useState, useEffect } from "react";

import Banner from "../../shared/Components/Banner/Banner";

// atoms
import { Container } from "../../components/atoms/index";

import { axiosAPI } from "../../utils/index";

import { useParams } from 'react-router-dom'; 
 import { Helmet } from 'react-helmet';
//import { Helmet } from 'react-helmet-async';

import dayjs from "dayjs";
import { useHistory } from 'react-router-dom';


export const ViewJob = () => {

  const [job, setJob] = useState(null); 
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
     async function fetchJob() {

       // Make an authenticated API request to fetch the job data
       if(id != null){
        axiosAPI
        .get(`/job/job-by-id-login`, {
          params: {
            id,
          },
        })
        .then((res) => {
          const data = res.data;
          setJob(data);
          localStorage.setItem("jobData", JSON.stringify(data));
        })
        .catch((err) => {
          console.error(err);
        });
       }
     }
 
     fetchJob();
   }, [id]);

   const handleClick = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => document.getElementById("copy_job").style.color = "#0e976b")
      .catch((error) => console.log('Error copying:', error));
  };

  const handleApply = () => {
    console.log("ddss")
    history.push('/login');
  };
  return (
   <>
   {/* <Helmet
    
    titleTemplate="%s | The Worqer"
    >
        <title>ddd</title>
        <meta name="description" content="ddd" />
       
    </Helmet>  */}
  {job!= null?
  <Container>
    
  {/* <Helmet
  
  titleTemplate="%s | The Worqer"
  >
      <title>{job.name}</title>
      <meta name="description" content={job.description.replace(/(<([^>]+)>)/ig, "")} />
      <meta property="og:title" content={job.name} />
      <meta property="og:description" content={job.description.replace(/(<([^>]+)>)/ig, "")} />
      <meta property="og:url" content={`${window.location.href}?${Date.now()}`} />
  </Helmet>  */}
  <div
  style={{
  border: "1px solid #e7e3e3",
  padding: "40px",
  borderRadius: "9px",
  background: "linear-gradient(179deg, rgb(0 0 0 / 1%) 0%, rgb(247, 247, 250) 100%) 0% 0% no-repeat padding-box padding-box transparent"
}}
  >
  <button id="copy_job" onClick={handleClick} className="login-btn"
  style={{
    cursor: "pointer",
    color:"#29d49c",
    fontSize: "17px",
    position: "absolute",
    marginTop: "60px",
    right: "31px",
    border: "none",
    background: "transparent"
  }}
  >Copy Job URL</button>
  <button onClick={handleApply}
  style={{
    cursor: "pointer",
    color:"#ffffff",
    fontSize: "17px",
    position: "absolute",
    right: "31px",
    border: "none",
    background: "#29d49c",
    padding: "10px 20px",
    borderRadius:"8px"
  }}
  >Apply</button>

  <h1 style={{maxWidth:"90%"}}>{job.name}</h1>
  <>
  
  <h2 className="job__title">{job.title}</h2>
  <div className="job__body">
    <h6 style={{
      marginTop: "30px",
      marginBottom: "10px"
    }}>
      Location:
      </h6>

      <h5 className="location">{job.location}</h5>
    <h6 style={{
      marginTop: "10px",
      marginBottom: "10px"
    }}>
      Skill required:
    </h6>
      <h5 className="skill">
        {job.skill.replaceAll('"', "").replaceAll(",", ", ")}
      </h5>
  </div>
  {job.job_type != "select-date"?
  <div className="job__body mt-1">
  <h6 style={{
    marginTop: "10px",
    marginBottom: "10px"
  }}>
  Job Type
  </h6>
  {job.job_type != "full-time" ? <h5 className="skill">Part Time</h5>: <h5 className="skill">Full Time</h5>}
</div>
  :
  <div className="job__date">
    <div className="job__date__start">
      From: <h4>{dayjs(job.startDate, "YYYY-MM-DD").format("DD-MM-YYYY")}</h4>
    </div>
    <div className="job__date__expire">
      To: <h4>{dayjs(job.expireDate, "YYYY-MM-DD").format("DD-MM-YYYY")}</h4>
    </div>
  </div>}
  <hr></hr>
  <div className="job__description">
    {/* {description} */}
    <div dangerouslySetInnerHTML={{ __html: job.description }} />
  </div>
</>
</div>
  </Container>
  : <Container>
    

    <div 
    style={{
      textAlign: "center",
      margin: "auto"
    }}
    >
        <div class="spinner-border"  role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
    </div>
    
    
  </Container>}
   </>
 
  );
};
