import clsx from "clsx";
import React from "react";
import { Wrapper } from "../../../components/atoms";

const Banner = (props) => (
  <div
    className={clsx(
      props.secondaryBackground ? "secondary-background" : "banner",
      props.className
    )}
  >
    <div className="container">
      {!props.secondaryBackground && <div className="banner__overlay" />}
      <Wrapper condition={props.secondaryBackground}>
        <img src={props.image} alt="banner" className="banner__image" />
      </Wrapper>
      <Wrapper condition={!props.secondaryBackground} className="container">
        <div className="banner__info">
          <h1>{props.title}</h1>
          <p>{props.description}</p>
        </div>
      </Wrapper>
    </div>
    {props.children}
  </div>
);

export default Banner;
