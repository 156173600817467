import React, { useState } from "react";
// atoms
import { Button, Wrapper, CheckIcon, Loading } from "../../components/atoms";
import { axiosAPI } from "../../utils/axios";
import { useAuthorization } from "../../hooks/useAuthorization";

function Subscription() {
  const {
    auth: { token },
  } = useAuthorization();
  const [loading, setLoading] = useState(false);

  const handleClickPackageOneMonthly = () => {
    setLoading(true);
    const priceId = "price_1KiZZVAC3ch6Ns9pCff6RviM";
    axiosAPI
      .get(`/stripe/subscription?priceId=${priceId}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setLoading(false);
        window.open(res.data.data.url);
      });
  };
  const handleClickPackageOneYearly = () => {
    setLoading(true);
    const priceId = "price_1KiZZVAC3ch6Ns9pCff6RviM";
    axiosAPI
      .get(`/stripe/subscription?priceId=${priceId}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        setLoading(false);
        window.open(res.data.data.url);
      });
  };

  return (
    <div className="wrapper">
      {loading && (
        <div className="loading">
          <Loading />
        </div>
      )}
      <div className="subscription_wrapper">
        <h2 className="main-title">Starter</h2>
        <p className="main-text">Great for startups and small business</p>
        <div style={{ paddingTop: 15, display: "flex" }}>
          <h2 className="main-title">£58</h2>
          <p className="main-text">/month</p>
        </div>
        <p className="sub-text"> Billed annually at £690 (save £138)</p>
        <hr class="solid"></hr>
        <div className="packageItem">
          <CheckIcon />
          <p className="sub-text underline">All standard features</p>
        </div>
        <div className="packageItem">
          <CheckIcon />
          <p className="sub-text">3x Active Job Posts</p>
        </div>
        <div className="packageItem">
          <CheckIcon />
          <p className="sub-text">Interview Scheduler & Calendar Sync</p>
        </div>
        <div className="packageItem">
          <CheckIcon />
          <p className="sub-text">Customable Pipeline Stages</p>
        </div>
        <div className="packageItem">
          <CheckIcon />
          <p className="sub-text">Customable Pipeline Stages</p>
        </div>
        <div className="packageItem">
          <CheckIcon />
          <p className="sub-text">Unlimited Team Members</p>
        </div>
        <Button onClick={handleClickPackageOneMonthly}>Monthly</Button>
        <Button onClick={handleClickPackageOneYearly}>Yearly</Button>
      </div>
    </div>
  );
}

export default Subscription;
