import React, { useEffect, useState, useCallback } from "react";
import parse from "html-react-parser";

import axios from "axios";
import Animate from "react-smooth";

// atoms
import { Container } from "../../../components/atoms/index";

import NewsBox from "../../../shared/Components/NewsBox/NewsBox";

export const BlogCategories = (props) => {
  const [cats, setCats] = useState([{}]);

  const getCats = useCallback(() => {
    axios
      .get(
        "https://bthewebchefs.co.uk/wp-json/wp/v2/posts?filter[category_name]=" +
          props.match.params.slug
      )
      .then((res) => {
        setCats(res.data);
      })
      .catch((err) => {});
  }, [props.match.params.slug]);

  useEffect(() => {
    getCats();
  }, [getCats]);

  return (
    <Animate to="3" from="0" attributeName="opacity">
      <Container>
        <div className="row">
          <div className="col-lg-8 p-3">
            <p className="main-title-categories">
              <span>{props.match.params.slug}</span>
            </p>
          </div>
        </div>
        <div className="row">
          {cats?.map((el, index) => (
            <NewsBox
              key={index}
              slug={el.slug}
              featured_image={el.full}
              title={el.title && parse(el.title.rendered)}
              class="col-lg-4"
            />
          ))}
        </div>
      </Container>
    </Animate>
  );
};
