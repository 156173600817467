import React, { useEffect, useState, useCallback } from "react";
import parse from "html-react-parser";
import axios from "axios";
import Animate from "react-smooth";

import Banner from "../../../shared/Components/Banner/Banner";
import RelatedPosts from "../components/relatedPosts";
import { Container, Loading } from "../../../components/atoms/index";

export const BlogDetails = (props) => {
  const [posts, setPosts] = useState({});
  const [postsImg, setPostsImg] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getSinglePost = useCallback(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_WP}/v2/posts?slug=${props.match.params.slug}`
      )
      .then((res) => {
        setPosts(res.data[0]);
        setIsLoading(false);
        if (
          res.data[0]._links &&
          res.data[0]._links["wp:featuredmedia"] &&
          res.data[0]._links["wp:featuredmedia"][0].href
        ) {
          axios
            .get(res.data[0]._links["wp:featuredmedia"][0].href)
            .then((inerResponse) => {
              setPostsImg(inerResponse.data);
            })
            .catch((err) => {});
        }
      })
      .catch((err) => {});
  }, [props.match.params.slug]);

  const getPostsImg = useCallback(() => {
    if (posts) {
      if (
        posts._links &&
        posts._links["wp:featuredmedia"] &&
        posts._links["wp:featuredmedia"][0].href
      ) {
        axios
          .get(posts._links["wp:featuredmedia"][0].href)
          .then((res) => {
            setPostsImg(res.data);
          })
          .catch((err) => {});
      }
    }
  }, [posts]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSinglePost();
    getPostsImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Animate to="3" from="0" attributeName="opacity">
      <div>
        <Banner
          backgroundImage={postsImg.source_url}
          title={posts.title?.rendered && posts.title?.rendered}
          description={posts.title?.rendered && posts.title?.rendered}
          height="450px"
        />

        <Container>
          <div className="row">
            <div className="col-lg-8">
              <div className="post--content">
                {!isLoading &&
                  !!posts?.content &&
                  parse(posts?.content?.rendered)}
                {isLoading && <Loading />}
              </div>
              <div className="row mt-lg-5">
                <div className="col-md-6">
                  <div className="row sidebar--media">
                    <div className="col-md-4">
                      <img src="/img/home-banner.png" alt="" />
                    </div>
                    <div className="col-md-6">
                      <h4>{posts.title?.rendered}</h4>
                      <a href={"/blog/" + posts.slug} className="list-link">
                        Previous Article
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="row sidebar--media">
                    <div className="col-md-6">
                      <h4>
                        {posts.title?.rendered ? posts.title?.rendered : ""}
                      </h4>
                      <a href={"/blog/" + posts.slug} className="list-link">
                        Next Article
                      </a>
                    </div>
                    <div className="col-md-5">
                      <img
                        src={postsImg.source_url}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <RelatedPosts />
          </div>
        </Container>
      </div>
    </Animate>
  );
};
