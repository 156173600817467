import { useState } from 'react';

// react
import { Modal } from 'react-bootstrap';

// atoms
import { Button, Checkbox } from '../../atoms/index';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

export const SortModal = ({ show, handleClose, ...rest }) => {
  const [sort, setSort] = useState(0);
  const {
    push,
    location: { search },
  } = useHistory();

  const queryParams = queryString.parse(search);

  const handleOnChange = (id) => () => setSort(id);
  const handleSubmit = () => {
    push({
      search: `${queryString.stringify({ ...queryParams, as: sort })}`,
    });
    handleClose();
  };

  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      aria-labelledby="sort"
      className="sort"
      {...rest}
    >
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center w-100">
        <div className="mb-3 mb-md-5 pb-2 pb-md-4 w-100">
          <Modal.Title id="sort" className="mb-3 mb-md-5 mx-auto">
            Sort results
          </Modal.Title>
          <div className="pt-2 pt-md-4 pb-5">
            {['Name A-Z'].map((label, index) => (
              <Checkbox
                key={`sort-${index}`}
                type="radio"
                name={`sort-${index}`}
                label={label}
                value={sort === index + 1}
                onChange={handleOnChange(index + 1)}
              />
            ))}
          </div>
        </div>
        <div className="sort_actions">
          <Button
            onClick={handleClose}
            variant="outlined"
            className="my-2 py-2"
          >
            Cancel
          </Button>

          <Button onClick={handleSubmit} className="my-2 py-2">
            Done
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
