// react
import React, { useEffect } from "react";

// router
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

// context
import { AuthProvider } from "./context/AuthContext";

// routes
import { Routes } from "./routes/Routes";
import { AuthRoutes } from "./routes/AuthRoutes";

// pages
import { ErrorPage } from "./pages/index";
import { useHistory } from "react-router-dom";


export const AppDevelopment = () => (
  <AuthProvider>
    <Router>
      <Switch>
        <Route
          path={[
            // "/chat",
            "/explore",
            "/jobs",
            "/jobs/:id",
            "/notifications",
            "/notifications/:id",
            "/profile",
            "/profile/:id",
            "/company/:slug",
            "/crew/:slug",
            "/profile/edit",
            "/search/results",
            "/auth/404",
            "/auth/404",
            "/stripe/package-one/success",
            "/stripe/package-two/success",
            "/stripe/package-three/success",
            "/stripe/canceled",
            "/stripe/subscription",
            "/subscription",
          ]}
          exact
          component={AuthRoutes}
        />
        <Route
          path={[
            "/",
            "/login",
            "/hire",
            "/how-it-works",
            "/become-worqer",
            "/blog",
            "/blog/:slug",
            "/categories/:slug",
            "/crew",
            "/view/job/:id",
            "/signup",
            "/companies",
            "/signup-company",
            "/signup-crew",
            "/signup-message",
            "/confirmation/user/email/account/:token",
            "/confirmation/company/email/account/:token",
            "/privacy-policy",
            "/forgot-password",
            "/reset-password/:token",
            "/terms-of-use",
            "/pricing",
          ]}
          exact
          component={Routes}
        />

        <Route path="/error/404" component={ErrorPage} />
        <Route path="/*" render={() => <Redirect to="/error/404" />} />
      </Switch>
    </Router>
  </AuthProvider>
);
