// react
import { useContext } from "react";

// router
import { Route, Redirect } from "react-router-dom";

// contexts
import { AuthContext } from "../../../context/AuthContext";
import { useParams, useHistory } from 'react-router-dom';


export const ProtectedRoute = ({ publicRoute, ...rest }) => {
  // context
  const { isLoggedIn } = useContext(AuthContext);
  const { id } = useParams();
  const history = useHistory();


  if (!!publicRoute) {
    return isLoggedIn ? (
      <Redirect to={{ pathname: "/profile" }} />
    ) : (
      <Route {...rest} />
    );
  }

  if (isLoggedIn) return <Route {...rest} />;

  if(id != undefined){
    if (window.location.pathname === `/jobs/${id}`) {
      return <Redirect to={`/view/job/${id}`} />;
    }
  }else{
    return <Redirect to={{ pathname: "/login" }} />;
  }
};
