// react
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../context/AuthContext";

// classnames
import clsx from "clsx";

// react icons
import { FaHamburger } from "react-icons/fa";

// hooks
import { Button } from "../../atoms";

// atoms
import {
  ProfileIcon,
  ExploreIcon,
  JobsIcon,
  Logo,
} from "../../../components/atoms/index";

// molecules
import { Sidebar } from "../../molecules/Sidebar/Sidebar";

// styles
import { useWindowSize } from "../../../hooks/useWindowSize";

export const AppLayout = (props) => {
  // local states
  const [isMenuToggled, setIsMenuToggled] = useState(true);
  const { logout } = useContext(AuthContext);

  // hooks
  const {
    auth: { status },
  } = useContext(AuthContext);

  const { width } = useWindowSize();

  useEffect(() => {
    if (width < 640) setIsMenuToggled(false);
    else setIsMenuToggled(true);
  }, [width]);

  // handlers
  const handleMenuToggle = () => setIsMenuToggled(!isMenuToggled);

  return (
    <div className="app__layout">
      {isMenuToggled && (
        <>
          <Logo />
          <div className="app__sideBar">
            {status === 2 ? (
              <Sidebar
                routes={[
                  { title: "Explore", path: "/explore", icon: <ExploreIcon /> },
                  {
                    title: "Jobs",
                    path: `/jobs${status === 2 ? "?status=active" : ""}`,
                    icon: <JobsIcon />,
                  },
                  // { title: "Chat", path: "/chat", icon: <ChatIcon /> },
                  { title: "Profile", path: "/profile", icon: <ProfileIcon /> },
                ]}
              />
            ) : (
              <Sidebar
                routes={[
                  { title: "Explore", path: "/explore", icon: <ExploreIcon /> },
                  {
                    title: "Jobs",
                    path: `/jobs${status === 2 ? "?status=active" : "?status=active"}`,
                    icon: <JobsIcon />,
                  },
                  // { title: "Chat", path: "/chat", icon: <ChatIcon /> },
                  { title: "Profile", path: "/profile", icon: <ProfileIcon /> },
                ]}
              />
            )}
            <div>
          <Button
          onClick={logout}
          variant="text"
          className="px-2 py-1 my-2"
        >
          Logout
        </Button>
          </div>
          </div>
          
        </>
      )}
      <div
        className={clsx(
          "app__mainContent",
          !isMenuToggled && "app__mainContent__fullWidth"
        )}
      >
        {props.children}
      </div>
      <Button
        className="menu__button"
        variant="text"
        icon={<FaHamburger />}
        iconOnly
        onClick={handleMenuToggle}
      />
    </div>
  );
};
