// react
import React from "react";
import ReactDOM from "react-dom";

// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "rc-pagination/assets/index.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

// styles
import "./index.css";

// App
import { AppDevelopment } from "./AppDevelopment";

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<AppDevelopment />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
