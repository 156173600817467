// react
import {
  useState,
  useEffect,
  createContext,
  useCallback,
  useLayoutEffect,
} from "react";

import {
  setStorageData,
  getStorageData,
  removeStorageData,
} from "../utils/index";

export const AuthContext = createContext({
  isLoggedIn: false,
  auth: null,
  login: () => {},
  logout: () => {},
  setAuth: () => {},
  setIsLoggedIn: () => {},
  setProfilePicture: () => {},
});

export const AuthProvider = ({ children }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const nullableAuth = {
    userId: "",
    token: "",
    name: "",
    status: null,
    email: "",
    slug: "",
    image: "",
    isTrial: false,
    hasSubscription: false,
    subscriptionType: false,
    firstTwoHundred: false,
  };

  const [auth, setAuth] = useState(nullableAuth);
  const [isLoggedIn, setIsLoggedIn] = useState(
    false || !!getStorageData("userData")
  );
  const [tokenExpiration, setTokenExpiration] = useState(null);

  const login = useCallback(
    ({
      userId,
      token,
      image,
      name,
      status,
      slug,
      redirect,
      expirationDate,
      hasSubscription,
      isTrial,
      firstTwoHundred,
      latitude,
      longitude,
    }) => {
      setAuth({
        userId,
        token,
        image,
        name,
        status,
        redirect,
        slug,
        hasSubscription,
        isTrial,
        firstTwoHundred,
        latitude,
        longitude,
      });

      setStorageData("completed-account", !!image);

      const tokenExpirationDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);

      setTokenExpiration(tokenExpirationDate);

      setStorageData("userData", {
        userId,
        token,
        image,
        name,
        status,
        slug,
        expiration: tokenExpirationDate.toISOString(),
        hasSubscription,
        isTrial,
        firstTwoHundred,
        latitude,
        longitude,
      });
    },
    []
  );

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    setAuth(nullableAuth);
    setTokenExpiration(null);
    removeStorageData("completed-account");
    removeStorageData("userData");
  }, [setIsLoggedIn, setAuth, setTokenExpiration, nullableAuth]);

  const setProfilePicture = useCallback(
    (img) => {
      const storedData = getStorageData("userData");
      if (
        storedData &&
        storedData.token &&
        new Date(storedData.expiration) > new Date()
      ) {
        login({
          ...storedData,
          image: img,
          expirationDate: new Date(storedData.expiration),
        });
      }
    },
    [login]
  );
  const setSubscription = useCallback(
    ({ hasSubscription, isTrial, firstTwoHundred }) => {
      const storedData = getStorageData("userData");
      if (
        storedData &&
        storedData.token &&
        new Date(storedData.expiration) > new Date()
      ) {
        login({
          ...storedData,
          expirationDate: new Date(storedData.expiration),
          hasSubscription,
          isTrial,
          firstTwoHundred,
        });
      }
    },
    [login]
  );

  useLayoutEffect(() => {
    let logoutTimer;
    if (!!auth && auth.token && tokenExpiration) {
      logoutTimer = setTimeout(
        logout,
        tokenExpiration.getTime() - new Date().getTime()
      );
    }

    return () => clearTimeout(logoutTimer);
  }, [logout, tokenExpiration, auth]);

  useEffect(() => {
    const storedData = getStorageData("userData");

    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      setIsLoggedIn(true);
      login({ ...storedData, expirationDate: new Date(storedData.expiration) });
    } else {
      logout();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        setAuth,
        setIsLoggedIn,
        setProfilePicture,
        setSubscription,
        auth,
        isLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
