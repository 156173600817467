import React, { cloneElement, forwardRef } from "react";

// react bootstrap
import { Form } from "react-bootstrap";
import clsx from "clsx";

export const SelectField = forwardRef(
  (
    { icon, name, label, options, placeholder, className, onChange, ...rest },
    ref
  ) => (
    <Form.Group className="selectField">
      <Form.Label htmlFor={name}>{label}</Form.Label>

      <div className="d-flex flex-row justify-content-start align-items-center selectField_wrapper">
        {icon && cloneElement(icon, { className: "selectField_icon" })}
        <Form.Control
          ref={ref}
          {...rest}
          name={name}
          onChange={onChange}
          as="select"
          className={clsx("selectField_input", className)}
        >
          {options.map(({ value, label }, index) => (
            <option key={index} value={value}>
              {label}
            </option>
          ))}
        </Form.Control>
      </div>
    </Form.Group>
  )
);
