import { Modal, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { MdCloudUpload } from "react-icons/md";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { axiosAPI } from "../../../utils";
import { Button } from "../Button/Button";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { pdfs } from "../../../static/feeds";

const customComponents = {
  Control: (rest) => (
    <components.Control {...rest}>
      <>
        <FiSettings className="selectField_icon" />
        {rest.children}
      </>
    </components.Control>
  ),
  IndicatorSeparator: () => null,
  IndicatorsContainer: () => (
    <components.DownChevron>
      <RiArrowDropDownLine />
    </components.DownChevron>
  ),
};

const customStyles = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
    border: "none",
    borderBottom: "1px solid #f4f4f6fd",
    borderRadius: "0",
    cursor: "pinter",
    padding: "4px",
    paddingRight: 0,
    "&:hover": {
      borderColor: "#f4f4f6fd",
    },
  }),
};

export const AddPdf = (props) => {
  // local state
  const [filePreview, setFilePreview] = useState("");
  const [file, setFile] = useState(null);
  const { push } = useHistory();

  // handler
  const { handleSubmit, control } = useForm();
  const {
    auth: { token },
  } = useAuthorization();

  const onSubmit = async (data) => {
    try {
      let formData = new FormData();
      formData.append("pdf", file);
      formData.append(
        "title",
        data.document ? data.document.value : pdfs[0].value
      );

      const { data: responseData } = await axiosAPI.post(
        "/pdf/upload-pdf",
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (responseData.message) {
        props.handleClose();
        push("/profile");
      }
    } catch (err) {
      return console.error(err);
    }
  };

  const handleOnChange = (event) => {
    if (event?.target.files?.length) {
      setFilePreview(event?.target.files[0].name);
      setFile(event?.target.files[0]);
    }
  };

  return (
    <Modal
      size="md"
      centered
      show={props.show}
      onHide={props.handleClose}
      aria-labelledby="pdf-modal"
      contentClassName="add-pdf-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="projects">Upload PDF</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="selectField mt-3">
            <div className="d-flex flex-row justify-content-start align-items-center multiSelect_wrapper">
              <Controller
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    onChange={onChange}
                    ref={ref}
                    value={value}
                    onBlur={onBlur}
                    options={pdfs}
                    className="selectField_input w-100"
                    components={customComponents}
                    styles={customStyles}
                    placeholder="Document title"
                    defaultValue={pdfs[0]}
                  />
                )}
                control={control}
                onChange={([selected]) => selected}
                name="document"
              />
            </div>
          </Form.Group>

          <div className="createProfile_file">
            <label htmlFor="uploadCv">
              <Button type="button">
                <MdCloudUpload />
              </Button>
              <p>Choose files to upload</p>
            </label>
            <input
              type="file"
              id="uploadCv"
              onChange={handleOnChange}
              accept="application/pdf, image/jpg, image/jpeg, image/png"
            />
          </div>
          {filePreview && (
            <p className="my-3 text-left">File name: {filePreview}</p>
          )}
          <Button type="submit" className="my-2" fullWidth>
            Submit
          </Button>
          <span style={{ color: 'red'}}>Accepted files: pdf, png, jpeg, jpg</span>
        </form>
      </Modal.Body>
    </Modal>
  );
};
