// react
import React from "react";

// router
import { Switch } from "react-router-dom";

// molecules
import { ProtectedRoute } from "../components/molecules";

// templates
import { AppLayout } from "../components/templates";

// pages
import {
  Explore,
  Profile,
  Jobs,
  EditProfile,
  CrewProfile,
  SingleJob,
  SearchResults,
  Notifications,
  CompanyProfile,
} from "../pages";
import Success from "../pages/Stripe/Success";
import Cancel from "../pages/Stripe/Cancel";
import Subscription from "../pages/Stripe/Subscription";
import SubscriptionDashboard from "../pages/Subscription";

export const AuthRoutes = () => (
  <AppLayout>
    <Switch>
      {/* <ProtectedRoute path="/chat" exact component={Chat} /> */}
      <ProtectedRoute path="/search/results" exact component={SearchResults} />
      <ProtectedRoute path="/explore" exact component={Explore} />
      <ProtectedRoute path="/jobs" exact component={Jobs} />
      <ProtectedRoute path="/jobs/:id" exact component={SingleJob} />
      <ProtectedRoute path="/profile/edit" exact component={EditProfile} />
      <ProtectedRoute path="/profile" exact component={Profile} />
      <ProtectedRoute path="/profile/:id" exact component={Profile} />
      <ProtectedRoute path="/company/:slug" component={CompanyProfile} />
      <ProtectedRoute path="/crew/:slug" component={CrewProfile} />
      <ProtectedRoute path="/notifications" exact component={Notifications} />
      <ProtectedRoute
        path="/stripe/package-one/success"
        exact
        component={Success}
      />
      <ProtectedRoute
        path="/stripe/package-two/success"
        exact
        component={Success}
      />
      <ProtectedRoute
        path="/stripe/package-three/success"
        exact
        component={Success}
      />
      <ProtectedRoute path="/stripe/cancel" exact component={Cancel} />
      <ProtectedRoute
        path="/stripe/subscription"
        exact
        component={Subscription}
      />
      <ProtectedRoute
        path="/subscription"
        exact
        component={SubscriptionDashboard}
      />
      <ProtectedRoute
        path="/notifications/:id"
        exact
        component={Notifications}
      />
    </Switch>
  </AppLayout>
);
