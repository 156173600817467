import React, { useState, useEffect } from "react";
import Animate from "react-smooth";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

import Banner from "../../shared/Components/Banner/Banner";
import TitleUnderline from "../../shared/Components/TitleUnderline/TitleUnderline";
import CompanySlider from "../../shared/Components/Slider/Slider";

// axios
import { axiosAPI } from "../../utils/index";

// atoms
import { Container } from "../../components/atoms/index";

const TITLE_ONE = {
  title: "Featured Companies",
  description:
    "Connecting you and your availability to Companies at a click of a button",
};

export const ForCrew = () => {
  const [companies, setCompanies] = useState();

  useEffect(() => {
    const sendRequest = async () => {
      try {
        let response = await axiosAPI.get("/companies/all-companies");
        setCompanies(response.data);
      } catch (err) {}
    };

    sendRequest();
  }, []);

  return (
    <Animate to="3" from="0" attributeName="opacity">
      <div id="for-crew">
        <Banner
          backgroundImage="https://worqman.fra1.digitaloceanspaces.com/images/crew-banner.png"
          title="For Crew"
          description="Connecting you and your availability"
          height="600px"
        >
          <div className="for-crew-button">
            <NavLink to="/signup-crew" className="upload-button">
              <FontAwesomeIcon icon={faUpload} />
              Create your profile
            </NavLink>
          </div>
        </Banner>

        <Container>
          <TitleUnderline
            title={TITLE_ONE.title}
            description={TITLE_ONE.description}
            center
          />
          <CompanySlider companies={companies || []} />
        </Container>
      </div>
    </Animate>
  );
};
