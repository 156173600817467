import { cloneElement, forwardRef } from "react";

// react bootstrap
import { Form } from "react-bootstrap";

export const InputField = forwardRef((props, ref) => (
  <Form.Group className="inputField">
    <Form.Label htmlFor={props.name}>{props.label}</Form.Label>

    <div className="inputField_wrapper">
      {props.icon && cloneElement(props.icon, { className: "inputField_icon" })}
      {props.divider && <span className="inputField_divider" />}
      {props.children ? (
        <>{props.children}</>
      ) : (
        <Form.Control
          {...props}
          value={props.value}
          type={props.type}
          name={props.name}
          className="inputField_input"
          ref={ref}
        />
      )}
    </div>
  </Form.Group>
));
