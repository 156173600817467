import Animate from "react-smooth";
import Banner from "../../shared/Components/Banner/Banner";
import { NavLink } from "react-router-dom";

import {
  OthersThoughts,
  DownloadApp,
  TopFreelancers,
} from "../../components/UIPages";
import { Footer } from "../../components/organisms/Footer/Footer";

export const Hire = () => (
  <Animate to="3" from="0" attributeName="opacity">
    <div id="hire">
      <div className="hire-banner">
        <div className="container">
          <div className="content-wrapper">
            <h2>
              Worqman makes it easy to find,manage, select, and hire the best
              workers for your project.
            </h2>
            <p>
              Recruit smarter, in less time, with less effort, with an
              all-in-one talent hiring platform designed for the Construction
              industry. With Worqman, you'll eliminate hiring chaos and make
              better decisions.
            </p>
            <NavLink to="/signup">
              <button className="btn btn__primary btn__contained">
                Get Started
              </button>
            </NavLink>
          </div>
          <div className="image-wrapper">
            <img
              src="https://worqman.fra1.digitaloceanspaces.com/images/hire-banner.png"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* <Banner
        image="https://worqman.fra1.digitaloceanspaces.com/images/hire-banner.png"
        title="Worqman makes it easy to find,manage, select, and hire the best workers for your project."
        description="Recruit smarter, in less time, with less effort, with an all-in-one talent hiring platform designed for the Construction industry. With Worqman, you'll eliminate hiring chaos and make better decisions."
        secondaryBackground
      /> */}
      <div className="hire-worqers-wrapper container">
        <div className="image-wrapper">
          <img
            src="../images/hire-worqer-dashboard.png"
            alt="worqer-dashboard"
          />
        </div>
        <div className="content-wrapper">
          <h2>
            It's quick, easy, and secure to find and hire workers with Worqman
          </h2>
          <p>
            Worqman makes finding, and hiring for general workers and labour
            pretty much straightforward, safe, and secure. We Request thatwe
            check the ID and tax status of the worker to ensure safety and
            transparency.
          </p>
        </div>
      </div>
      <div className="sections">
        <OthersThoughts />
      </div>
      <div className="find-and-hire-wrapper container">
        <div className="content-wrapper">
          <h2>Find and hire labourers and workers from different industries</h2>
          <p>
            Worqman is a one-stop solution to help you find different types of
            workers, from landscapers to scaffolders, to building gangs.
          </p>
        </div>
        <div className="image-wrapper">
          <img
            src="../images/find-and-hire-section.jpg"
            alt="find-and-hire-worqers"
          />
        </div>
      </div>
      <div className="sections">
        {/* <TopFreelancers /> */}
        <DownloadApp />
        <Footer />
      </div>
    </div>
  </Animate>
);
