import { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export const Portal = memo(({ id, children }) => {
  const el = useRef(
    document.getElementById(id) || document.createElement("div")
  );
  const [dynamic] = useState(!el.current.parentElement);
  useEffect(() => {
    const { current } = el;
    if (dynamic) {
      current.id = id;
      document.body.appendChild(current);
    }
    return () => {
      if (dynamic && current.parentElement) {
        current.parentElement.removeChild(current);
      }
    };
  }, [dynamic, id]);
  return createPortal(children, el.current);
});
