import React from "react";
import { NavLink } from "react-router-dom";
import { ToolsIcon, WorkerIcon } from "../../components/atoms/index";

export const Signup = () => (
  <div id="signup">
    <div className="modal--content">
      <section>
        <div className="modal--head text-center mb-5">
          <h1 className="signup-title">Signup</h1>
          <p className="modal--description mb-3">
            If you don't have an account please signup
          </p>
          <div className="btn--wrapper mr-lg-3 mt-2 mb-2">
            <NavLink
              to="/signup-company"
              className={`btn-c btn--underline signup-as-a-company`}
            >
              <WorkerIcon />
              As a Company
            </NavLink>
          </div>
          <div className="btn--wrapper ml-lg-3 mt-2 mb-2">
            <NavLink
              to="/signup-crew"
              className={`btn-c btn--underline signup-as-a-crew`}
            >
              <ToolsIcon />
              As a Crew
            </NavLink>
          </div>
        </div>
      </section>
    </div>
  </div>
);
