import { BusinessTestimonialCard } from './BusinessTestimonialCard';

export const BusinessTestimonialCards = (props) => (
  <div className="business-testimonial-cards">
    {props.quotes?.map((info) => (
      <BusinessTestimonialCard
        key={info.id}
        image={info.image}
        quote={info.quote}
        author={info.author}
      />
    ))}
  </div>
);
