import Animate from 'react-smooth';
import Banner from '../../shared/Components/Banner/Banner';

import { DownloadApp, RoadMap, SelectRole } from '../../components/UIPages';
import { Footer } from '../../components/organisms/Footer/Footer';

export const HowItWorks = () => (
  <Animate to="3" from="0" attributeName="opacity">
    <div id="how-it-works">
      <Banner
        image="https://worqman.fra1.digitaloceanspaces.com/images/single-worqer.png"
        title="How it works"
        description="Worqman connects your business to a community of qualified professionals."
        secondaryBackground
      />
      <div className="sections">
        <RoadMap />
        <SelectRole />
        <DownloadApp />
        <Footer />
      </div>
    </div>
  </Animate>
);
