import React from 'react';

// organisms
import { MainNavigation } from '../../organisms';

export const AuthLayout = ({ children }) => (
  <div className="container-fluid auth_layout">
    <MainNavigation />
    <main>{children}</main>
  </div>
);
