import clsx from 'clsx';

export const ExploreCardImage = ({ src, isUser }) => (
  <img
    src={src}
    alt="explore profile"
    className={clsx(
      'explore__card__image',
      isUser ? 'explore__card__image_company' : 'explore__card__image_user'
    )}
  />
);
