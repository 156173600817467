import React from "react";

export const ProfileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.251"
    height="23.251"
    viewBox="0 0 23.251 23.251"
  >
    <g id="user" transform="translate(0 0)">
      <g id="Group_766" data-name="Group 766" transform="translate(0 14.947)">
        <g id="Group_765" data-name="Group 765" transform="translate(0)">
          <path
            id="Path_8791"
            data-name="Path 8791"
            d="M18.744,308.421l-3.535-1.178a.831.831,0,0,0-.262-.043H8.3a.829.829,0,0,0-.262.043l-3.535,1.178A6.582,6.582,0,0,0,0,314.673a.83.83,0,0,0,.83.83h21.59a.83.83,0,0,0,.83-.83A6.582,6.582,0,0,0,18.744,308.421Z"
            transform="translate(0 -307.199)"
            fill="#bab8c6"
          />
        </g>
      </g>
      <g id="Group_768" data-name="Group 768" transform="translate(6.643 0)">
        <g id="Group_767" data-name="Group 767">
          <path
            id="Path_8792"
            data-name="Path 8792"
            d="M141.515,0a4.982,4.982,0,0,0-4.982,4.982V8.3a4.982,4.982,0,1,0,9.965,0V4.982A4.982,4.982,0,0,0,141.515,0Z"
            transform="translate(-136.533)"
            fill="#bab8c6"
          />
        </g>
      </g>
    </g>
  </svg>
);
