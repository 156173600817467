import React, { useState } from "react";
import { Modal, Alert } from "react-bootstrap";
import clsx from "clsx";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { axiosAPI } from "../../../utils";

import { useHistory } from "react-router-dom";
import { Button } from "../../atoms";
import { loadStripe } from "@stripe/stripe-js";

import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
const stripePromise = loadStripe(
  "pk_test_51Ki1N7AC3ch6Ns9paiDxHL3kUWrs6NREJj3ZGiTKc00NcDif4Owk9Zn5osUHbtCDmWhowlCg7tGMyPEemxCnM06v00nIXVHuth",
  {
    stripeAccount: "acct_1Ki1N7AC3ch6Ns9p",
  }
);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const {
    auth: { token },
  } = useAuthorization();

  const handleSubmit = async (event) => {
    setError(null);
    event.preventDefault();

    if (elements == null) {
      return;
    }

    console.log(elements.getElement(CardElement));
    stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      })
      .then((res) => {
        if (res.error) {
          setError(res.error.message);
        }

        axiosAPI.put(
          `/stripe/subscription/update/payment-method`,
          { paymentMethod: res.paymentMethod },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );
      })
      .catch((error) => {
        setError(error.error.message);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      <div style={{ marginTop: 30, marginBottom: 45 }}>
        <CardElement />
      </div>

      <Button
        type="submit"
        disabled={!stripe || !elements}
        style={{ width: "100%" }}
      >
        Update Payment Method
      </Button>
    </form>
  );
};

export const UpdateCard = ({ isEdit, show, title, handleClose, ...rest }) => {
  const [selectedEndDay, setSelectedEndDay] = useState(null);
  const [selectedStartDay, setSelectedStartDay] = useState(null);
  const { push } = useHistory();

  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);

  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      aria-labelledby="updateCard"
      className={clsx(
        "updateCard",
        openStart || openEnd ? "updateCard_hidden" : "updateCard_visible"
      )}
      {...rest}
    >
      <Modal.Title id="updateCard">{title}</Modal.Title>

      <Modal.Body style={{ alignItems: "normal" }}>
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </Modal.Body>
    </Modal>
  );
};
