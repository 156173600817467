// config
import { ITEMS_PER_PAGE as itemsPerPage } from '../../../config/global';

// hooks
import { usePaginationItemIndex } from '../../../hooks';

// atoms
import { Ternary } from '../../atoms';

// molecules
import { Pagination, ExploreCard, EmptyData } from '../../molecules';

export const ExploreCards = ({ title, data, asProject }) => {
  // hooks
  const { firstItemIndex, lastItemIndex } = usePaginationItemIndex();

  return (
    <div className="explore-card">
      <Ternary condition={data.length !== 0} fallback={<EmptyData />}>
        <h3 className="explore-card__title">{title}</h3>
        <div className="explore-card__cardWrapper">
          {data
            .slice(firstItemIndex || 0, lastItemIndex || data.length)
            .map((data) => (
              
              <ExploreCard key={data.id} data={data} asProject={asProject} />
            ))}
        </div>
      </Ternary>

      {data.length > itemsPerPage && (
        <Pagination defaultPage={1} numberOfItems={data.length} />
      )}
    </div>
  );
};
