// react
import { useEffect, useState } from "react";

// router
import { useHistory } from "react-router-dom";

// config
import { ITEMS_PER_PAGE as itemsPerPage } from "../../../config/global";

// query string
import queryString from "query-string";

// hooks
import { usePagination } from "../../../hooks/usePagination";

// atoms
import { Button, Ternary } from "../../atoms";

export const Pagination = ({ defaultPage, numberOfItems }) => {
  // local states
  const [currentPage, setCurrentPage] = useState(defaultPage);

  // hooks
  const {
    push,
    location: { search },
  } = useHistory();

  const paginationItems = usePagination({
    numberOfItems: numberOfItems,
    itemsPerPage: itemsPerPage,
    currentPage: 1,
  });

  // query string
  const queryParams = queryString.parse(search);
  const { page } = queryParams;

  useEffect(() => {
    if (!paginationItems.includes(Number(page))) {
      setCurrentPage(defaultPage);
      push({ search: `${queryString.stringify({ ...queryParams, page: 1 })}` });
    }
    setCurrentPage(+page);
  }, [currentPage, defaultPage, page, paginationItems, push, queryParams]);

  // handlers
  const handlePageChange = (pageNumber) => () => {
    push({
      search: `${queryString.stringify({ ...queryParams, page: pageNumber })}`,
    });
  };

  return (
    <Ternary condition={numberOfItems > itemsPerPage} fallback={null}>
      <div className="pagination">
        <Button
          disabled={paginationItems[0] === currentPage}
          onClick={handlePageChange(currentPage - 1)}
        >
          &larr;
        </Button>
        {paginationItems.map((item) => (
          <Button
            key={item}
            onClick={handlePageChange(item)}
            className={`${currentPage === item ? "active" : ""}`}
          >
            {item}
          </Button>
        ))}
        <Button
          disabled={paginationItems[paginationItems.length - 1] === currentPage}
          onClick={handlePageChange(currentPage + 1)}
        >
          &rarr;
        </Button>
      </div>
    </Ternary>
  );
};
