import React from "react";

// icons
import { FiCheck } from "react-icons/fi";

// classnames
import clsx from "clsx";

// react bootstrap
import { Form } from "react-bootstrap";

export const Checkbox = ({
  type = "checkbox",
  value,
  icon,
  name,
  label,
  ...rest
}) => (
  <Form.Group className="mb-3 checkbox" controlId={name}>
    <div className="d-flex flex-row justify-content-between align-items-center checkbox_wrapper">
      <Form.Check
        custom
        id={name}
        type={type}
        label={label}
        checked={value}
        {...rest}
        className={clsx("checkbox_input", value && "checkbox_active")}
      />
      {value && <FiCheck className="checkbox_icon" />}
    </div>
  </Form.Group>
);
