// react
import React from "react";

// router
import { Redirect, Route, Switch } from "react-router-dom";

// molecules
import { ProtectedRoute } from "../components/molecules";

// organisms
import { ConfirmUserEmail, ConfirmCompanyEmail } from "../components/organisms";

// templates
import { AuthLayout } from "../components/templates";

// pages
import {
  Blog,
  Terms,
  Login,
  Signup,
  ViewJob,
  Homepage,
  ForCrew,
  ForCompanies,
  BlogDetails,
  PrivacyPolicy,
  ForgotPassword,
  BlogCategories,
  ResetPassword,
  SignupCrew,
  SignupCompany,
  SignupMessage,
  Hire,
  BecomeWorqer,
  HowItWorks,
  Pricing,
} from "../pages";
import Success from "../pages/Stripe/Success";
import Cancel from "../pages/Stripe/Cancel";

export const Routes = () => (
  <AuthLayout>
    <Switch>
      {/* <ProtectedRoute publicRoute path="/" exact component={Homepage} /> */}
      <ProtectedRoute publicRoute path="/login" exact component={Login} />
      <ProtectedRoute publicRoute path="/hire" exact component={Hire} />
      <ProtectedRoute
        publicRoute
        path="/become-worqer"
        exact
        component={BecomeWorqer}
      />
      <ProtectedRoute
        publicRoute
        path="/how-it-works"
        exact
        component={HowItWorks}
      />
      <ProtectedRoute
        publicRoute
        path="/companies"
        exact
        component={ForCompanies}
      />
      <ProtectedRoute publicRoute path="/crew" exact component={ForCrew} />
      <ProtectedRoute publicRoute path="/signup" exact component={Signup} />
      <ProtectedRoute
        publicRoute
        path="/signup-company"
        exact
        component={SignupCompany}
      />
      <ProtectedRoute
        publicRoute
        path="/signup-crew"
        exact
        component={SignupCrew}
      />
      <ProtectedRoute
        publicRoute
        path="/signup-message"
        exact
        component={SignupMessage}
      />
      <ProtectedRoute
        publicRoute
        path="/confirmation/user/email/account/:token"
        exact
        render={() => <ConfirmUserEmail />}
      />
      <ProtectedRoute
        publicRoute
        path="/confirmation/company/email/account/:token"
        exact
        render={() => <ConfirmCompanyEmail />}
      />

      <ProtectedRoute publicRoute path="/blog" exact component={Blog} />
      <ProtectedRoute
        publicRoute
        path="/blog/:slug"
        exact
        render={(props) => <BlogDetails {...props} />}
      />
      <ProtectedRoute
        publicRoute
        path="/categories/:slug"
        exact
        render={(props) => <BlogCategories {...props} />}
      />
      <ProtectedRoute
        publicRoute
        path="/privacy-policy"
        exact
        component={PrivacyPolicy}
      />
      <ProtectedRoute publicRoute path="/pricing" exact component={Pricing} />
      <ProtectedRoute
        publicRoute
        path="/forgot-password"
        exact
        component={ForgotPassword}
      />
      <ProtectedRoute
        publicRoute
        path="/reset-password/:token"
        exact
        component={ResetPassword}
      />
      <ProtectedRoute
        publicRoute
        path="/terms-of-use"
        exact
        component={Terms}
      />
      <ProtectedRoute
        publicRoute
        path="/view/job/:id"
        exact
        component={ViewJob}
      />
      <ProtectedRoute
        publicRoute
        path="/stripe/success"
        exact
        component={Success}
      />
      <ProtectedRoute
        publicRoute
        path="/stripe/cancel"
        exact
        component={Cancel}
      />
      <Route path="/*" render={() => <Redirect to="/login" />} />
    </Switch>
  </AuthLayout>
);
