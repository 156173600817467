/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const BlogSidebar = (props) => {
  const [cats, setCats] = useState([{}]);
  const [search, setSearchResults] = useState([{}]);
  const [searchParam, setSearchParam] = useState();
  const [subscribed, setSubscribed] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    getCats();
    getSearchResults();
  }, []);

  const inputValueHandler = (e) => {
    setSearchParam(e.target.value);
  };
  const getCats = () => {
    axios
      .get(`${process.env.REACT_APP_WP}/v2/categories`)
      .then((res) => {
        setCats(res.data);
      })
      .catch((err) => {});
  };

  const getSearchResults = (e) => {
    e && e.preventDefault();
    axios
      .get(`${process.env.REACT_APP_WP}/v2/search?s=${searchParam}`)
      .then((res) => {
        setSearchResults(res.data);
      })
      .catch((err) => {});
  };

  const onSubmit = (e) => {
    setTimeout(() => {
      setSubscribed(true);
    }, 500);
  };

  return (
    <div className="col-lg-4">
      <div className="sidebar">
        <p className="sidebar--title">Search news</p>

        <div className="search--input--wrapper">
          <form action="" onSubmit={getSearchResults}>
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              placeholder="Search"
              className="search--input"
              onChange={inputValueHandler}
            />
          </form>
        </div>

        <p className="sidebar--title">Categories</p>
        <ul className="categories--list">
          {cats.map((cat, index) => (
            <li key={index}>
              <NavLink to={/categories/ + cat.name} className="list-link" exact>
                {cat.name}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className="subscribe">
          <h6>Subscribe to receive weekly updates on the industry</h6>
          <div className="subscribe--input--wrapper">
            {/*<FontAwesomeIcon icon={faFacebook} />*/}
            {!subscribed ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="text"
                  placeholder="Your email address"
                  className="search--input"
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+\.\S+$/,
                  })}
                />
                {errors.email?.type === "required" && (
                  <div className="error-text">Email is required</div>
                )}
                {errors.email?.type === "pattern" && (
                  <div className="error-text">Field must be email</div>
                )}
              </form>
            ) : (
              <p className="subscribe-message">Thanks for your subscription.</p>
            )}
          </div>
          <p className="check-box">
            <input id="check" type="checkbox" />
            <label htmlFor="check">
              I agree to store my email according to Privacy Policy
            </label>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;
