import { useCallback, useEffect, useState } from 'react';
import { axiosAPI } from '../../../utils';
import { Loading } from '../../atoms';
import { ExploreCards } from '../../organisms';
import { PageSectionHeading } from './PageSectionHeading';

export const LatestJobs = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = useCallback(async () => {
    try {
      const { data } = await axiosAPI.get('/job/latest-jobs');
      setData(data);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <section id="latest-jobs">
      <PageSectionHeading
        title="The latest posted jobs"
        text="Lorem ipsum dolor sit amet, coectetur adipiscing elit. Suspendisse varius
          enim in eros."
      />
      {loading ? (
        <Loading />
      ) : (
        <div className="container">
          <ExploreCards data={data} asProject />
        </div>
      )}
    </section>
  );
};
