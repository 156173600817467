// react
import React from "react";

// prop-types
import PropTypes from "prop-types";

// classname
import clsx from "clsx";

export const Button = ({
  variant = "contained",
  color = variant === "contained" && "primary",
  icon,
  iconOnly,
  fullWidth,
  className,
  children,
  ...props
}) => {
  return (
    <button
      className={clsx(
        "btn",
        color && `btn__${color}`,
        variant && `btn__${variant}`,
        fullWidth && "btn__fullWidth",
        className
      )}
      {...props}
    >
      {!iconOnly && children}
      {icon && icon}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  variant: PropTypes.oneOf(["text", "contained", "outlined"]),
  color: PropTypes.oneOf(["white", "primary", "secondary"]),
  icon: PropTypes.element,
  iconOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
};
