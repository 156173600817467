// router
import { Link } from "react-router-dom";

// hooks
import { useWindowSize } from "../../../hooks/useWindowSize";

export const Logo = () => {
  // hooks
  const { width } = useWindowSize();

  return (
    <div className="app__logo">
      <Link to="/explore" className="app__logo__link">
        <img
          src={
            width <= 1024
              ? "https://worqman.fra1.digitaloceanspaces.com/images/logo-light.png"
              : "https://worqman.fra1.digitaloceanspaces.com/images/PHOTO-2022-11-08-21-44-19%20%281%29%20-%20Edited.png"
          }
          alt="worqman logo"
        />
      </Link>
    </div>
  );
};
