import React, { useEffect, useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { axiosAPI } from "../../../utils/index";
import { AuthContext } from "../../../context/AuthContext";
import { Loading } from "../../atoms/Loading/Loading";
import { Button } from "../../atoms/index";

export const ConfirmCompanyEmail = () => {
  const history = useHistory();
  const token = useParams().token;
  const [loading, setLoading] = useState(true);

  const { login, setIsLoggedIn, auth } = useContext(AuthContext);

  useEffect(() => {
    if (!token) {
      // history.goBack();
    }

    const sendRequest = async () => {
      try {
        setLoading(true);
        axiosAPI
          .post(
            "/companies/confirm/email",
            {},
            { headers: { authorization: `Bearer ${token}` } }
          )
          .then((res) => {
            const { loggedUser } = res.data;
            login({ ...loggedUser, token });
            setIsLoggedIn(true);
            setLoading(false);
          });
      } catch (err) {
        return;
      }

      // history.push({
      //   pathname: "/login",
      //   state: {
      //     fullname: response.data.name,
      //   },
      // });
    };

    sendRequest();
  }, [history, token]);

  console.log(auth);

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      style={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      {/* <style jsx global>{`
        .main-navigation {
          display: none;
        }
      `}</style> */}
      <div
        style={{
          padding: 40,
          width: "100%",
          maxWidth: 700,
          backgroundColor: "#f5f5f5",
          borderRadius: 12,
          textAlign: "center",
        }}
      >
        <h6>
          You have Successfully Signup to our page. Please Complete Your profile
        </h6>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 40,
          }}
        >
          <Button
            onClick={() => {
              setIsLoggedIn(true);
              history.push("/profile");
            }}
          >
            Compleat Profile
          </Button>
        </div>
      </div>
    </div>
  );
};
