import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

export const Footer = () => (
  <div className="footer">
    <div className="container">
      <div className="widget">
        <img
          src="https://worqman.fra1.digitaloceanspaces.com/images/PHOTO-2022-11-08-21-44-19%20%281%29%20-%20Edited.png"
          alt="worqman logo"
        />
        <p>
          Worqman connects your business to a community of qualified
          professionals.
        </p>
        <img
          src="https://worqman.fra1.digitaloceanspaces.com/images/gdpr.png"
          alt="gdpr logo"
        />
      </div>
      <div className="widget">
        <h2>PAGES</h2>
        <Link to="/">Home</Link>
        <Link to="/pricing">Pricing</Link>
      </div>
      <div>
        <h2>LEGAL</h2>
        <Link to="/terms-of-use">Terms of use</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      <div className="widget">
        <h2>CONTACT US</h2>
        <p>We’re Always Happy to Help</p>
        <h4>mail@worqman.co.uk</h4>
        <div className="social-links">
          <Link
            to={{ pathname: "https://www.facebook.com/worqman" }}
            target="_blank"
          >
            <span>
              <FaFacebook />
            </span>
          </Link>
          <Link
            to={{ pathname: "https://www.linkedin.com/company/worqman" }}
            target="_blank"
          >
            <span>
              <FaTwitter />
            </span>
          </Link>
          <Link
            to={{ pathname: "https://www.instagram.com/worqman" }}
            target="_blank"
          >
            <span>
              <FaInstagram />
            </span>
          </Link>
        </div>
      </div>
    </div>
  </div>
);
