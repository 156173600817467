import React from 'react';

export const BusinessTestimonialCard = (props) => (
  <div className="business-testimonial-card">
    <img src={props.image} alt="business testimonial profile" />
    <div>
      <h3>{props.quote}</h3>
      <p>{props.author}</p>
    </div>
  </div>
);
