import React from "react";

export const SkilledProfessionalIcon = () => (
  <svg
    id="network_1_"
    data-name="network (1)"
    xmlns="http://www.w3.org/2000/svg"
    width="63"
    height="63.001"
    viewBox="0 0 63 63.001"
  >
    <g id="Group_280" data-name="Group 280" transform="translate(54.6 12.6)">
      <path
        id="Path_1432"
        data-name="Path 1432"
        d="M447.934,102.4a4.2,4.2,0,1,0,4.2,4.2A4.2,4.2,0,0,0,447.934,102.4Zm0,6.3a2.1,2.1,0,1,1,2.1-2.1,2.1,2.1,0,0,1-2.1,2.1Z"
        transform="translate(-443.734 -102.4)"
        fill="#78849e"
      />
    </g>
    <g id="Group_281" data-name="Group 281" transform="translate(0 47.25)">
      <path
        id="Path_1433"
        data-name="Path 1433"
        d="M4.2,384a4.2,4.2,0,1,0,4.2,4.2A4.2,4.2,0,0,0,4.2,384Zm0,6.3a2.1,2.1,0,1,1,2.1-2.1A2.1,2.1,0,0,1,4.2,390.3Z"
        transform="translate(0 -384)"
        fill="#78849e"
      />
    </g>
    <g id="Group_282" data-name="Group 282" transform="translate(46.196 9.448)">
      <path
        id="Path_1434"
        data-name="Path 1434"
        d="M386.028,81.27,376.97,76.9a1.051,1.051,0,0,0-.918,1.891l9.058,4.373a1.05,1.05,0,0,0,.912-1.891Z"
        transform="translate(-375.46 -76.792)"
        fill="#78849e"
      />
    </g>
    <g id="Group_283" data-name="Group 283" transform="translate(43.052 54.6)">
      <path
        id="Path_1435"
        data-name="Path 1435"
        d="M354.069,443.733a4.2,4.2,0,1,0,4.2,4.2,4.2,4.2,0,0,0-4.2-4.2Zm0,6.3a2.1,2.1,0,1,1,2.1-2.1,2.1,2.1,0,0,1-2.1,2.1Z"
        transform="translate(-349.869 -443.733)"
        fill="#78849e"
      />
    </g>
    <g
      id="Group_284"
      data-name="Group 284"
      transform="translate(34.622 51.436)"
    >
      <path
        id="Path_1436"
        data-name="Path 1436"
        d="M292.163,422.6,283.1,418.23a1.05,1.05,0,1,0-.912,1.891l9.058,4.373a1.05,1.05,0,0,0,.912-1.891Z"
        transform="translate(-281.57 -418.112)"
        fill="#78849e"
      />
    </g>
    <g id="Group_285" data-name="Group 285" transform="translate(27.302 28.35)">
      <path
        id="Path_1437"
        data-name="Path 1437"
        d="M226.069,230.4a4.2,4.2,0,1,0,4.2,4.2,4.2,4.2,0,0,0-4.2-4.2Zm0,6.3a2.1,2.1,0,1,1,2.1-2.1,2.1,2.1,0,0,1-2.1,2.1Z"
        transform="translate(-221.869 -230.4)"
        fill="#78849e"
      />
    </g>
    <g
      id="Group_286"
      data-name="Group 286"
      transform="translate(21.001 24.149)"
    >
      <path
        id="Path_1438"
        data-name="Path 1438"
        d="M179.319,201.916l-6.958-5.423a1.05,1.05,0,1,0-1.291,1.656l6.958,5.423a1.05,1.05,0,1,0,1.291-1.656Z"
        transform="translate(-170.665 -196.27)"
        fill="#78849e"
      />
    </g>
    <g id="Group_287" data-name="Group 287" transform="translate(38.845 27.3)">
      <path
        id="Path_1439"
        data-name="Path 1439"
        d="M327.284,221.867a11.55,11.55,0,1,0,11.545,11.547,11.55,11.55,0,0,0-11.545-11.547Zm0,21a9.45,9.45,0,1,1,9.45-9.45,9.45,9.45,0,0,1-9.45,9.45Z"
        transform="translate(-315.728 -221.867)"
        fill="#78849e"
      />
    </g>
    <g id="Group_288" data-name="Group 288" transform="translate(46.202 31.5)">
      <path
        id="Path_1440"
        data-name="Path 1440"
        d="M379.669,256a4.2,4.2,0,1,0,4.2,4.2A4.2,4.2,0,0,0,379.669,256Zm0,6.3a2.1,2.1,0,1,1,2.1-2.1A2.1,2.1,0,0,1,379.669,262.3Z"
        transform="translate(-375.469 -256)"
        fill="#78849e"
      />
    </g>
    <g id="Group_289" data-name="Group 289" transform="translate(44.1 42)">
      <path
        id="Path_1441"
        data-name="Path 1441"
        d="M367.746,341.333h-6.092A3.214,3.214,0,0,0,358.4,344.5v2.768a1.05,1.05,0,0,0,.524.909,11.491,11.491,0,0,0,11.552,0,1.049,1.049,0,0,0,.524-.908V344.5A3.215,3.215,0,0,0,367.746,341.333Zm1.154,5.308a9.521,9.521,0,0,1-8.4,0V344.5a1.114,1.114,0,0,1,1.154-1.067h6.092A1.114,1.114,0,0,1,368.9,344.5v2.141Z"
        transform="translate(-358.4 -341.333)"
        fill="#78849e"
      />
    </g>
    <g
      id="Group_290"
      data-name="Group 290"
      transform="translate(13.644 39.901)"
    >
      <path
        id="Path_1442"
        data-name="Path 1442"
        d="M122.484,324.267a11.55,11.55,0,1,0,11.545,11.547A11.55,11.55,0,0,0,122.484,324.267Zm0,21a9.45,9.45,0,1,1,9.45-9.45A9.45,9.45,0,0,1,122.484,345.267Z"
        transform="translate(-110.928 -324.267)"
        fill="#78849e"
      />
    </g>
    <g id="Group_291" data-name="Group 291" transform="translate(21.002 44.1)">
      <path
        id="Path_1443"
        data-name="Path 1443"
        d="M174.869,358.4a4.2,4.2,0,1,0,4.2,4.2,4.2,4.2,0,0,0-4.2-4.2Zm0,6.3a2.1,2.1,0,1,1,2.1-2.1,2.1,2.1,0,0,1-2.1,2.1Z"
        transform="translate(-170.669 -358.4)"
        fill="#78849e"
      />
    </g>
    <g id="Group_292" data-name="Group 292" transform="translate(18.9 54.6)">
      <path
        id="Path_1444"
        data-name="Path 1444"
        d="M162.946,443.733h-6.092A3.214,3.214,0,0,0,153.6,446.9v2.768a1.05,1.05,0,0,0,.524.909,11.491,11.491,0,0,0,11.552,0,1.049,1.049,0,0,0,.524-.908V446.9a3.215,3.215,0,0,0-3.255-3.167Zm1.154,5.308a9.521,9.521,0,0,1-8.4,0V446.9a1.115,1.115,0,0,1,1.154-1.067h6.092A1.115,1.115,0,0,1,164.1,446.9v2.141Z"
        transform="translate(-153.6 -443.733)"
        fill="#78849e"
      />
    </g>
    <g id="Group_293" data-name="Group 293" transform="translate(0 13.65)">
      <path
        id="Path_1445"
        data-name="Path 1445"
        d="M11.55,110.933a11.55,11.55,0,1,0,11.55,11.55,11.55,11.55,0,0,0-11.55-11.55Zm0,21a9.45,9.45,0,1,1,9.45-9.45,9.45,9.45,0,0,1-9.45,9.45Z"
        transform="translate(0 -110.933)"
        fill="#78849e"
      />
    </g>
    <g id="Group_294" data-name="Group 294" transform="translate(7.355 17.85)">
      <path
        id="Path_1446"
        data-name="Path 1446"
        d="M63.933,145.067a4.2,4.2,0,1,0,4.2,4.2,4.2,4.2,0,0,0-4.2-4.2Zm0,6.3a2.1,2.1,0,1,1,2.1-2.1,2.1,2.1,0,0,1-2.1,2.1Z"
        transform="translate(-59.738 -145.067)"
        fill="#78849e"
      />
    </g>
    <g id="Group_295" data-name="Group 295" transform="translate(5.25 28.35)">
      <path
        id="Path_1447"
        data-name="Path 1447"
        d="M52.013,230.4H45.921a3.214,3.214,0,0,0-3.254,3.166v2.768a1.051,1.051,0,0,0,.524.909,11.491,11.491,0,0,0,11.552,0,1.049,1.049,0,0,0,.524-.908v-2.768A3.215,3.215,0,0,0,52.013,230.4Zm1.154,5.308a9.521,9.521,0,0,1-8.4,0v-2.141a1.115,1.115,0,0,1,1.154-1.067h6.092a1.115,1.115,0,0,1,1.154,1.067v2.141Z"
        transform="translate(-42.667 -230.4)"
        fill="#78849e"
      />
    </g>
    <g id="Group_296" data-name="Group 296" transform="translate(25.2 0)">
      <path
        id="Path_1448"
        data-name="Path 1448"
        d="M216.35,0A11.55,11.55,0,1,0,227.9,11.55,11.55,11.55,0,0,0,216.35,0Zm0,21a9.45,9.45,0,1,1,9.45-9.45A9.45,9.45,0,0,1,216.35,21Z"
        transform="translate(-204.8 0)"
        fill="#78849e"
      />
    </g>
    <g id="Group_297" data-name="Group 297" transform="translate(32.55 4.2)">
      <path
        id="Path_1449"
        data-name="Path 1449"
        d="M268.734,34.133a4.2,4.2,0,1,0,4.2,4.2,4.2,4.2,0,0,0-4.2-4.2Zm0,6.3a2.1,2.1,0,1,1,2.1-2.1,2.1,2.1,0,0,1-2.1,2.1Z"
        transform="translate(-264.534 -34.133)"
        fill="#78849e"
      />
    </g>
    <g id="Group_298" data-name="Group 298" transform="translate(30.45 14.7)">
      <path
        id="Path_1450"
        data-name="Path 1450"
        d="M256.813,119.467h-6.092a3.214,3.214,0,0,0-3.254,3.166V125.4a1.051,1.051,0,0,0,.524.909,11.491,11.491,0,0,0,11.552,0,1.049,1.049,0,0,0,.524-.908v-2.768a3.215,3.215,0,0,0-3.255-3.167Zm1.154,5.308a9.521,9.521,0,0,1-8.4,0v-2.141a1.115,1.115,0,0,1,1.154-1.067h6.092a1.114,1.114,0,0,1,1.154,1.067v2.141Z"
        transform="translate(-247.467 -119.467)"
        fill="#78849e"
      />
    </g>
    <g
      id="Group_299"
      data-name="Group 299"
      transform="translate(17.924 10.503)"
    >
      <path
        id="Path_1451"
        data-name="Path 1451"
        d="M154.8,85.7a1.051,1.051,0,0,0-1.481-.11l-7.277,6.227a1.05,1.05,0,0,0,1.365,1.6l7.277-6.227A1.049,1.049,0,0,0,154.8,85.7Z"
        transform="translate(-145.67 -85.336)"
        fill="#78849e"
      />
    </g>
    <g
      id="Group_300"
      data-name="Group 300"
      transform="translate(42.013 18.926)"
    >
      <path
        id="Path_1452"
        data-name="Path 1452"
        d="M349.518,162.428l-6.3-8.4a1.05,1.05,0,0,0-1.681,1.259l6.3,8.4a1.05,1.05,0,0,0,1.681-1.258Z"
        transform="translate(-341.345 -153.632)"
        fill="#78849e"
      />
    </g>
    <g
      id="Group_301"
      data-name="Group 301"
      transform="translate(33.818 41.902)"
    >
      <path
        id="Path_1453"
        data-name="Path 1453"
        d="M282.588,340.96a1.05,1.05,0,0,0-1.47-.21l-5.865,4.4a1.049,1.049,0,0,0,1.26,1.678l5.865-4.4a1.049,1.049,0,0,0,.21-1.468Z"
        transform="translate(-274.833 -340.54)"
        fill="#78849e"
      />
    </g>
    <g id="Group_302" data-name="Group 302" transform="translate(6.305 50.401)">
      <path
        id="Path_1454"
        data-name="Path 1454"
        d="M59.6,409.6H52.254a1.05,1.05,0,0,0,0,2.1H59.6a1.05,1.05,0,1,0,0-2.1Z"
        transform="translate(-51.204 -409.6)"
        fill="#78849e"
      />
    </g>
    <g id="Group_303" data-name="Group 303" transform="translate(7.356 0)">
      <path
        id="Path_1455"
        data-name="Path 1455"
        d="M63.932,0a4.2,4.2,0,1,0,4.205,4.2A4.2,4.2,0,0,0,63.932,0Zm0,6.3a2.1,2.1,0,1,1,2.1-2.1,2.1,2.1,0,0,1-2.1,2.1Z"
        transform="translate(-59.738 0)"
        fill="#78849e"
      />
    </g>
    <g id="Group_304" data-name="Group 304" transform="translate(10.5 6.3)">
      <path
        id="Path_1456"
        data-name="Path 1456"
        d="M86.384,51.2a1.05,1.05,0,0,0-1.05,1.05V59.6a1.05,1.05,0,0,0,2.1,0V52.25a1.049,1.049,0,0,0-1.05-1.05Z"
        transform="translate(-85.334 -51.2)"
        fill="#78849e"
      />
    </g>
  </svg>
);
