import React, { useState, useContext, useEffect } from "react";

// classnames
import clsx from "clsx";

// router
import { NavLink, useHistory } from "react-router-dom";

import { Formik, Form } from "formik";
import * as Yup from "yup";

// context
import { AuthContext } from "../../context/AuthContext";
import MixPanel from "../../utils/MixPanel";

// utils
import { axiosAPI } from "../../utils/index";
//import { Helmet } from 'react-helmet';
import { Helmet } from 'react-helmet-async';

import { useParams } from 'react-router-dom'; 

const logInSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

export const Login = () => {
  // local state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // hooks
  const { login, setIsLoggedIn } = useContext(AuthContext);
  const { push } = useHistory();

  const handleOnSubmit = async (data, formikApi) => {
    setError(null);
    await axiosAPI
      .post("/users/login", data)
      .then((res) => {
        if (!!res?.data) {
          login(res.data);
          setIsLoggedIn(!!res.data);

          if (!!res.data.status) push("/explore");
        }
      })
      .catch((err) => {
        setError(err.response.data.error);
        formikApi.setSubmitting(false);
      });
  };

  // const { jobid } = useParams();
  const [job, setJob] = useState(null);

  useEffect(() => {
    async function fetchJob() {
      // const queryParams = new URLSearchParams(window.location.search)
      // var jobid = queryParams.get("id")

      // Make an authenticated API request to fetch the job data
      // console.log("JOB ID",jobid);
      // if(jobid != null){
      // //  const jobData = await axiosAPI.get(`/job/job-by-id-login`, {
      // //      //headers: { Authorization: `Bearer ${token}` },
      // //      params: {
      // //        jobid,
      // //      },
      // //    }).then(res => res.data);

      // //    setJob(jobData);
      // }
    }

    fetchJob();
  }, []);


  return (
    <div id="login">
      {/* {console.log(job)}
      {job != null? <Helmet>
        <title>{job.name ? job.name : " " } - Worqer</title>
       <meta name="title" content={ job.name  } /> 
       <meta name="description" content={ job.description.replace(/(<([^>]+)>)/ig, "")  } /> 
    </Helmet>  : ""} */}
      <Formik
        initialValues={{
          password: "",
          email: "",
        }}
        validationSchema={logInSchema}
        onSubmit={handleOnSubmit}
      >
        {({ errors, touched, setFieldValue, isValid, isSubmitting }) => (
          <>
            {isSubmitting && (
              <div className="bar">
                <p>Loading...</p>
              </div>
            )}

            <div className="modal--content">
              <section>
                <div className="text-center modal--head">
                  <h1 className="login-title">Login</h1>
                  <p className="modal--description">
                    If you don't have an account please signup as...
                  </p>
                  <div
                    className="btn--wrapper w--50"
                    onClick={() => MixPanel.track("SignUp_Company", {})}
                  >
                    <NavLink
                      to="/signup-company"
                      className={clsx(
                        "btn-c btn--underline",
                        loading && "disabled"
                      )}
                    >
                      Company
                    </NavLink>
                  </div>
                  <div
                    className="btn--wrapper w--50"
                    onClick={() => MixPanel.track("SignUp_Crew", {})}
                  >
                    <NavLink
                      to="/signup-crew"
                      className={clsx(
                        "btn-c btn--underline",
                        loading && "disabled"
                      )}
                    >
                      Crew
                    </NavLink>
                  </div>
                </div>
              </section>

              <section>
                {!!error && (
                  <div className="response--error">
                    <p>{error}</p>{" "}
                  </div>
                )}

                <Form className="form--data">
                  <div className="form--group">
                    <input
                      type="text"
                      className="form--control"
                      name="email"
                      placeholder="Email"
                      onChange={(e) => setFieldValue("email", e.target.value)}
                    />
                    {errors.email && touched.email && (
                      <div className="error-text">{errors.email}</div>
                    )}
                  </div>
                  <div className="form--group">
                    <input
                      type="password"
                      className="form--control"
                      name="password"
                      placeholder="Password"
                      onChange={(e) =>
                        setFieldValue("password", e.target.value)
                      }
                    />
                    {errors.password && touched.password && (
                      <div className="error-text">{errors.password}</div>
                    )}
                  </div>
                  <div className="form-details">
                    <label className="form-remember">
                      <input type="checkbox" />
                      Remember Me
                    </label>
                    <div className="forgot-password">
                      <a
                        href="/forgot-password"
                        className="forgot-password-link"
                      >
                        Forgot Password
                      </a>
                    </div>
                  </div>
                  <div className="submit--group">
                    <div className="text-center btn--wrapper w-100">
                      <button
                        className={clsx(
                          "btn-c btn--dark btn--center",
                          loading && "disabled"
                        )}
                        type="submit"
                        disabled={!isValid}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </Form>
              </section>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
