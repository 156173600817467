import React from "react";

export const AccountIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="84.225"
    height="76.292"
    viewBox="0 0 84.225 76.292"
  >
    <g transform="translate(0.5 0.5)">
      <g transform="translate(0 0)">
        <path
          class="a"
          d="M67.568,24.4H5.122A5.128,5.128,0,0,0,0,29.522V81.561a5.128,5.128,0,0,0,5.122,5.122h40.9a1.219,1.219,0,1,0,0-2.438H41.467V76.357a5.108,5.108,0,0,0-3.152-4.728l-6.4-2.669a1.379,1.379,0,0,1-.851-1.276v-1.9a9.056,9.056,0,0,0,2.6-6.336v-5.2c0-4.259-4.049-7.724-9.025-7.724s-9.025,3.465-9.025,7.724v5.2a9.056,9.056,0,0,0,2.6,6.336v1.9a1.379,1.379,0,0,1-.851,1.277l-6.4,2.669a5.108,5.108,0,0,0-3.152,4.728v7.888H5.122a2.687,2.687,0,0,1-2.684-2.684V39.848H70.252V60.095a1.219,1.219,0,1,0,2.438,0V29.522A5.128,5.128,0,0,0,67.568,24.4ZM10.244,76.357A2.677,2.677,0,0,1,11.9,73.879l6.4-2.669a3.811,3.811,0,0,0,2.351-3.527V67.42a1.218,1.218,0,0,0,.69-2.27,6.613,6.613,0,0,1-3.292-5.7v-5.2c0-2.915,2.955-5.286,6.587-5.286s6.587,2.371,6.587,5.286v5.2a6.613,6.613,0,0,1-3.294,5.706,1.219,1.219,0,0,0,.612,2.275c.027,0,.054,0,.08-.005v.264a3.811,3.811,0,0,0,2.351,3.527l6.4,2.669a2.677,2.677,0,0,1,1.652,2.477v7.888H10.244ZM70.252,37.41H2.438V29.522a2.687,2.687,0,0,1,2.684-2.684H67.568a2.687,2.687,0,0,1,2.684,2.684Z"
          transform="translate(0 -24.4)"
        />
      </g>
      <g transform="translate(5.21 6.505)">
        <path
          class="a"
          d="M33.28,64.418h-.013a1.226,1.226,0,1,0,.013,0Z"
          transform="translate(-32.054 -64.418)"
        />
      </g>
      <g transform="translate(13.003 6.505)">
        <path
          class="a"
          d="M81.221,64.418h-.013a1.226,1.226,0,1,0,.013,0Z"
          transform="translate(-79.995 -64.418)"
        />
      </g>
      <g transform="translate(9.1 6.505)">
        <path
          class="a"
          d="M57.21,64.418H57.2a1.226,1.226,0,1,0,.013,0Z"
          transform="translate(-55.984 -64.418)"
        />
      </g>
      <g transform="translate(18.214 6.505)">
        <path
          class="a"
          d="M160.1,64.418H113.268a1.219,1.219,0,0,0,0,2.438H160.1a1.219,1.219,0,1,0,0-2.438Z"
          transform="translate(-112.049 -64.418)"
        />
      </g>
      <g transform="translate(45.661 37.728)">
        <path
          class="a"
          d="M317.649,262.678l-17.563-6.109a1.217,1.217,0,0,0-.8,0l-12.63,4.393a1.219,1.219,0,0,0,.8,2.3l12.229-4.254,16.286,5.665A36.509,36.509,0,0,1,299.686,291.4a35.963,35.963,0,0,1-14.049-17.416,34.142,34.142,0,0,1-2.3-10.184,1.219,1.219,0,0,0-2.438.053,35.83,35.83,0,0,0,2.42,10.906,38.387,38.387,0,0,0,15.718,19.12,1.219,1.219,0,0,0,1.288,0,38.387,38.387,0,0,0,15.718-19.12,35.818,35.818,0,0,0,2.42-10.906A1.218,1.218,0,0,0,317.649,262.678Z"
          transform="translate(-280.903 -256.501)"
        />
      </g>
      <g transform="translate(56.543 50.423)">
        <path
          class="a"
          d="M363.674,334.958a1.219,1.219,0,0,0-1.724,0l-7.538,7.538-4.483-4.483a1.219,1.219,0,0,0-1.724,1.724l5.345,5.345a1.219,1.219,0,0,0,1.724,0l8.4-8.4A1.219,1.219,0,0,0,363.674,334.958Z"
          transform="translate(-347.848 -334.601)"
        />
      </g>
      <g transform="translate(39.029 20.815)">
        <path
          class="a"
          d="M263.441,152.456H242.625a2.523,2.523,0,0,0-2.52,2.52v3.9a2.523,2.523,0,0,0,2.52,2.52H263.44a2.523,2.523,0,0,0,2.52-2.52v-3.9A2.523,2.523,0,0,0,263.441,152.456Zm.082,6.423a.082.082,0,0,1-.082.082H242.625a.082.082,0,0,1-.082-.082v-3.9a.082.082,0,0,1,.082-.082H263.44a.082.082,0,0,1,.082.082Z"
          transform="translate(-240.105 -152.456)"
        />
      </g>
      <g transform="translate(40.33 32.524)">
        <path
          class="a"
          d="M264.939,224.487H249.327a1.219,1.219,0,0,0,0,2.438h15.612a1.219,1.219,0,0,0,0-2.438Z"
          transform="translate(-248.108 -224.487)"
        />
      </g>
      <g transform="translate(40.33 37.728)">
        <path
          class="a"
          d="M258.434,256.5h-9.107a1.219,1.219,0,0,0,0,2.438h9.107a1.219,1.219,0,0,0,0-2.438Z"
          transform="translate(-248.108 -256.502)"
        />
      </g>
    </g>
  </svg>
);
