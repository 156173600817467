import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import NavLinks from "./NavLinks";
import Buttons from "./Buttons";
import SideDrawer from "./SideDrawer";
import { Backdrop } from "../../atoms/index";
import clsx from "clsx";
//import { Helmet } from 'react-helmet';
import { Helmet } from 'react-helmet-async';



export const MainNavigation = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const sideDrawerOpenHandler = () => {
    setSideDrawerOpen((prevState) => !prevState);

    document.getElementsByTagName("body")[0].style.overflow = `${
      !sideDrawerOpen ? "hidden" : "auto"
    }`;
  };

  return (
    <>
    {/* <>
        <Helmet>
        <title>WORQER - We connect workers</title>
          <meta name="title" content="WORQER - We connect workers"/>
          <meta
            name="description"
            content="We connect your operations team to qualified freelance crew at a click of a button"
          />
        </Helmet></> */}
      {sideDrawerOpen && <Backdrop onClick={sideDrawerOpenHandler} zIndex />}
      <SideDrawer show={sideDrawerOpen}>
        <NavLinks onClick={sideDrawerOpenHandler} />
        <Buttons onClick={sideDrawerOpenHandler} />
      </SideDrawer>

      <div className="main-navigation">
        <div className="container">
          <NavLink to="/">
            <img
              src="https://worqman.fra1.digitaloceanspaces.com/images/photo-theworqer-dark-preview.png"
              alt="Worqman"
              className="main-navigation__image"
            />
          </NavLink>
          <div className="main-navigation__buttons">
            <NavLinks />
            <Buttons />
          </div>
        </div>
        <div
          className={clsx("main-nav__hamburger", sideDrawerOpen && "is-active")}
          id="main-nav__hamburger"
          onClick={sideDrawerOpenHandler}
        >
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </>
  );
};
