import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { Portal } from "../../atoms";

const SideDrawer = ({ children, show, ...props }) => {
  const nodeRef = useRef(null);

  return (
    <Portal id="slide-drawer">
      <CSSTransition
        nodeRef={nodeRef}
        in={show}
        timeout={200}
        classNames="slide-in-left"
        mountOnEnter
        unmountOnExit
      >
        <aside ref={nodeRef} className="side-drawer">
          {children}
        </aside>
      </CSSTransition>
    </Portal>
  );
};

export default SideDrawer;
