import React, { useEffect } from "react";

import Banner from "../../shared/Components/Banner/Banner";

// atoms
import { Container } from "../../components/atoms/index";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="privacy-policy">
      <Banner
        backgroundImage="https://worqman.fra1.digitaloceanspaces.com/images/privacy-policy.png"
        title="Privacy Policy"
        height="400px"
      />
      <Container>
        <h4>
          <strong>Privacy Policy</strong>
        </h4>
        <p>
          <i>Date of last update: 09/04/2020 </i>
        </p>
        The protection of your privacy is important for the Crew Boost Ltd site
        (if later referred to as the "Site"). This privacy statement describes
        what measures are taken to protect your privacy when using the services
        of the Site and what rights you have as a user of these services. By
        accessing the Site, you accept the registration of your information and
        the conditions of use described in this privacy statement.
        <br />
        <br />
        <br />
        <h5>Article 1: Collection of your personal information</h5>
        <br />
        We will ask you for information when we need data that identifies you
        personally (personal information) or for the purpose of contacting
        you. In general, this information is necessary when we provide you with
        a service or carry out a transaction which you have requested, for
        example for information on products and services on this Site,
        registration on this Site, access to a priority site with limited access
        or the purchase and registration of products on this Site. The personal
        information we collect may include your name, gender, position,
        nationality, company or organization name, email address, phone number,
        business or personal mailing address, and relevant qualifications. The
        Site may also collect information about your visit, including the name
        of your Internet service provider and the IP address used to access the
        Internet, the date and time of access to the Site, the pages you access
        on the Site and the URL of the site from which you arrived on our
        Site. This information is intended for the analysis of trends and for
        the administration and improvement of the Site.
        <br />
        <br />
        <br />
        <h5> Article 2: Use of your personal data </h5>
        <br />
        The personal information collected on this Site is used for the
        operation of the Site and to allow us to provide you with the service or
        carry out the transaction that you have requested or authorized. Your
        personal data is used to allow you to appear in search results for
        workers for potential employers. They can also be used to facilitate the
        administrative work of employers by gathering a set of user data in
        summary tables and professional planning tools. Employers registered on
        the platform can request access to your personal availability type
        data. If you have given your consent, the employer can view the time
        slots and the days when you are unavailable. In no case do they see the
        content of the unavailability. In the context of these uses, this Site
        may use personal information in order to provide you with more efficient
        customer service, to improve the Site and any other product or service
        on this Site and to facilitate navigation by avoiding you to enter the
        same information several times or by adapting the Site to your
        preferences or interests. In order to provide you with a constant
        environment in your interactions with our Site, the information
        collected may be used in combination with that collected by other
        services on our Site. We may use your personal information to send you
        important information about the product or service you are using. When
        you subscribe to a newsletter or declare that you wish to receive
        electronic advertising messages, our Site may use personalized links or
        other similar technologies to follow the links on which you click in
        electronic messages. Our Site sometimes uses other companies to provide
        certain services on its part. This can include, for example, the hosting
        of websites, the administration platform for subscription subscriptions
        and their payments, answers to questions asked by a customer about
        products and services or even sending information about our products,
        special offers or other new services. We will only transmit to these
        companies the personal information necessary for their service. These
        companies are required to respect the confidentiality of this
        information and may in no case use it for any other purpose. We will
        only disclose your personal information if required by law or if we
        believe in good faith that it is necessary to comply with legislative
        decrees or due process, protect and defend our rights or properties, and
        act in emergency cases to protect the personal safety of our employees
        and site users. The personal information collected on the Site can be
        stored and used in European countries or in any other country by its
        subsidiaries, partners or agents present there. By using the Site, you
        consent to such transfers of your personal information outside your
        country. We respect and comply with Regulation (EU) 2016/679 of the
        European Parliament and of the Council of 27 April 2016, relating to the
        protection of individuals with regard to the processing of personal data
        and to free movement of this data, and repealing Directive 95/46 / EC
        (General Data Protection Regulation).
        <br />
        <br />
        <br />
        <h5> Article 3: Control of your personal information </h5>
        <br />
        Unless otherwise stated in this declaration, the personal information
        that you provide on the Site will not be transmitted outside its
        subsidiaries and partners without your authorization. As described in
        the following section, you can access your personal information and
        choose how you wish to be contacted. This privacy statement and the
        choices you make on the Site do not necessarily apply to personal
        information that you have provided to us in the context of other
        separate products or services. We may send you regular emails to notify
        you of technical or security issues related to a product or service you
        have requested, or to acknowledge receipt of a product or service
        request. In certain services offered on our Site, contact with the
        client is an essential element of the service. You will not be able to
        cancel your subscription to these mailings because they are an integral
        part of the service you have chosen.
        <br />
        <br />
        <br />
        <h5> Article 4: Access to your personal data </h5>
        <br />
        You have the right to access, rectify, delete and oppose your personal
        data. You can exercise this right via or by
        <ul>
          <li>your personal space after being identified; </li>
          <li>the contact form; </li>
          <li>
            mail to <a href="/">info@crewboost.co.uk</a>;{" "}
          </li>

          <li>
            mail to the address 31 Middleton Road, Daventry, Northamptonshire,
            NN11 8BH{" "}
          </li>
        </ul>
        <br />
        <br />
        <br />
        <h5> Article 5: Security of your personal information </h5>
        <br />
        We rigorously guarantee the protection of your personal information. We
        use various security technologies and procedures to protect your
        personal data and prevent it from being viewed, used or disclosed
        without permission. For example, we store the data you provide to us on
        servers with limited access that are located in monitored facilities. In
        addition, when we transmit sensitive confidential information over the
        Internet (a credit card number, for example), this information is
        protected by an encryption system such as SSL (Secure Socket Layer) or
        any other technique. allowing encryption of this information. Article 6:
        Protection of children's personal information Our Site does not
        voluntarily collect personal information from children.
        <br />
        <br />
        <br />
        <h5> Article 7: Cookies </h5>
        <br />
        Cookies are used on this Site to ensure the integrity of the
        registration process and to personalize the Site. A cookie is a small
        text file placed on your hard drive by a web page server. It cannot be
        used to run programs or to infect your computer with viruses. Cookies
        are sent to you exclusively and can only be read by web servers
        belonging to the domain which delivered the cookie to you. One of the
        main practical functions of cookies is to save you time. For example, if
        you personalize a web page, or navigate through a site, the cookie will
        help the site find your specific information during subsequent
        visits. This allows, in particular, to simplify the process of delivery
        of the content that interests you and navigation on the site. When you
        return to the site, the information you previously provided is reused to
        make it easier for you to use the functions of the site you have
        customized. We may also use web beacons, also known as invisible GIFs,
        to assist in the implementation of the cookie. We use this technology as
        a compilation tool for statistics relating to the use of our Site. These
        statistics include the number of visitors who clicked on key elements
        (such as links or graphics) of a web page, a web journal page or a
        newsletter on our Site. We may share compiled statistics with partner
        companies, but we do not allow other companies to place .gif images or
        cookies on our sites, except in the case of temporary special interest
        to our customers ( for example by integrating a service You can accept
        or decline cookies. Most web browsers accept cookies automatically, but
        you can change your browser settings to deny them access to your
        computer if you wish. If you choose to refuse cookies, you may not be
        able to fully use the interactive features of this Site or other
        websites you visit.
        <br />
        <br />
        <br />
        <h5> Article 8: Application of this privacy statement </h5>
        <br />
        This privacy statement applies only to this Site; it cannot be applied
        to other sites, online or not, or to other products or services. By
        accessing the Site, you accept the registration of your information and
        the conditions of use described in this privacy statement. If you have
        any questions regarding this declaration, contact us using the form that
        we make available to you, which you can access after identifying
        yourself or not.
        <br />
        <br />
        <br />
        <h5> Article 9: Changes to this privacy statement </h5>
        <br />
        We occasionally update this privacy statement. In this case, we also
        correct the "last updated" date at the top of the privacy statement. In
        the event of substantial modifications to this declaration, we will
        notify you by placing a notice visible on the home page of the Site or
        by sending you a notification directly. We encourage you to periodically
        review this privacy statement in order to stay informed of how we help
        you protect the personal information we collect. Continued use of this
        service constitutes your agreement to this privacy statement and its
        updates.
        <br />
        <br />
        <br />
        <h5> Contacts </h5>
        <br />
        Your comments regarding this privacy statement are welcome. If you
        believe that our Site has not complied with this privacy statement,
        contact us electronically or by post to the address found on the general
        conditions of use, and we will use all reasonable means to quickly
        determine the problem and fix it.
      </Container>
    </div>
  );
};
