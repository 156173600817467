import React from "react";
import { FiUser, FiSettings } from "react-icons/fi";
import { HiLink } from "react-icons/hi";
import {
  MdOutlineLocationOn,
  MdModeEdit,
  MdBusinessCenter,
  MdOutlineTransgender,
} from "react-icons/md";
import dayjs from "dayjs";
import { FaLayerGroup, FaRegIdCard } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Button } from "../../atoms/index";
import {
  TextDetails,
  CrewSkills,
  Feeds,
  Feed,
  FeedHelptext,
  FeedTitle,
  FeedDescription,
} from "../../molecules";
import { GENDERS } from "../../../static/index";

export const ProfileDetails = (props) => {
  return (
    <div className="profileDetails col-xs-12 col-sm-6 col-lg-7 d-flex flex-column justify-space-between p-2">
      {props.isAuthentication && (
        <span className="d-flex flex-row justify-content-end profileDetails_editBtn">
          <Link to="/profile/edit">
            <Button
              variant="text"
              className="p-1"
              icon={<MdModeEdit className="text_primary" />}
            />
          </Link>
        </span>
      )}

      <div className="d-flex flex-column justify-content-start py-3 pl-5 profileDetails_edit profileDetails_divider">
        <p className="text_gray profileDetails_p">About</p>
        {props.userRole === 1 && (
          <>
            <TextDetails
              link={true}
              label="Website link"
              text={props.user.website || ""}
              icon={<HiLink width={24} height={24} />}
            />
            <TextDetails
              label="Professions"
              text={props.user.profession || ""}
              icon={<FiSettings width={24} height={24} />}
            />

            <TextDetails
              label="Location"
              text={props.user.city || ""}
              icon={<MdOutlineLocationOn width={28} height={28} />}
            />
            <TextDetails
              label="Description"
              text={props.user.description || ""}
              icon={<FaLayerGroup width={28} height={28} />}
            />
          </>
        )}

        {props.userRole === 2 && (
          <>
            <TextDetails
              label="Full name"
              text={props.user.name || ""}
              icon={<FiUser width={24} height={24} />}
            />
            <TextDetails
              label="Location"
              text={props.user.city || ""}
              icon={<MdOutlineLocationOn width={28} height={28} />}
            />
            <TextDetails
              label="Sex"
              text={
                GENDERS.find((item) => item.value === props.user.gender)?.label
              }
              icon={<MdOutlineTransgender width={24} height={24} />}
            />

            {props.user.experiences?.length !== 0 && (
              <Feeds>
                {props.user.experiences &&
                  props.user.experiences?.length !== 0 &&
                  props.user.experiences?.map(
                    ({ id, position, description, company, start, end }) => (
                      <Feed
                        key={`${id}-${position}`}
                        icon={<MdBusinessCenter />}
                        className="my-4"
                      >
                        <FeedHelptext>
                          {dayjs(start).format("DD-MM-YYYY")}
                          {"  "} {"  "}
                          {!end
                            ? "Present"
                            : `to ${dayjs(end).format("DD-MM-YYYY")}`}
                        </FeedHelptext>
                        <FeedTitle>
                          {position}
                          {" - "}
                          {company}
                        </FeedTitle>
                        <FeedDescription>{description}</FeedDescription>
                      </Feed>
                    )
                  )}
              </Feeds>
            )}
            {!!props.user.user_professions?.length && (
              <CrewSkills
                skills={
                  props.user.user_professions?.length &&
                  props.user.user_professions?.map((item) => item.name)
                }
              />
            )}
          </>
        )}

        <div className="mt-5">
          {/* {props.user.pdfs?.length !== 0 && <h4>My Documentsss</h4>} */}
          <div className="d-flex justify-content-start flex-column">
            {props.user.pdfs?.length !== 0 &&
              props.user.pdfs?.map((pdf) => (
                <div
                  key={`${pdf.title}-${pdf.id}`}
                  className="d-flex my-1 justify-content-start flex-row"
                >
                  <FaRegIdCard />
                  <a
                    href={pdf.file}
                    target="_blank"
                    className=" ml-2 text-secondary"
                  >
                    {pdf.title}
                  </a>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
