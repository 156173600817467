// router
import { useHistory } from "react-router-dom";

// config
import { ITEMS_PER_PAGE as itemsPerPage } from "../config/global";

// query string
import queryString from "query-string";

export const usePaginationItemIndex = () => {
  // hooks
  const {
    location: { search },
  } = useHistory();

  // query string
  const { page } = queryString.parse(search);

  const lastItemIndex = page * itemsPerPage;

  const firstItemIndex = lastItemIndex - itemsPerPage;

  return {
    firstItemIndex: firstItemIndex,
    lastItemIndex: lastItemIndex,
  };
};
