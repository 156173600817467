import React from "react";
import Pagination from "rc-pagination";

const Paginaton = (props) => {
  return (
    <div id="pagination">
      {props.length > props.pageSize && (
        <Pagination
          onChange={props.changePage}
          defaultPageSize={props.pageSize || 3}
          current={parseInt(props.current)}
          total={props.length}
          showSizeChanger={true}
        />
      )}
    </div>
  );
};

export default Paginaton;
