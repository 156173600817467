import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";

// icons
import { HiMail } from "react-icons/hi";

export const SignupMessage = () => {
  const [email, setEmail] = useState();

  const history = useHistory();

  useEffect(() => {
    try {
      setEmail(history.location.state.email);
    } catch (err) {
      setEmail("Your Email");
    }
  }, [history]);

  return (
    <Modal
      show={true}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => history.push("/")}
      centered
    >
      <div className="modal--content">
        <section className="modal-remove-wrapper">
          <span
            className="remove-modal-button"
            onClick={() => history.push("/")}
          >
            &#x2715;
          </span>
        </section>

        <div id="signup-message">
          <div className="confirmation-head">
            <HiMail />
            <p className="confirmation-head__title">
              Confirm Your Email Address
            </p>
            <p className="confirmation-head__description">
              We have sent an email to
            </p>
            <p className="confirmation-head__email">{email}</p>
            <p className="confirmation-head__description-2">
              Click on the confirmation link in the email to activate your
              account.
            </p>
          </div>

          <div className="submit--group">
            <div className="btn--wrapper w-100 text-center pt-3 pb-5">
              <NavLink to="/login" className="btn-c btn--dark btn--center">
                Ok
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
