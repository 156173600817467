import Animate from "react-smooth";
import Banner from "../../shared/Components/Banner/Banner";

import {
  OthersThoughts,
  DownloadApp,
  LatestJobs,
} from "../../components/UIPages";
import { Footer } from "../../components/organisms/Footer/Footer";
import React from "react";

export const BecomeWorqer = () => (
  <Animate to="3" from="0" attributeName="opacity">
    <div id="hire">
      <Banner
        image="https://worqman.fra1.digitaloceanspaces.com/images/Architect-Worker-Transparent.png"
        title="Become a worqer"
        description="Worqman connects your business to a community of qualified professionals."
        className="become-worqer"
        secondaryBackground
      />
      <div className="sections">
        <LatestJobs />
        <OthersThoughts freelancer />
        <DownloadApp />
        <Footer />
      </div>
    </div>
  </Animate>
);
