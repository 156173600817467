import { useRef, useState } from "react";
import clsx from "clsx";
import { Modal } from "react-bootstrap";
import {
  MdOutlineTransgender,
  MdCloudUpload,
  MdBusinessCenter,
  MdOutlineLocationOn,
  MdDelete,
} from "react-icons/md";
import dayjs from "dayjs";
import { FaLayerGroup } from "react-icons/fa";
import { FiSettings, FiUser } from "react-icons/fi";
import { HiLink } from "react-icons/hi";
import {
  AvatarInput,
  Button,
  InputField,
  SelectField,
  TextareaField,
  AddWorkExperience,
} from "../../atoms/index";
import { GENDERS, PROFESSIONS } from "../../../static/index";
import { useForm, Controller } from "react-hook-form";
import { axiosAPI, setStorageData } from "../../../utils/index";
import Select, { components } from "react-select";
import { RiArrowDropDownLine } from "react-icons/ri";
import BForm from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import {
  Feeds,
  Feed,
  FeedActions,
  FeedHelptext,
  FeedTitle,
  FeedDescription,
} from "../index";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const customComponents = {
  Control: (rest) => (
    <components.Control {...rest}>
      <>
        <FiSettings className="selectField_icon" />
        {rest.children}
      </>
    </components.Control>
  ),
  IndicatorSeparator: () => null,
  IndicatorsContainer: () => (
    <components.DownChevron>
      <RiArrowDropDownLine />
    </components.DownChevron>
  ),
};

const customStyles = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
    border: "none",
    borderBottom: "1px solid #f4f4f6fd",
    borderRadius: "0",
    cursor: "pinter",
    padding: "4px",
    paddingRight: 0,
    "&:hover": {
      borderColor: "#f4f4f6fd",
    },
  }),
};

const companySchema = Yup.object().shape({
  fullname: Yup.string().required("Required"),
  website: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  profession: Yup.array().min(1, "Select a Profession").required("Required"),
});
const userSchema = Yup.object().shape({
  fullname: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  gender: Yup.number().required("Required"),
  profession: Yup.array().min(1, "Select a Profession").required("Required"),
});

export const CreateProfile = (props) => {
  const [isOpen, setIsOpenAdd] = useState(false);
  const [experience, setExperience] = useState([]);
  const [latLng, setLatLng] = useState(null);
  const [show, setShow] = useState(props.show);

  const handleAddress = (value, setFieldValue) => {
    if (typeof value === "object") {
      setFieldValue("address", value.formatted_address);
      setFieldValue("city", value.formatted_address);
    } else {
      setFieldValue("address", value);
      setFieldValue("city", value);
    }
  };

  const handleSelectAddress = (value) => {
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => setLatLng(latLng))
      .catch((error) => console.error("Error", error));
  };

  const { push } = useHistory();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const myRef = useRef(null);

  const handleUploadProfileImage = async (file) => {
    try {
      let formData = new FormData();
      formData.append("file", file);

      await axiosAPI.post(
        `${props.roleuser === 1 ? "/companies" : "/users"}/profile-picture`,
        formData,
        { headers: { Authorization: "Bearer " + props.token } }
      );
    } catch (err) {
      return console.error(err);
    }
  };

  const handleUploadPdf = async (file) => {
    try {
      let formData = new FormData();
      formData.append("pdf", file);

      await axiosAPI.post("/users/upload-pdf", formData, {
        headers: { Authorization: "Bearer " + props.token },
      });
    } catch (err) {
      return console.error(err);
    }
  };

  const handleOnSubmit = async (formData) => {
    const data =
      props.roleuser === 1
        ? {
          fullname: formData.fullname,
          city: formData.city,
          profession:
            formData.profession.map((i) => i.value).toString() || "",
          website: formData.website,
          description: formData.description,
          latLng,
        }
        : {
          name: formData.name,
          city: formData.city,
          gender: +formData.gender,
          profession: formData.profession.map((i) => i.value) || [],
          experience: "2 years",
          description: formData.description,
          latLng,
        };

    if (!!formData.files && !!formData.files[0]) {
      handleUploadProfileImage(formData.files[0]);
    }
    if (!!formData.uploadCv && !!formData.uploadCv[0]) {
      handleUploadPdf(formData.uploadCv[0]);
    }

    axiosAPI
      .put(
        `${props.roleuser === 1 ? "companies" : "users"}/update-basic-info`,
        data,
        { headers: { Authorization: "Bearer " + props.token } }
      )
      .then((res) => {
        setStorageData("completed-account", true);
        props.handleclose();
        push("/profile");
      })
      .catch((err) => {
        props.handleclose();
        console.error(err);
      });
  };
  const handleClose = () => setShow(false);
  const handleSetIsOpen = (value) => () => setIsOpenAdd(value);
  const handleSetExperience = (value) => {
    if (experience !== null) {
      setExperience([...experience, value]);
    }
  };
  console.log('show', show)
  return (
    <Modal
      size="lg"
      centered
      show={show}
      ref={myRef}
      aria-labelledby="create-profile"
      className={clsx(
        "createProfile",
        isOpen ? "createProfile_hidden" : "createProfile_visible"
      )}
    >
      <Modal.Title id="create-profile">
        Welcome {props.name}, let`s create your profile!
      </Modal.Title>
      <Formik
        initialValues={
          props.user
            ? props.roleuser === 1
              ? {
                name: props?.user?.name || "",
                fullname: props?.user?.fullname || "",
                city: props?.user?.city || "",
                gender: props?.user?.gender || "",
                phone: props?.user?.phone || "",
                website: props?.user?.website || "",
                address: props?.user?.address || props?.user?.city || "",
                description: props?.user?.description || "",
                image: props?.user?.image || "",
                files: [],
                profession:
                  (props?.user?.profession &&
                    props.user.profession.split(",").map((item) => ({
                      label: item,
                      value: item,
                    }))) ||
                  [],
              }
              : {
                name: props?.user?.name || "",
                fullname: props?.user?.name || "",
                city: props?.user?.city || "",
                description: props?.user?.description || "",
                gender: props?.user?.gender || "",
                address: props?.user?.address || props?.user?.city || "",
                profession:
                  (props.user.user_professions &&
                    props.user.user_professions.map((item) => ({
                      label: item.name,
                      value: item.name,
                    }))) ||
                  [],
              }
            : {}
        }
        validationSchema={props?.roleuser === 1 ? companySchema : userSchema}
        onSubmit={handleOnSubmit}
      >
        {({
          errors,
          touched,
          setFieldValue,
          isValid,
          isSubmitting,
          values,
          handleSubmit,
        }) => (
          <>
            <form className="w-100">
              <Modal.Body className="d-flex flex-column justify-content-center align-items-center w-100">
                <AvatarInput
                  name="file"
                  onChange={(event) => {
                    if (event?.target.files?.length) {
                      setFieldValue("files", event?.target.files);
                      setFieldValue(
                        "image",
                        URL.createObjectURL(event?.target.files[0])
                      );
                    }
                  }}
                  accept="image/png,image/gif,image/jpeg"
                />

                {props.roleuser === 1 && (
                  <div className="w-100 my-3">
                    <InputField
                      value={values.fullname}
                      onChange={(e) =>
                        setFieldValue("fullname", e.target.value)
                      }
                      placeholder="Full Name"
                      icon={<FiUser />}
                    />
                    {errors.fullname && touched?.fullname && (
                      <div className="error-message">Full name is required</div>
                    )}
                    <BForm.Group className="selectField mt-3">
                      <div className="d-flex flex-row justify-content-start align-items-center multiSelect_wrapper">
                        <Select
                          onChange={(values) => {
                            setFieldValue("profession", values);
                          }}
                          value={values.profession}
                          options={PROFESSIONS}
                          isMulti
                          className="selectField_input w-100"
                          components={customComponents}
                          styles={customStyles}
                          placeholder="Professions"
                        />
                      </div>
                    </BForm.Group>

                    {errors.profession && touched?.profession && (
                      <div className="error-text m-2 error-message">
                        Professions is required
                      </div>
                    )}

                    <InputField
                      value={values.website}
                      onChange={(e) => setFieldValue("website", e.target.value)}
                      placeholder="Website link"
                      icon={<HiLink />}
                    />
                    {errors.website && touched?.website && (
                      <div className="error-message">
                        Website link is required
                      </div>
                    )}
                    <PlacesAutocomplete
                      value={values.address}
                      onChange={(value) => handleAddress(value, setFieldValue)}
                      //onSelect={handleSelectAddress}
                    >
                      
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div
                          className="search--location"
                          style={{ position: "relative" }}
                        >
                          <div className="search__job__input_control">
                            <InputField
                              {...getInputProps({
                                className: "location-search-input",
                              })}
                              placeholder="city"
                              icon={<MdOutlineLocationOn />}
                            />
                            {errors.location && touched?.location && (
                              <div className="error-message">
                                {errors.location}
                              </div>
                            )}
                          </div>
                          {loading && (
                            <div className="autocomplete-dropdown-container">
                              <div style={{ padding: "15px 32px" }}>
                                Loading...
                              </div>
                            </div>
                          )}

                          {suggestions && suggestions.length >= 1 && (
                            <div className="autocomplete-dropdown-container">
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                  : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                    style={{
                                      padding: "15px 32px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </PlacesAutocomplete>
                    
                    <TextareaField
                      value={values.description}
                      onChange={(e) =>
                        setFieldValue("description", e.target.value)
                      }
                      placeholder="Description"
                      icon={<FaLayerGroup />}
                    />
                    {errors.description && (
                      <div className="error-message">
                        Description is required
                      </div>
                    )}
                  </div>
                )}

                {props.roleuser === 2 && (
                  <div className="w-100 my-3">
                    <InputField
                      name="name"
                      placeholder="Full name"
                      //icon={<FiUser />}
                      value={values.fulname}
                      onChange={(e) => setFieldValue("fulname", e.target.value)}
                      hidden
                    />
                    {errors.fulname && touched?.fulname && (
                      <div className="error-message">Full name is required</div>
                    )}
                    <BForm.Group className="selectField mt-3">
                      <div className="d-flex flex-row justify-content-start align-items-center multiSelect_wrapper">
                        <Select
                          onChange={(values) => {
                            setFieldValue("profession", values);
                          }}
                          value={values.profession}
                          options={PROFESSIONS}
                          isMulti
                          className="selectField_input w-100"
                          components={customComponents}
                          styles={customStyles}
                          placeholder="Professions"
                        />
                      </div>
                    </BForm.Group>

                    {errors.profession && touched.profession && (
                      <div className="error-text m-2 error-message">
                        Professions is required
                      </div>
                    )}

                    <div className="row w-100 justify-content-between align-items-baseline">
                      <div className="w-100 ">
                        <PlacesAutocomplete
                          value={values.address}
                          onChange={(value) =>
                            handleAddress(value, setFieldValue)
                          }
                          //onSelect={handleSelectAddress}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div
                              className="search--location"
                              style={{ position: "relative" }}
                            >
                              <div className="search__job__input_control">
                                <InputField
                                  {...getInputProps({
                                    className: "location-search-input",
                                  })}
                                  placeholder="city"
                                  required
                                  icon={<MdOutlineLocationOn />}
                                />
                                {errors.location && touched.location && (
                                  <div className="error-message">
                                    {errors.location}
                                  </div>
                                )}
                              </div>
                              {loading && (
                                <div className="autocomplete-dropdown-container">
                                  <div style={{ padding: "15px 32px" }}>
                                    Loading...
                                  </div>
                                </div>
                              )}

                              {suggestions && suggestions.length >= 1 && (
                                <div className="autocomplete-dropdown-container">
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    const style = suggestion.active
                                      ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                      : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        style={{
                                          padding: "15px 32px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                      <div className="w-100">
                        <SelectField
                          {...register("gender", { required: true })}
                          value={values.gender}
                          options={GENDERS}
                          onChange={(e) =>
                            setFieldValue("gender", parseInt(e.target.value))
                          }
                          icon={<MdOutlineTransgender />}
                        />
                        {errors.gender && (
                          <div className="error-message">
                            Gender is required
                          </div>
                        )}
                      </div>
                      <div className="w-100 my-3">
                        <TextareaField
                          value={values.description}
                          onChange={(e) =>
                            setFieldValue("description", e.target.value)
                          }
                          placeholder="Description"
                          icon={<FaLayerGroup />}
                        />
                        {errors.description && (
                          <div className="error-message">
                            Description is required
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex flex-column justify-content-start mb-3">
                      <p className="text_gray mb-2 mt-3 profileDetails_p">
                        CERTIFICATES, CV AND OTHER
                      </p>
                      <div className="createProfile_file">
                        <label htmlFor="uploadCv">
                          <Button type="button">
                            <MdCloudUpload />
                          </Button>
                          <p>
                            {values.uploadCv?.length
                              ? values.uploadCv[0].name
                              : "Choose files to upload"}
                          </p>
                        </label>
                        <input
                          type="file"
                          id="uploadCv"
                          name="uploadCv"
                          accept="application/pdf, image/jpg, image/jpeg, image/png"
                          onChange={(e) => {
                            console.log(e?.target.files);
                            setFieldValue("uploadCv", e?.target.files);
                          }}
                        />
                      </div>
                    </div>

                    <p className="text_gray mb-2 mt-3 profileDetails_p">
                      EXPERIENCE
                    </p>

                    {experience?.length !== 0 && (
                      <Feeds>
                        {experience &&
                          experience?.length !== 0 &&
                          experience.map(
                            (
                              { position, description, company, start, end },
                              index
                            ) => (
                              <Feed
                                key={position}
                                icon={<MdBusinessCenter />}
                                className="my-4"
                              >
                                <FeedHelptext>
                                  {dayjs(start).format("DD-MM-YYYY")}
                                  {"  "} {"  "}
                                  {!end
                                    ? "Present"
                                    : `to ${dayjs(end).format("DD-MM-YYYY")}`}
                                </FeedHelptext>
                                <FeedTitle>
                                  {position}
                                  {" - "}
                                  {company}
                                </FeedTitle>
                                <FeedDescription>{description}</FeedDescription>
                                <FeedActions>
                                  <Button
                                    type="button"
                                    variant="text"
                                    icon={<MdDelete />}
                                    onClick={() => {
                                      experience.splice(index, 1);
                                      setExperience([...experience]);
                                    }}
                                    iconOnly
                                    style={{ color: "red" }}
                                  />
                                </FeedActions>
                              </Feed>
                            )
                          )}
                      </Feeds>
                    )}

                    <Button
                      type="button"
                      onClick={handleSetIsOpen(true)}
                      variant="text"
                    >
                      Add new job experience +
                    </Button>

                    <AddWorkExperience
                      show={isOpen}
                      handleSetExperience={handleSetExperience}
                      handleClose={handleSetIsOpen(false)}
                    />
                  </div>
                )}
                <Button
                  type="submit"
                  disabled={!isValid}
                  onClick={() => handleSubmit()}
                  className="py-2 createProfile_btn"
                >
                  SAVE & CONTINUE
                </Button>
                
              </Modal.Body>
            </form>
            <Button
                  onClick={handleClose}
                  variant="outlined"
                  className="my-2 py-2"
                >
                  Cancel
                </Button>
          </>
        )}
      </Formik>
    </Modal>
  );
};
