import { BusinessTestimonialCards } from '../Cards/BusinessTestimonialCards';
import { PageSectionHeading } from './PageSectionHeading';

const freelancersThoughts = [
  {
    id: 1,
    image:
      'https://worqman.fra1.digitaloceanspaces.com/images/guilain-demoutiers-CMaoGYmU8V0-unsplash.jpg',
    quote:
      '“My relationship with businesses keeps on growing. The projects get larger and more technical every year.”',
    author: 'Adam Tracht',
  },

  {
    id: 2,
    image:
      'https://worqman.fra1.digitaloceanspaces.com/images/daniel-thomas-HA-0i0E7sq4-unsplash.jpg',
    quote:
      '“My relationship with businesses keeps on growing. The projects get larger and more technical every year.”',
    author: 'Jenny Rich',
  },
];

const businessesThoughts = [
  {
    id: 1,
    image:
      'https://worqman.fra1.digitaloceanspaces.com/images/michael-dam-mEZ3PoFGs_k-unsplash.jpg',
    quote:
      '“The freelance talent we work with are more productive than we ever thought possible.”',
    author: 'Jenny Rich',
  },

  {
    id: 2,
    image:
      'https://worqman.fra1.digitaloceanspaces.com/images/ben-parker-OhKElOkQ3RE-unsplash.jpg',
    quote:
      '“The freelance talent we work with are more productive than we ever thought possible.”',
    author: 'Adam Tracht',
  },
];

export const OthersThoughts = (props) => (
  <section id="thoughts">
    <PageSectionHeading
      title={
        props.freelancer
          ? 'What other freelancers are saying'
          : 'What businesses are saying'
      }
      text="Lorem ipsum dolor sit amet, coectetur adipiscing elit. Suspendisse varius
    enim in eros."
    />
    <div className="container">
      <BusinessTestimonialCards
        quotes={props.freelancer ? freelancersThoughts : businessesThoughts}
      />
    </div>
  </section>
);
