import React, { useContext, useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEdit,
  faPowerOff,
  faCog,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";

import { AuthContext } from "../../../context/AuthContext";
import MixPanel from "../../../utils/MixPanel";

const Buttons = (props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [mobile, setMobile] = useState(false);

  const { onClick } = props;

  const { push } = useHistory();
  const authContext = useContext(AuthContext);

  const menuHandler = () => {
    if (!mobile) {
      setOpenMenu((prevState) => !prevState);
    }
  };

  const editProfileHandler = () => {
    if (mobile) {
      onClick();
    }
    push("/profile");
  };

  const logoutHandler = () => {
    if (mobile) {
      onClick();
    }
    authContext?.logout();
    push("/");
  };

  const moveToEditData = () => {
    if (mobile) {
      onClick();
    }
    push("/settings");
  };

  // const viewProfileHandler = (e) => {
  //   if (mobile) {
  //     props.onClick();
  //   }
  //   if (auth.status === 2) {
  //     history.push(`/crew/${auth.slug}`);
  //   } else if (auth.status === 1) {
  //     history.push(`/company/${auth.slug}`);
  //   }
  // };

  useEffect(() => {
    if (window.innerWidth < 992) {
      setOpenMenu(true);
      setMobile(true);
    }
  }, []);

  let image;
  if (authContext?.auth.image) {
    image = (
      <img
        src={process.env.REACT_APP_PUBLIC_URL + authContext?.auth.image}
        alt={props.name}
        className="image"
      />
    );
  } else {
    image = (
      <div className="avatar">
        <FontAwesomeIcon
          icon={authContext?.auth.status === 2 ? faUser : faBuilding}
        />
      </div>
    );
  }

  return !authContext?.auth.token ? (
    <div className="nav-buttons">
      <NavLink to="/login" className="nav-login-btn" onClick={onClick}>
        <button
          className="login-btn"
          onClick={() => MixPanel.track("Login_nav", {})}
        >
          <h1>Login</h1>
        </button>
      </NavLink>
      <NavLink to="/signup" className="nav-signup-btn" onClick={onClick}>
        <button
          className="signup-btn"
          onClick={() => MixPanel.track("Sign Up_nav", {})}
        >
          <h1>Signup</h1>
        </button>
      </NavLink>
    </div>
  ) : (
    <div
      className="logged-in-profile-button"
      tabIndex="0"
      onClick={menuHandler}
      onBlur={menuHandler}
    >
      <div className="image">{image}</div>
      <p className="name">{authContext?.auth.name}</p>
      <CSSTransition
        in={openMenu}
        timeout={200}
        classNames="alert"
        mountOnEnter
        unmountOnExit
      >
        <ul className={`small-menu`} onBlur={menuHandler}>
          {((authContext?.auth.token && authContext?.auth.status === 1) ||
            (authContext?.auth.token && authContext?.auth.status === 2)) && (
            <li onClick={editProfileHandler}>
              <FontAwesomeIcon icon={faEdit} />
              Edit your profile
            </li>
          )}
          {((authContext?.auth.token && authContext?.auth.status === 1) ||
            (authContext?.auth.token && authContext?.auth.status === 2)) && (
            <li onClick={moveToEditData}>
              <FontAwesomeIcon icon={faCog} />
              Settings
            </li>
          )}
          <li onClick={logoutHandler}>
            <FontAwesomeIcon icon={faPowerOff} />
            Log out
          </li>
        </ul>
      </CSSTransition>
    </div>
  );
};

export default Buttons;
