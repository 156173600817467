import * as React from "react";

export const WorkerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 431.891 431.891"
    {...props}
  >
    <path
      d="M273.511 232.681v80.34h-115.12v-80.34c6.6-1.04 13.23-1.9 19.87-2.57 25.06-2.57 50.31-2.57 75.37 0 6.64.67 13.27 1.53 19.88 2.57z"
      fill="#ef7954"
    />
    <path
      d="M303.155 336.274v-97.678a370.633 370.633 0 00-29.644-5.915v80.34h-115.12v-80.34a370.96 370.96 0 00-29.654 5.915v97.678h-21.816v95.617h218.05v-95.617h-21.816z"
      fill="#738b93"
    />
    <path
      d="M253.631 230.111v4.13c0 20.78-16.9 37.69-37.68 37.69s-37.69-16.91-37.69-37.69v-4.13c25.06-2.57 50.31-2.57 75.37 0z"
      fill="#738b93"
    />
    <path
      d="M243.631 203.482v30.76c0 15.29-12.39 27.69-27.68 27.69s-27.69-12.4-27.69-27.69v-30.76h55.37z"
      fill="#f9d8b7"
    />
    <path
      d="M243.631 203.482v24.66a66.407 66.407 0 01-27.67 6.01h-.02c-9.83 0-19.22-2.14-27.68-6.02v-24.65h55.37z"
      fill="#edceb2"
    />
    <path
      d="M289.821 106.411l-1.76 12.99-6 44.44c-4.46 33.1-32.71 57.81-66.1 57.81h-.02c-33.39 0-61.65-24.71-66.11-57.81l-6-44.44-1.75-12.99h147.74z"
      fill="#f9d8b7"
    />
    <path
      d="M142.081 106.411l1.75 12.99h24.76l3.9 5.97 7.94 12.16h71.04l7.94-12.16 3.89-5.97h24.76l1.76-12.99h-147.74z"
      fill="#edceb2"
    />
    <path
      d="M304.286 106.406H127.611a87.802 87.802 0 013.46-24.496h169.756a87.85 87.85 0 013.459 24.496z"
      fill="#eda856"
    />
    <path
      d="M238.776 8.1l-5.651 73.811H198.78L193.124 8.1l-.381-4.973C200.132 1.093 207.913 0 215.95 0s15.819 1.093 23.207 3.127l-.381 4.973zM263.306 106.406l-3.897 5.969-7.941 12.156h-71.039l-7.941-12.156-3.897-5.969z"
      fill="#eda856"
    />
    <g fill="#cc731b">
      <path d="M198.78 81.909h34.346v.001h60.148c0-2.859-.155-5.685-.46-8.464-2.972-27.33-20.189-50.382-44.065-61.579a75.76 75.76 0 00-9.59-3.738l-.383-.029-5.003 65.348h-35.642L193.124 8.1l-.381.029a75.911 75.911 0 00-9.59 3.738c-23.881 11.197-41.097 34.249-44.069 61.579a77.342 77.342 0 00-.46 8.464h60.156v-.001zM172.488 112.375h86.921l3.897-5.969h-94.715z" />
    </g>
    <g fill="#eda856">
      <path d="M183.154 11.867l5.908 61.579h-49.978c2.972-27.33 20.189-50.382 44.07-61.579zM292.812 73.446h-49.974l5.908-61.579c23.878 11.197 41.094 34.249 44.066 61.579z" />
    </g>
    <path
      fill="#ef7954"
      d="M128.736 238.596l-48.443 17.446-26.744 80.232h75.187M303.155 238.596l48.443 17.446 26.745 80.232h-75.188"
    />
    <path
      fill="#f9d8b7"
      d="M66.991 336.274h61.746v95.617H66.991zM303.155 336.274h61.746v95.617h-61.746z"
    />
    <path
      fill="#496a72"
      d="M158.391 343.111h115.12v18.91h-115.12zM164.911 362.021h102.08v58.09h-102.08z"
    />
    <path fill="#2d4c51" d="M164.911 362.021h102.08v8.5h-102.08z" />
    <path fill="#496a72" d="M126.064 273.111h35v27h-35z" />
    <path fill="#738b93" d="M133.064 280.111h21v13h-21z" />
    <path fill="#496a72" d="M270.838 273.111h35v27h-35z" />
    <path fill="#738b93" d="M277.838 280.111h21v13h-21z" />
    <path
      fill="#edceb2"
      d="M66.991 336.274h61.746v15.837H66.991zM303.155 336.274h61.746v15.837h-61.746z"
    />
  </svg>
);
