// router
import { useHistory, useLocation } from "react-router-dom";

// atoms
import { Button, ExploreCardImage } from "../../atoms/index";
import { useAuthorization } from "../../../hooks/useAuthorization";

export const ExploreCard = ({ data, asProject }) => {
  // hooks
  const { push } = useHistory();
  const { auth } = useAuthorization();
  const search = useLocation().search;
  const job = new URLSearchParams(search).get("job");

  // handlers
  const handleProfileClick = () => {
    push(
      auth.status === 1
        ? `/crew/${data.slug}${job ? `?job=${job}` : ""}`
        : `/jobs/${data.id}`
    );
  };

  return (
    <div className="explore__card">
      <div className="explore__card__header">
        <div className="explore__card__header_info">
          <ExploreCardImage
            src={
              data.image
                ? data.image
                : auth.status === 1
                ? "https://worqman.fra1.digitaloceanspaces.com/images/profile-placeholder.png"
                : "https://worqman.fra1.digitaloceanspaces.com/images/job-placeholder.jpg"
            }
            isUser={data.jobs_applied !== "undefined"}
          />
          <div>
            <h2>{data.name}</h2>
            {!!data.user_professions &&
              data.user_professions.map((profession) => (
                <p key={profession.name}>{profession.name}</p>
              ))}
            <p>{!!data.description ? data.description : data.location}</p>
          </div>
        </div>
        {data.jobs_applied && data.jobs_applied >= 0 ? (
          <div className="explore__card__header_jobsNumber">
            <div>
              <span>{data.jobs_applied}</span>
            </div>
            <span>Jobs</span>
          </div>
        ) : null}
      </div>
      <hr className="explore__card__divider" />
      <Button
        variant="text"
        className="explore__card__cta"
        onClick={handleProfileClick}
      >
        {!asProject && !auth.status
          ? "Visit Profile"
          : auth.status === 1
          ? "Visit Profile"
          : "Visit Job"}
      </Button>
    </div>
  );
};
