const roadMap = [
  {
    id: 1,
    image:
      'https://worqman.fra1.digitaloceanspaces.com/images/undraw_project_completed_w0oq.svg',
    title: 'Post your projects',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’',
  },
  {
    id: 2,
    image:
      'https://worqman.fra1.digitaloceanspaces.com/images/undraw_booking_re_gw4j.svg',
    title: 'Send booking requests',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’',
  },
  {
    id: 3,
    image:
      'https://worqman.fra1.digitaloceanspaces.com/images/undraw_chatting_2yvo.svg',
    title: 'Chat with freelancers',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’',
  },
];

export const RoadMap = () => (
  <section id="how-it-works">
    <div className="container road-map">
      {roadMap.map((road) => (
        <div key={road.id} className="road">
          <div className="road-image">
            <img src={road.image} alt="roadmap svg" />
          </div>
          <div className="road-info">
            <h2>{road.title}</h2>
            <p>{road.description}</p>
          </div>
        </div>
      ))}
    </div>
  </section>
);
