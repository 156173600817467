import { useCallback, useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import queryString from "query-string";

// react icons
import { GoSettings } from "react-icons/go";
import { IoFilterOutline } from "react-icons/io5";

import { Loading, Ternary } from "../../components/atoms";

// molecules
import {
  EmptyData,
  HeaderBar,
  SearchResultsCard,
  SortModal,
} from "../../components/molecules";
import { AuthContext } from "../../context/AuthContext";

import { axiosAPI } from "../../utils";

export const SearchResults = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [sortModal, setSortModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    location: { search },
  } = useHistory();
  const {
    auth: { token, status },
  } = useContext(AuthContext);

  const { job, address, proffesion, fromDay, toDay, as, range } =
    queryString.parse(search);

  const getAllData = useCallback(async () => {
    try {
      if (token) {
        if (status === 1) {
          const { data } = await axiosAPI.get("/users/all-users", {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              ...(proffesion && { proffesion }),
              ...(address && { location: address }),
              ...(fromDay && { fromDay }),
              ...(toDay && { toDay }),
              ...(as && { as }),
              ...(range && { range }),
            },
          });
          setSearchResults(data);
          setLoading(false);
        } else {
          const { data } = await axiosAPI.get("/job/filtered-jobs", {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              ...(as && { as }),
              ...(job && { job }),
              ...(address && { location: address }),
              ...(range && { range }),
            },
          });
          setSearchResults(data);
          setLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [as, job, fromDay, address, proffesion, status, toDay, token, range]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  // handlers
  const handleClick = () => setSortModal(true);
  const handleClose = () => setSortModal(false);

  if (loading) return <Loading />;

  return (
    <>
      <HeaderBar
        backButton
        title="Results"
        customAction={{
          clickHandler: handleClick,
          icon: status === 2 ? <IoFilterOutline /> : <GoSettings />,
        }}
      />
      <Ternary condition={searchResults.length !== 0} fallback={<EmptyData />}>
        <h3 className="search__results__title">your search results</h3>
        <div className="search__results__cards overflow-y">
          {searchResults.map(({ id, image, name, slug, city, description, location }) => (
            <SearchResultsCard
              key={`${name} - ${description}`}
              id={id}
              image={image}
              name={name}
              slug={slug}
              {...(city && { city })}
              {...(description && { description })}
              {...(location && { location })}
            />
          ))}
        </div>
      </Ternary>

      <SortModal show={sortModal} handleClose={handleClose} />
    </>
  );
};
