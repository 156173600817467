import { useState, useCallback, useEffect } from 'react';
import { ProfileDetails } from '../../components/organisms';
import { useParams } from 'react-router-dom';
import { AvatarProfile, ProfileActions } from '../../components/molecules';
import { ProfileLayout } from '../../components/templates';
import { Loading } from '../../components/atoms';
import { axiosAPI } from '../../utils';

export const CompanyProfile = () => {
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const getProfileData = useCallback(async (slug) => {
    try {
      const { data } = await axiosAPI.get('/companies/company-by-slug', {
        params: { slug },
      });

      setUser(data.company);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!!slug) {
      getProfileData(slug);
    }
  }, [getProfileData, slug]);

  if (loading) {
    return <Loading />;
  }

  return (
    <ProfileLayout isAuthentication={false} userRole={1}>
      <>
        <div className="d-flex flex-column align-items-center justify-content-start col-xs-12 col-sm-6 col-md-5 col-lg-5 py-5 md-py-2 border-end">
          <AvatarProfile
            src={
              user.image ||
              'https://worqman.fra1.digitaloceanspaces.com/images/profile-placeholder-3.png'
            }
          />

          <h3 className="h3 text-center my-3 mt-5">{user.name}</h3>
          <h6 className="h6 m-0">{user && user.jobs.length} active jobs</h6>
          <p className="m-0">{user?.city}</p>

          <ProfileActions user={user} isAuthentication={false} userRole={1} />
        </div>
        <ProfileDetails user={user} isAuthentication={false} userRole={1} />
      </>
    </ProfileLayout>
  );
};
