import * as React from "react";

export const ToolsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 443.489 443.489"
    {...props}
  >
    <path
      d="M46.83 35.454l27.57 59.62-29.17 13.41v-.01c-14.96-21.74-14.82-51.24 1.6-73.02z"
      fill="#b3b3b3"
    />
    <path
      fill="gray"
      d="M85.12 116.174l12.06 25.57-12.95 6.1-12.2-25.88 12.94-6.1zM110.48 103.834l12.2 25.88-12.94 6.1-.15-.31-12.06-25.56z"
    />
    <path
      fill="#999"
      d="M109.59 135.504l.15.31-12.56 5.93-12.06-25.57-.15-.31 12.56-5.92z"
    />
    <path
      d="M97.18 141.744l12.56-5.93 12.94-6.1-12.2-25.88-12.95 6.11-12.56 5.92-12.94 6.1 12.2 25.88 12.95-6.1zm58.21-64.95c0 4.96-.41 9.84-1.68 14.43-.92 3.35-5.14 13.99-5.14 13.99l-.02.05-23.2 56.88a123.93 123.93 0 00-9.12 42.85l-1.91-.07h-44.9c-2.78-18.6-9.76-36.41-20.52-52.03l-24.09-35.03 15.52-7.13 4.9-2.25 29.17-13.41 49.03-22.54-26.67-58.15c32.72 2.01 58.63 29.18 58.63 62.41z"
      fill="#ccc"
    />
    <path
      d="M116.15 375.974v30.45c0 12.53-10.15 22.69-22.68 22.69-6.27 0-11.93-2.54-16.04-6.65-4.1-4.1-6.64-9.77-6.64-16.04v-30.45h45.36zm-13.12 29.76c0-5.19-4.2-9.4-9.4-9.4-5.19 0-9.4 4.21-9.4 9.4s4.21 9.4 9.4 9.4c5.2 0 9.4-4.21 9.4-9.4zM114.32 204.924l1.91.07c-.06 1.35-.08 2.7-.08 4.06v11.2H72.01l-1.26.04c-.12-5.16-.57-10.29-1.33-15.37h44.9z"
      fill="#999"
    />
    <path
      d="M116.15 220.254v155.72H70.79v-152.67c0-1-.01-2-.04-3.01l1.26-.04h44.14z"
      fill="#ef7954"
    />
    <path
      d="M302.184 44.604c1.05 16.29-6.12 30.95-17.76 40.25-16.08 12.85-25.14 32.57-25.14 53.15v204.34c0 11.07 2.75 21.93 7.8 31.78 3.33 6.49 5.2 13.84 5.2 21.63 0 26.74-22.1 48.31-49.03 47.46l13.98-26.66c5.39-10.27 1.43-22.96-8.85-28.35a20.944 20.944 0 00-9.74-2.41c-7.55 0-14.85 4.09-18.61 11.25l-13.82 26.36c-5.05-7.01-8.24-15.43-8.82-24.52-1.05-16.28 6.12-30.95 17.76-40.25 16.08-12.85 25.13-32.56 25.13-53.15v-204.34c0-11.06-2.74-21.93-7.79-31.78a47.166 47.166 0 01-5.21-21.62c0-26.75 22.11-48.32 49.04-47.47l-13.98 26.66c-5.39 10.27-1.43 22.97 8.84 28.35 3.12 1.63 6.45 2.41 9.74 2.41 7.56 0 14.86-4.09 18.62-11.25l13.82-26.36c5.04 7.01 8.24 15.43 8.82 24.52zm-54.4 266.14v-178a7.998 7.998 0 00-13.65-5.66 7.981 7.981 0 00-2.35 5.66v178c0 4.42 3.59 8 8 8 4.42 0 8-3.58 8-8z"
      fill="#ccc"
    />
    <path
      d="M247.784 132.744v178c0 4.42-3.58 8-8 8-4.41 0-8-3.58-8-8v-178c0-2.21.9-4.21 2.35-5.66a7.998 7.998 0 0113.65 5.66z"
      fill="#999"
    />
    <path
      fill="#ef7954"
      d="M418.427 285.364v9.73l-16.5 7.5h-44l-16.5-7.5v-9.73h46.5zM401.927 316.594v97.5c0 1.47-.14 2.9-.42 4.29l-1.58-.29h-40.5l-1.09.2c-.27-1.36-.41-2.76-.41-4.2v-97.5h44z"
    />
    <path
      fill="#999"
      d="M401.927 302.594v14h-44v-14zM399.927 418.094l1.58.29c-.86 4.36-3 8.25-6.02 11.27a21.931 21.931 0 01-15.56 6.44c-10.71 0-19.64-7.66-21.59-17.8l1.09-.2h40.5z"
    />
    <path
      fill="#ccc"
      d="M388.467 31.694l8.54 31.32-8.54 14.79h-.54v207.56h-16V77.804h-.54l-8.54-14.79 8.54-31.32z"
    />
  </svg>
);
