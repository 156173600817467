// router
import { Link } from 'react-router-dom';

// react icons
import { MdNotifications,MdNotificationsNone } from 'react-icons/md';
import { useAuthorization } from '../../../hooks/useAuthorization';
import { useHistory } from 'react-router-dom';

import { axiosAPI } from '../../../utils';
import { Button } from '../Button/Button';

export const NotificationItem = ({ userName, jobStatus, jobId, jobName, id, seen_by_company, seen_by_crew }) => {
  const {
    auth: {token, status },
  } = useAuthorization();
  const { push } = useHistory();

  const handleClick = async(id, status, notification_id) => {
    var url = status !== 2 ? "/invitation/seen-by-company": "/invitation/seen-by-crew";
    try {
      if (token) {
        await axiosAPI.post(url, {notification_id:notification_id }, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }

    } catch (err) {
      console.error(err);
    }

    push(`/jobs/${id}`);

  };

  return (
    <li className="notification__item">
      {status !== 2 ?
      seen_by_company !== 1 ? <MdNotifications fontSize={30} color="#2BD49C" /> :<MdNotificationsNone fontSize={30} color="dark" />
      : 
      seen_by_company !== 2 ? <MdNotifications fontSize={30} color="#2BD49C" /> :<MdNotificationsNone fontSize={30} color="dark" />
      }
      
      <div className="notification__content">
        {status === 2 ? (
          <h3>You have a new booking request for job {jobName}</h3>
        ) : (
          <h3>
            Your request to {userName} for job {jobName} has been{' '}
            {jobStatus === 0 ? ' Declined' : ' Accepted'}
          </h3>
        )}
       {/* <Link to={{ pathname: `/notifications/${jobId}` }}>See JOB &gt;</Link> */}
        <a onClick={(e) => handleClick(id ,status, jobId)} href={`/jobs/${id}`}>See JOB &gt;</a>
        
      </div>
    </li>
  );
};
