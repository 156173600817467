import React, { useRef, useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import { Button, InputField } from '../../atoms';
import { useAuthorization } from '../../../hooks/useAuthorization';
import { axiosAPI } from '../../../utils';
import { useForm } from 'react-hook-form';
import { AiOutlineLock } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

export const AccountSettings = ({ show, title, handleClose, ...props }) => {
  const [deactivation, setDeactivated] = useState(null);
  const { auth } = useAuthorization();
  const { push } = useHistory();

  const {
    watch,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: props.user.email ? { email: props.user.email } : {},
  });

  const password = useRef({});
  password.current = watch('password', '');
  
  const handleOnSubmit = async (formData) => {
    try {
      await axiosAPI.put(
        `users/update-password`,
        {
          email: formData.email,
          oldPassword: formData.password,
          newPassword: formData.newpassword,
          status: auth.status,
        },
        { headers: { Authorization: 'Bearer ' + auth.token } }
      );

      handleClose();
      push('/profile');
    } catch (err) {
      return console.error(err.response.data.message);
    }

    reset({}, { keepDefaultValues: false });
  };

  const handleChangeSwitch = async () => {
    try {
      let response = await axiosAPI.put(
        `/${auth.status === 1 ? 'companies' : 'users'}/update-deactivation`,
        null,
        { headers: { Authorization: 'Bearer ' + auth.token } }
      );
      setDeactivated(!response.data.deactivated);
      window.scrollTo(0, 0);
    } catch (err) {
      return console.error(err.response.data.message);
    }
  };

  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      aria-labelledby="account-settings"
      className="addJob accountSettings"
    >
      <Modal.Title id="add-job">{title}</Modal.Title>

      <Modal.Body className="d-flex flex-column justify-content-center align-items-center w-100">
        <form className="w-100 my-3">
          <InputField
            placeholder="Email"
            icon={<FaUser />}
            {...register('email', {
              required: true,
              pattern: /^\S+@\S+\.\S+$/,
            })}
          />
          {errors.email?.type === 'required' && (
            <div className="error-text">Email is required</div>
          )}
          {errors.email?.type === 'pattern' && (
            <div className="error-text">Field must be email</div>
          )}
          <InputField
            type="password"
            placeholder="Old password"
            icon={<AiOutlineLock />}
            {...register('password', {
              required: true,
              minLength: 8,
            })}
          />
          {errors.password?.type === 'required' && (
            <div className="error-text">Password is required</div>
          )}
          {errors.password?.type === 'minLength' && (
            <div className="error-text">Min length is 8 characters</div>
          )}
          <InputField
            type="password"
            placeholder="New Password"
            name="newpassword"
            icon={<AiOutlineLock />}
            {...register('newpassword')}
          />
          {/* {errors.confirm?.type === 'validate' && (
            <div className="error-text">Password does not match</div>
          )} */}
        </form>
        <div className="w-100 my-3">
          <p className="mt-4">
            Account activation/deactivation happens in real time. No need to
            press save button for it.
          </p>

          <Form className="mb-2">
            <Form.Check
              type="switch"
              id="custom-switch"
              onChange={handleChangeSwitch}
              checked={deactivation != null ? deactivation : !props.user.deactivated}
            />
          </Form>
          {(deactivation != null ? deactivation : !props.user.deactivated)?  <p className="m-1">Your account is active.</p>: 
          <p className="m-1">If you deactivate your account you will not be seen by other companies and freelancers. </p>}
        </div>
        <div className="addJob_actions">
          <Button
            onClick={handleClose}
            variant="outlined"
            className="my-2 py-2"
          >
            Cancel
          </Button>

          <Button
            disabled={!errors}
            onClick={handleSubmit(handleOnSubmit)}
            className="my-2 py-2"
          >
            Save changes
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
