import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  MdDeleteForever,
  MdCloudUpload,
  MdOutlineTransgender,
  MdBusinessCenter,
  MdOutlineLocationOn,
  MdDelete,
  MdModeEdit,
} from "react-icons/md";
import { FiSettings, FiUser } from "react-icons/fi";
import { HiLink } from "react-icons/hi";
import { FaLayerGroup } from "react-icons/fa";
import {
  AddPdf,
  Button,
  InputField,
  SelectField,
  TextareaField,
  AddWorkExperience,
  EditWorkExperience,
} from "../../atoms/index";
import { CrewSkills } from "../../molecules/index";
import { useForm, Controller } from "react-hook-form";
import { axiosAPI } from "../../../utils/index";
import { GENDERS, PROFESSIONS } from "../../../static/index";
import Select, { components } from "react-select";
import dayjs from "dayjs";
import { RiArrowDropDownLine } from "react-icons/ri";
import BForm from "react-bootstrap/Form";
import {
  Feeds,
  Feed,
  FeedActions,
  FeedHelptext,
  FeedTitle,
  FeedDescription,
} from "../../molecules";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { Formik, Form } from "formik";
import * as Yup from "yup";

const customComponents = {
  Control: (rest) => (
    <components.Control {...rest}>
      <>
        <FiSettings className="selectField_icon" />
        {rest.children}
      </>
    </components.Control>
  ),
  IndicatorSeparator: () => null,
  IndicatorsContainer: () => (
    <components.DownChevron>
      <RiArrowDropDownLine />
    </components.DownChevron>
  ),
};

const customStyles = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
    border: "none",
    borderBottom: "1px solid #f4f4f6fd",
    borderRadius: "0",
    cursor: "pinter",
    padding: "4px",
    paddingRight: 0,
    "&:hover": {
      borderColor: "#f4f4f6fd",
    },
  }),
};

const companySchema = Yup.object().shape({
  fullname: Yup.string().required("Required"),
  website: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  profession: Yup.array().min(1, "Select a Profession").required("Required"),
});
const userSchema = Yup.object().shape({
  fullname: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  gender: Yup.number().required("Required"),
  profession: Yup.array().min(1, "Select a Profession").required("Required"),
});

export const EditProfileDetails = (props) => {
  const { push } = useHistory();
  const [openPdfModal, setPdfModalOpened] = useState(false);
  const [editExperience, setEditExperience] = useState();
  const [editExperienceModal, setEditExperienceModal] = useState(false);
  const [isOpen, setIsOpenAdd] = useState(false);
  const [latLng, setLatLng] = useState(null);
  const [error, setError] = useState(null);

  const handleAddress = (value, setFieldValue) => {
    if (typeof value === "object") {
      setFieldValue("address", value.formatted_address);
      setFieldValue("city", value.formatted_address);
    } else {
      setFieldValue("address", value);
      setFieldValue("city", value);
    }
  };

  const handleSelectAddress = (value) => {
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => setLatLng(latLng))
      .catch((error) => console.error("Error", error));
  };

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: props.user
      ? {
          name: props.user.name,
          fullname: props.user.fullname,
          city: props.user.city,
          gender: props.user.gender,
          phone: props.user.phone,
          website: props.user.website,
          description: props.user.description,
        }
      : {},
  });

  const handleOnSubmit = async (formData) => {
    const data =
      props.userRole === 1
        ? {
            fullname: formData.fullname,
            city: formData.address,
            profession:
              formData.profession.map((item) => item.value).toString() || "",
            website: formData.website,
            description: formData.description,
            latLng,
          }
        : {
            name: formData.fullname,
            city: formData.address,
            gender: formData.gender,
            description: formData.description,
            profession: formData.profession.map((i) => i.value) || [],
            experience: "2 years",
            latLng,
          };

    try {
      await axiosAPI.put(
        `${props.userRole === 1 ? "companies" : "users"}/update-basic-info`,
        data,
        { headers: { Authorization: "Bearer " + props.token } }
      );
    } catch (err) {
      setError(err.response.data.error);
    }
    push("/profile");
  };

  const handlePdfModal = (value) => () => setPdfModalOpened(value);
  const handleSetIsOpen = (value) => () => setIsOpenAdd(value);
  const handleCloseEditModal = () => setEditExperienceModal(false);

  const handleEditModal = (value) => () => {
    setEditExperience(value);
    setEditExperienceModal(true);
  };

  const deletePdfHandler = async (id) => {
    try {
      await axiosAPI.delete("pdf/delete-pdf", {
        data: { id: id },
        headers: { Authorization: "Bearer " + props.token },
      });
    } catch (err) {
      console.error(err);
    }
    push("/profile");
  };

  const deleteExperienceHandler = async (id) => {
    try {
      await axiosAPI.delete("experience/delete-experience", {
        data: { id: id },
        headers: { Authorization: "Bearer " + props.token },
      });
    } catch (err) {
      console.error(err);
    }
    push("/profile");
  };

  return (
    <div className="profileDetails col-xs-12 col-sm-6 col-lg-7 d-flex flex-column justify-space-between p-2">
      <Formik
        initialValues={
          props.user
            ? props?.userRole === 1
              ? {
                  name: props?.user?.name || "",
                  fullname: props?.user?.fullname || "",
                  city: props?.user?.city || "",
                  gender: props?.user?.gender || "",
                  phone: props?.user?.phone || "",
                  website: props?.user?.website || "",
                  address: props?.user?.address || props?.user?.city || "",
                  description: props?.user?.description || "",
                  profession:
                    (props?.user?.profession &&
                      props.user.profession.split(",").map((item) => ({
                        label: item,
                        value: item,
                      }))) ||
                    [],
                }
              : {
                  name: props?.user?.name || "",
                  fullname: props?.user?.name || "",
                  city: props?.user?.city || "",
                  description: props?.user?.description || "",
                  gender: props?.user?.gender || "",
                  address: props?.user?.address || props?.user?.city || "",
                  profession:
                    (props.user.user_professions &&
                      props.user.user_professions.map((item) => ({
                        label: item.name,
                        value: item.name,
                      }))) ||
                    [],
                }
            : {}
        }
        validationSchema={props?.userRole === 1 ? companySchema : userSchema}
        onSubmit={handleOnSubmit}
      >
        {({
          errors,
          touched,
          setFieldValue,
          isValid,
          isSubmitting,
          values,
        }) => (
          <>
            {isSubmitting && (
              <div className="bar">
                <p>Loading...</p>
              </div>
            )}
            <Form className="w-100">
              <div className="d-flex flex-column justify-content-start py-3 pl-5 profileDetails_edit">
                <section>
                  {error && (
                    <div className="response--error">
                      <div>
                        <p>{error}</p>
                      </div>
                    </div>
                  )}
                </section>
                <p className="text_gray profileDetails_p">About</p>

                <div className="profileDetails_edit_input">
                  {props.userRole === 1 && (
                    <div className="w-100 my-3">
                      <InputField
                        name="fullname"
                        value={values.fullname}
                        placeholder="Full name"
                        icon={<FiUser />}
                        onChange={(e) =>
                          setFieldValue("fullname", e.target.value)
                        }
                      />
                      {errors.fullname && touched.fullname && (
                        <div className="error-message">{errors.fullname}</div>
                      )}

                      <BForm.Group className="selectField mt-3">
                        <div className="d-flex flex-row justify-content-start align-items-center multiSelect_wrapper">
                          <Select
                            onChange={(values) => {
                              setFieldValue("profession", values);
                            }}
                            value={values.profession}
                            options={PROFESSIONS}
                            isMulti
                            className="selectField_input w-100"
                            components={customComponents}
                            styles={customStyles}
                            placeholder="Professions"
                          />
                        </div>
                      </BForm.Group>

                      {errors.profession && touched.profession && (
                        <div className="error-text m-2">
                          {errors.profession}
                        </div>
                      )}

                      <InputField
                        placeholder="Website link"
                        value={values.website}
                        onChange={(e) =>
                          setFieldValue("website", e.target.value)
                        }
                        icon={<HiLink />}
                      />
                      {errors.website && touched.website && (
                        <div className="error-message">{errors.website}</div>
                      )}
                      <PlacesAutocomplete
                        value={values.address}
                        onChange={(value) =>
                          handleAddress(value, setFieldValue)
                        }
                        //onSelect={handleSelectAddress}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div
                            className="search--location"
                            style={{ position: "relative" }}
                          >
                            <div className="search__job__input_control">
                              <InputField
                                {...getInputProps({
                                  className: "location-search-input",
                                })}
                                placeholder="city"
                                icon={<MdOutlineLocationOn />}
                              />
                              {errors.location && touched.location && (
                                <div className="error-message">
                                  {errors.location}
                                </div>
                              )}
                            </div>
                            {loading && (
                              <div className="autocomplete-dropdown-container">
                                <div style={{ padding: "15px 32px" }}>
                                  Loading...
                                </div>
                              </div>
                            )}

                            {suggestions && suggestions.length >= 1 && (
                              <div className="autocomplete-dropdown-container">
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                      style={{
                                        padding: "15px 32px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </PlacesAutocomplete>
                      <TextareaField
                        onChange={(e) =>
                          setFieldValue("description", e.target.value)
                        }
                        value={values.description}
                        placeholder="Description"
                        icon={<FaLayerGroup />}
                      />
                      {errors.description && touched.description && (
                        <div className="error-message">
                          {errors.description}
                        </div>
                      )}
                    </div>
                  )}
                  {props.userRole === 2 && (
                    <>
                      <InputField
                        name="name"
                        placeholder="Full name"
                        value={values.fullname}
                        onChange={(e) =>
                          setFieldValue("fullname", e.target.value)
                        }
                        icon={<FiUser />}
                      />
                      {errors.name && touched.name && (
                        <div className="error-message">
                          Full name is required
                        </div>
                      )}
                      <PlacesAutocomplete
                        value={values.city}
                        onChange={(value) =>
                          handleAddress(value, setFieldValue)
                        }
                       // onSelect={handleSelectAddress}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div
                            className="search--location"
                            style={{ position: "relative" }}
                          >
                            <div className="search__job__input_control">
                              <InputField
                                {...getInputProps({
                                  placeholder: "city",
                                  className: "location-search-input",
                                })}
                                placeholder="city"
                                icon={<MdOutlineLocationOn />}
                              />
                              {errors.city && touched.city && (
                                <div className="error-message">
                                  Location is required
                                </div>
                              )}
                            </div>
                            {loading && (
                              <div className="autocomplete-dropdown-container">
                                <div style={{ padding: "15px 32px" }}>
                                  Loading...
                                </div>
                              </div>
                            )}

                            {suggestions && suggestions.length >= 1 && (
                              <div className="autocomplete-dropdown-container">
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                      style={{
                                        padding: "15px 32px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </PlacesAutocomplete>

                      <TextareaField
                        placeholder="Description"
                        value={values.description}
                        onChange={(e) =>
                          setFieldValue("description", e.target.value)
                        }
                        icon={<FaLayerGroup />}
                      />
                      {errors.description && touched.description && (
                        <div className="error-message">
                          Description is required
                        </div>
                      )}

                      <BForm.Group className="selectField mt-3">
                        <div className="d-flex flex-row justify-content-start align-items-center multiSelect_wrapper">
                          <Select
                            onChange={(values) => {
                              setFieldValue("profession", values);
                            }}
                            value={values.profession}
                            options={PROFESSIONS}
                            isMulti
                            className="selectField_input w-100"
                            components={customComponents}
                            styles={customStyles}
                            placeholder="Professions"
                          />
                        </div>
                      </BForm.Group>

                      {errors.profession && touched.profession && (
                        <div className="error-text m-2">
                          Professions is required
                        </div>
                      )}

                      <SelectField
                        value={values.gender}
                        options={GENDERS}
                        onChange={(e) =>
                          setFieldValue("gender", parseInt(e.target.value))
                        }
                        icon={<MdOutlineTransgender />}
                      />
                      {errors.gender && touched.gender && (
                        <div className="error-message">Gender is required</div>
                      )}
                      <div className="d-flex flex-column justify-content-start mb-3">
                        <p className="text_gray mb-2 mt-3 profileDetails_p">
                          CERTIFICATES, CV AND OTHER
                        </p>
                        <div className="createProfile_file">
                          <label htmlFor="uploadCv">
                            <Button
                              type="button"
                              onClick={handlePdfModal(true)}
                            >
                              <MdCloudUpload />
                            </Button>
                            <p>Choose files to upload</p>
                          </label>
                        </div>
                      </div>
                      <AddPdf
                        show={openPdfModal}
                        handleClose={handlePdfModal(false)}
                      />
                      <div className="mt-5">
                        {props.user.pdfs?.length !== 0 && <h6>My Documents</h6>}
                        <div className="d-flex justify-content-start flex-column">
                          {props.user.pdfs?.length !== 0 &&
                            props.user.pdfs.map((pdf) => (
                              <div
                                key={`${pdf.title}-${pdf.id}`}
                                className="d-flex my-2 justify-content-start flex-row align-items-center"
                              >
                                <Button
                                  type="button"
                                  className="py-1 px-2 mr-1 "
                                  style={{ background: "red" }}
                                  onClick={() => deletePdfHandler(pdf.id)}
                                >
                                  <MdDeleteForever width={20} />
                                </Button>
                                <p className="text-secondary m-0 ml-2">
                                  {pdf.title}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>

                      {props.user.experiences?.length !== 0 && (
                        <Feeds>
                          <h6>My experiences</h6>
                          {props.user.experiences &&
                            props.user.experiences?.length !== 0 &&
                            props.user.experiences.map(
                              ({
                                id,
                                position,
                                description,
                                company,
                                start,
                                end,
                              }) => (
                                <Feed
                                  key={`${id}-${position}`}
                                  icon={<MdBusinessCenter />}
                                  className="my-4"
                                >
                                  <FeedHelptext>
                                    {dayjs(start).format("DD-MM-YYYY")}
                                    {"  "} {"  "}
                                    {!end
                                      ? "Present"
                                      : `to ${dayjs(end).format("DD-MM-YYYY")}`}
                                  </FeedHelptext>
                                  <FeedTitle>
                                    {position}
                                    {" - "}
                                    {company}
                                  </FeedTitle>
                                  <FeedDescription>
                                    {description}
                                  </FeedDescription>
                                  <FeedActions>
                                    <Button
                                      onClick={() =>
                                        deleteExperienceHandler(id)
                                      }
                                      type="button"
                                      style={{ color: "red" }}
                                      variant="text"
                                      iconOnly
                                      icon={<MdDelete />}
                                    />
                                    <Button
                                      onClick={handleEditModal({
                                        id,
                                        position,
                                        description,
                                        company,
                                        start,
                                        end,
                                      })}
                                      type="button"
                                      variant="text"
                                      iconOnly
                                      icon={<MdModeEdit />}
                                    />
                                  </FeedActions>
                                </Feed>
                              )
                            )}
                        </Feeds>
                      )}
                      <Button
                        type="button"
                        className="my-3"
                        onClick={handleSetIsOpen(true)}
                        variant="text"
                      >
                        Add new job experience +
                      </Button>

                      <AddWorkExperience
                        show={isOpen}
                        editMode={true}
                        handleClose={handleSetIsOpen(false)}
                      />
                      {!!editExperience && (
                        <EditWorkExperience
                          experience={editExperience}
                          show={editExperienceModal}
                          editMode={true}
                          handleClose={handleCloseEditModal}
                        />
                      )}

                      <CrewSkills skills={["Inspector"]} inputName={"skills"} />
                    </>
                  )}
                </div>
              </div>

              <div className="row profileDetails_edit_btns">
                <span className="col my-3">
                  <Link to="/profile" className="text-decoration-none">
                    <Button
                      variant="outlined"
                      className="py-2 profileDetails_edit_btn"
                    >
                      Cancel
                    </Button>
                  </Link>
                </span>
                <span className="col m-3">
                  <Button
                    className="py-2 profileDetails_edit_btn"
                    // type="submit"
                  >
                    Save changes
                  </Button>
                </span>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};
