import { JobSearch } from '../JobSearch/JobSearch';
import { CrewSearch } from '../CrewSearch/CrewSearch';

export const SearchBar = ({ isCompany }) => {
  return (
    <div className="searchbar">
      <h3 className="searchbar__title">Search</h3>
      <div className="searchbar__form__wrapper">
        {isCompany ? <CrewSearch /> : <JobSearch />}
      </div>
    </div>
  );
};
