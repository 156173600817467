import React, { useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { axiosAPI } from "../../../utils/index";
import { AuthContext } from "../../../context/AuthContext";

export const ConfirmUserEmail = () => {
  const history = useHistory();
  const token = useParams().token;
  const { login, setIsLoggedIn, auth } = useContext(AuthContext);

  useEffect(() => {
    if (!token) {
      history.goBack();
    }

    const sendRequest = async () => {
      axiosAPI
        .post(
          "/users/confirm/email",
          {},
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((res) => {
          const { loggedUser } = res.data;
          login({ ...loggedUser, token });
          setIsLoggedIn(true);
          history.push("/profile");
        });
    };

    sendRequest();
  }, [history, token]);

  return <div></div>;
};
