import React from "react";

import Banner from "../../shared/Components/Banner/Banner";

// atoms
import { Container } from "../../components/atoms/index";

export const Terms = () => (
  <div id="terms-of-use">
    <Banner
      backgroundImage="https://worqman.fra1.digitaloceanspaces.com/images/privacy-policy.png"
      title="Terms of Use "
      height="400px"
    />
    <Container>
      <h4>
        <strong> Terms and conditions</strong>
      </h4>
      <br />
      <br />
      <h5>Article 1. Introduction</h5>
      <br />
      These general conditions (the "Conditions") apply to all the services
      offered via the website www.crewboost.co.uk (the "Site") or the Worqman
      application (the "App"), and in particular to the operation of an online
      platform which allows registered employers (the "companies") looking for a
      profile for a specific service to get in touch with registered workers
      (the "crew") who are ready to make themselves available of the Employer
      (the "Service").An Employer or a Worker is hereinafter called "User",
      "you", "your".These Conditions apply to the exclusion of all other
      conditions (unless expressly agreed otherwise in writing accepted and
      approved by Crew Boost Ltd). The Conditions must be accepted by any User
      wishing to access the Site or the App. They constitute the contract
      between the Site or the App and the User. Access to the Site or the App by
      the User signifies his acceptance of these Conditions. This contract is
      valid in English only, and only English controls all aspects. All other
      languages ​​are provided to facilitate your task and do not constitute a
      contract with Crew Boost Ltd. In the event of non-acceptance of the
      Conditions, the User must renounce access to the services offered by the
      Site or the App. Please read these Terms carefully. By creating a User
      account or by using the services offered by Crew Boost Ltd, You are deemed
      to have understood and accepted the Conditions. If You do not agree to
      these Terms or our Privacy Policy, you should not use the services We
      offer.
      <br />
      <br />
      <br />
      <h5>Article 2. Modification of the general conditions</h5>
      <br />
      Crew Boost Ltd reserves the right to modify these Conditions at any
      time. In the event of modification of these Conditions, the Conditions as
      modified will be published on the Crew Boost Ltd website. Crew Boost Ltd
      will inform its Users in good time of changes to the Conditions by e-mail
      or in the App before the date on which they take effect. By continuing to
      use the Service after the effective date of the modified Conditions, you
      agree to be bound by the Conditions as amended. If you do not agree with
      the amended Conditions, you can terminate your Subscription. Article 3.
      Nature of the service The Service set up by Crew Boost Ltd only consists
      of the execution of an online platform which allows Registered Employers,
      who are looking for a trusted person, to contact Registered Workers who
      are ready to respond to a work request (a "Request") to perform a service,
      the amount of which will have been determined by the Employer in his
      request (the "Service"). Crew Boost Ltd cannot be considered as employer,
      principal or other responsible for the Services that the Workers provide
      directly to the Employers. You expressly agree that the activity of Crew
      Boost Ltd does not consist in providing services. As operator of the
      online platform, Crew Boost Ltd is only an intermediary, and therefore
      will not interfere in any way in the developing relationship between an
      Employer and a Worker. Each Worker is free to decide whether or not to
      respond positively or not to an invitation to enter into contact with an
      Employer and each Employer is solely responsible for the Worker he
      chooses. We are in no way responsible for the behaviour of Employers or
      Workers and the damage they may cause to another in the context of the
      Service requested. It is up to the Users themselves to resolve such
      problems.
      <br />
      <br />
      <br />
      <h5>Article 4. Legal notices</h5>
      <br />
      The website www.crewboost.co.uk and the App are provided by the company
      Crew Boost Ltd whose head office is located at; 31 Middleton Road,
      Daventry, Northamptonshire, NN11 8BH Company number: 12393746 The Director
      of publication and the person responsible for processing personal data is
      Mr. Adam Sutton. Mr Robert Fitzgerald The host of the Site
      www.crewboost.co.uk is the Company Crew Boost Ltd. whose head office is
      located at 31 Middleton Road, Daventry, Northamptonshire, NN11 8BH
      <br />
      <br />
      <br />
      <h5> Article 5. Registration and identification of a User</h5>
      <br />
      In order to use www.crewboost.co.uk, Workers must have a computer/phone on
      which the site can be opened and have an Internet connection. The User can
      register (for a fee) by completing the online registration form. You agree
      to provide accurate and truthful information about your identity (e.g.
      age, gender, description), Your skills (e.g. degrees,
      experiences). Supporting documents regarding your approval may be
      requested later by the Crew Boost Ltd team.). It is prohibited to borrow
      an alias or the identity of another person to create several profiles or
      to use the profile of another User. We reserve the right to remove or
      refuse, temporarily or permanently, the User's access to the site/app if
      the profile contains inaccurate or false information, or which we can
      reasonably suspect that. In case of doubt, We may also ask You to prove
      Your identity on the basis of written evidence and We may carry out any
      verification which might be useful in this context. If you do not respond
      to such a request, We reserve the right to delete Your profile or refuse
      You access to the Site/App or the Service, without any warning and no
      right to compensation. The User can, at any time, modify his profile ("My
      Profile"). The User must keep his profile up to date. Workers can also,
      temporarily or permanently, define their status as "unavailable". In this
      case, they will not receive requests from the Employers. A Worker must be
      at least 16 years old to use the Service.
      <br />
      <br />
      <br />
      <h5> Article 6. Access to services</h5>
      <br />
      The Site allows Users access to a platform that is aimed at professionals
      in the world of events, entertainment and audiovisual. The objective is to
      bring together the real needs of the market. It facilitates implementation
      between Workers. By registering on the Platform, Workers highlight their
      skills and their availability. By registering on the Site, Employers can
      access a list of profiles corresponding to their needs for Services. The
      Site is accessible anywhere to any User with Internet access. All costs
      incurred by the User to access the service (computer hardware, software,
      Internet connection, etc.) are his responsibility. The use of
      www.crewboost.co.uk is chargeable for Users via a subscription service
      (either monthly or annually) Crew Boost Ltd will inform its Users in good
      time of changes to the Conditions by e-mail or in the App before the date
      on which they take effect. The use of www.crewboost.co.uk is free for
      Employers (“companies”) however Crew Boost Ltd reserve the right to change
      this at any time. Crew Boost Ltd will inform its Users in good time of
      changes to the Conditions by e-mail or in the App before the date on which
      they take effect. The non-member User does not have access to this
      services Access to Crew Boost Ltd services may be interrupted, suspended
      or changed without notice at any time for maintenance or any other
      case. The User undertakes not to claim any compensation following an
      interruption of a suspension or a modification of this contract. Users
      will be notified of any pre planned maintenance of the site via email or
      notification. Any event due to third party resulting in a malfunction of
      the network or the server does not engage the responsibility of Crew Boost
      Ltd. The User can contact Crew Boost Ltd by email at 
      <a href="/">info@crewboost.co.uk</a> .
      <br />
      <br />
      <br />
      <h5> Article 7. Obligations of Users</h5>
      <br />
      <br />
      <p>
        <strong>7.1 Obligation of the Employer to Pay the Worker</strong>
      </p>
      As an Employer, You agree to pay the Worker at the end of the Service, in
      accordance with the mutual agreement that you had previously
      concluded. See below for more information on payment terms.
      <br />
      <br />
      <p>
        <strong>
          7.2 Prohibition to use the data for other than personal use
        </strong>
      </p>
      You can use the Site/App or the Service only for your personal use as an
      Employer or Worker. You are not authorized to transmit the information you
      obtain via the Site/App or the Service to third parties for commercial
      purposes or for purposes other than the normal use of the Site/App.
      <br />
      <br />
      <p>
        <strong>7.3 Prohibited acts and behaviours</strong>
      </p>
      Trust is essential in the context of the Services entrusted by the
      Employer. Each User agrees to behave in an appropriate and respectful
      manner towards other Users. It is strictly forbidden for any User to adopt
      any inappropriate, racist, discriminatory behavior or to take any act
      which is contrary to good morals or to use a profile photo which is
      incompatible with the above. A User may not post inappropriate, racist,
      discriminatory, hurtful or contrary to good character declarations in the
      description of his profile or, as far as this is applicable, through the
      chat or rating functions offered by the Site/App. The App or the Site
      cannot be used for fraudulent purposes, to commit illegal acts, to
      exchange illegal material or content and, in general, it is prohibited to
      use the App in a way that is unrelated to the Service. In connection with
      the use of the App or the Service, You prohibit yourself and avoid
      encouraging other people to (i) request, accept or perform a Service found
      via Crew Boost Ltd outside the App; (ii) dilute, tarnish or otherwise harm
      the Crew Boost Ltd brand in any way, including in the event of denigration
      on social networks; (iii) to use the site/app for fraudulent purposes to
      commit illegal acts to exchange illegal material or content, and generally
      to use the App for a purpose other than the use of the service that is
      offered. In general, in case of concern or dissatisfaction, you agree to
      contact the Crew Boost Ltd teams via <a href="/">info@crewboost.co.uk</a>{" "}
      before expressing your dissatisfaction on social networks. Crew Boost Ltd
      reserves the right to refuse access, temporarily or permanently, to the
      Site/App to any User who does not comply with this article, or to delete
      any declaration or profile photo contrary to this article, without any
      warning or right to compensation.
      <br />
      <br />
      <p>
        <strong>7.4 Compliance with legal obligations</strong>
      </p>
      It is up to each User alone to comply with all the laws, rules,
      regulations and obligations applicable to the use of the App and the Site.
      Crew Boost Ltd clarifies to Users that the use of the Service may create
      legal obligations for the User, for example, without this list being
      exhaustive, in the fields of social security, taxation or insurance. Each
      Employer and Worker is required to inform himself about the obligations
      incumbent upon him or for which he is legally bound or liable to be held
      liable. If necessary, each Employer or Worker must take the necessary
      measures himself to ensure that he / she is in compliance with all
      applicable legal obligations. Under no circumstances can Crew Boost Ltd be
      held liable for damages, fines or penalties resulting from the fact that a
      User has failed to comply with these obligations.
      <br />
      <br />
      <h5> Article 8. Intellectual property. </h5>
      The brands, logos, signs and any other content on the Site are protected
      by the Intellectual Property Code and more particularly by copyright. The
      User requests the prior authorization of the Site for any reproduction,
      publication, copy of the various content. The User undertakes not to use
      the contents for commercial purposes. Any content posted by the User is
      his sole responsibility. The User undertakes not to put online content
      that could harm the interests of third parties. Any legal action brought
      by an injured third party against the Site will be borne by the User. User
      content may be deleted or modified at any time by the Site if it does not
      comply with the above conditions and the User does not receive any
      justification and notification prior to the deletion or modification of
      User content.
      <br />
      <br />
      <h5> Article 9. Personal data </h5>
      The information requested for registration on the Site is necessary and
      mandatory for the creation of the User's account. In particular, the email
      address may be used by the site for the administration, management and
      management of the service. The Worker user content is used for the purpose
      of connecting with potential Employer users and to facilitate declarations
      and administrative procedures for employment. The Site collects and stores
      User content for the duration of the users subscription, Crew Boost Ltd
      may store user content for marketing purposes. Users reserve the right to
      contact Crew Boost Ltd at <a href="/">info@crewboost.co.uk</a> to have
      their content deleted at any time. The Site allows Employer members to
      download a team table containing all the essential data of Workers who
      have accepted a Service. The User can find more information regarding the
      collection, processing and storage of personal data in our Privacy
      Statement .
      <br />
      <br />
      <h5> Lability</h5>
      The sources of the information disseminated on the Site are deemed
      reliable. However, the Site reserves the right to not guarantee the
      reliability of the sources. The information given on the site is for
      informational purposes only. Thus, the User assumes sole responsibility
      for the use of the information and content on this site. The User makes
      sure to keep his password secret. Any disclosure of the password, whatever
      its form, is prohibited. The User assumes the risks linked to the use of
      his username and password. Crew Boost Ltd declines all responsibility. Any
      use of the service by the User directly or indirectly resulting in damage
      must be subject to compensation for the benefit of Crew Boost Ltd. The
      site cannot be held liable in the event of unforeseeable circumstances and
      insurmountable fact of a third party.
      <br />
      <br />
      <h5>Article 11. Payments</h5>
      <br />
      <p>
        <strong>11.1 Payments to Crew Boost Ltd.</strong>
      </p>
      <p>
        <strong>11.1.1 Workers</strong>
      </p>
      The worker “crew” may subscribe to (i) a monthly Subscription or (ii) an
      annual Subscription (the "Subscription Period"). The applicable duration
      and the applicable prices of the Subscription will be indicated on the
      Site/App when subscribing. These prices are invoiced in Pound sterling and
      include all taxes. To collect this payment, Crew Boost Ltd uses the
      payment services of Stripe Payments Europe. By using the Stripe Service
      and payment system, You agree, as an Employer, that the Stripe General
      Conditions of Use will apply between You and Stripe Payments
      Europe. Stripe's General Conditions of Use are attached to and form an
      integral part of these Conditions. Taking into account the Stripe payment
      system, single payments of less than 1 Euro cannot be made.
      <br />
      <br />
      <p>
        <strong>11.1.2 Payment for the Subscription</strong>
      </p>
      When you subscribe to a Subscription, the worker is authorized to benefit
      from an unlimited number of services as described in the product
      description.
      <br />
      <br />
      <p>
        <strong>11.1.3. Termination of the Subscription:</strong>
      </p>
      The Subscription is automatically renewed at the end of each Subscription
      Period for the same duration as those applicable to the current
      Subscription Period (each being a "Subscription Renewal"), unless you
      cancel your subscription before the expiration date of the current
      subscription. All information relating to your Subscription is available
      in the "my profile" section. You can terminate your Subscription at any
      time without charge during the current Subscription Period. You can cancel
      a Renewal at any time, free of charge and regardless of a notice period.
      Crew Boost Ltd reserves the right to modify the pricing of the
      Subscription in the future. Such a modification will be communicated to
      the User in good time before the entry into force of such new prices and
      this new pricing will apply when the Subscription Period is first renewed.
      You can cancel your Subscription simply in the "management of my
      subscription" section in Your profile. Please note that the Subscription
      fees are non-refundable and that the termination of your Subscription
      gives no right to reimbursement of the prepaid Subscription fees. 11.1.4
      Payments by Employers to Workers
      <br />
      <br />
      <p>
        <strong>11.1.3. Termination of the Subscription:</strong>
      </p>
      The Workers will be paid directly by the Employers for their Services on
      the basis of the agreements which they themselves have put in place on
      this subject. We reiterate that Crew Boost Ltd is not a party to the
      agreements between Employers and Workers. Therefore, We do not intervene
      in the payments between them and we cannot be held responsible in any way
      whatsoever if an Employer does not make or only partially makes the
      payment.
      <br />
      <br />
      <h5>Article 12. Right of withdrawal</h5>
      If you wish to cancel your purchase of a Subscription, you have the right
      to exercise your right of withdrawal within thirty (30) days without
      providing specific reasons for said withdrawal, provided that You have not
      yet made a request before this date under your Subscription during this
      period. The withdrawal period expires after a period of thirty (30) days
      from the day on which You purchased the Subscription
      <br />
      <br />
      <h5>Article 13. User reviews</h5>
      Trust is essential in the context of the Services mentioned. This is why
      Crew Boost Ltd has set up a rating and notice system by which Users can
      each assign an opinion or recommendation at the end of a Service and
      attach a comment to it. This system of ratings and reviews is not subject
      to any capital link. Users undertake to make this assessment in an
      objective and conscientious manner. By creating a profile, Users accept
      that they can be evaluated. If a User finds that a specific evaluation is
      incorrect, he can report it to:  
      <a href="/">info@crewboost.co.uk</a>
      <br />
      <br />
      <h5>Article 14. Exclusion of liability</h5>
      Crew Boost Ltd is not responsible for slight faults. In no event shall
      Crew Boost Ltd be liable for any damage resulting from the unavailability
      of the Site/App or loss of data or information provided on the
      Site/App. In any case, Crew Boost Ltd cannot be held responsible for any
      direct or indirect damage such as loss of opportunity or unnecessarily
      incurred costs.
      <br />
      <br />
      <h5>Article 15. Availability of the App and the Site</h5>
      We make every reasonable effort to keep our services accessible to a
      maximum of Users 7 days a week and 24 hours a day. In the event of an
      interruption, We will provide the resources we find most appropriate in
      order to resume normal services as quickly as possible. We reserve the
      right, at any time and without notice, to suspend access to the App or the
      site for technical or other reasons and accept no responsibility for the
      possible consequences of these interruptions for you or third parties.
      <br />
      <br />
      <h5>Article 16. Intellectual property</h5>
      The intellectual property rights of the Site/App and the copyright in
      relation to all the documents stored, displayed and accessible on the
      Site/App are either the property of Crew Boost Ltd or their use is duly
      authorized. All these rights are reserved. The User acknowledges, accepts
      and guarantees that Crew Boost Ltd and its service providers are entitled
      to a non-exclusive, universal, unlimited, irrevocable, free license (with
      sub-license rights) to use any text, photo or information that the User
      would place on the Site/App, as part of the operation of the Service. The
      User remains the holder of all of their intellectual property rights. But
      by publishing a publication on the site, it gives the publishing company
      the non-exclusive and free right to represent, reproduce, adapt, modify,
      distribute and distribute its publication, directly or by an authorized
      third party, worldwide, on any medium (digital or physical), for the
      duration of the intellectual property. The User gives in particular the
      right to use his publication on the internet.
      <br />
      <br />
      <h5>Article 17. Invalidity and / or inapplicability</h5>
      If these Conditions or a part of them should be considered illegal,
      invalid or unenforceable, then to the extent that they are illegal,
      invalid or unenforceable, they must be treated as separable from these
      Conditions and will be deleted. The remaining Conditions will remain
      unchanged, fully in force, retain all their effects and continue to be
      binding and enforceable.
      <br />
      <br />
      <h5>Article 18. Unforeseen circumstances</h5>
      Crew Boost Ltd will not be responsible for any violations of these Terms
      caused by circumstances beyond its reasonable control.
      <br />
      <br />
      <h5>Article 19. Deletion of your profile</h5>
      Crew Boost Ltd reserves the right to delete Your profile, if You have not
      used the App for a consecutive period of six months and you have not
      subscribed to a Crew Boost Ltd Subscription. If You wish to delete your
      profile, please see our Privacy Policy. Subject to the application of Our
      Privacy Policy, We will delete Your profile within the legally required
      time.
      <br />
      <br />
      <h5>Article 20. Applicable law and competent jurisdiction</h5>
      These Conditions are subject to UK law. In the event of a dispute between
      Crew Boost Ltd and a User, the two parties agree to try to settle the
      dispute amicably at first. If no friendly settlement can be reached in the
      month following the birth of the dispute, the User may, if necessary,
      submit the choice, either to the courts of the place where the User has
      his residence or to courts of the UK.Crew Boost Ltd will always bring
      litigation before the courts of the place where the User has his
      residence. In the case of a dispute between two Users, we remind you that
      Crew Boost Ltd is not a party to such a dispute, and that it cannot
      therefore in any case intervene in this dispute.
      <br />
      <br />
      <h5>Article 21. Reports and complaints</h5>
      If you have any questions or want to report a problem, you can contact us
      either by letter: Crew Boost Ltd, 31 Middleton Road, Daventry,
      Northamptonshire, NN11 8BH or by e-mail:
      <a href="/"> {"  "}info@crewboost.co.uk</a>
    </Container>
  </div>
);
