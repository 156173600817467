import React from "react";

export const EasyToFindIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="63.572"
    height="63.572"
    viewBox="0 0 63.572 63.572"
  >
    <g id="search_2_1_" data-name="search (2) (1)" transform="translate(0 0)">
      <path
        id="Path_1457"
        data-name="Path 1457"
        d="M52.648,32.578a20.07,20.07,0,1,0,20.07,20.07A20.07,20.07,0,0,0,52.648,32.578Zm0,38.027A17.957,17.957,0,1,1,70.6,52.648,17.957,17.957,0,0,1,52.648,70.6Zm0,0"
        transform="translate(-28.276 -28.277)"
        fill="#78849e"
      />
      <path
        id="Path_1458"
        data-name="Path 1458"
        d="M62.025,54.555,50.073,42.6a1.087,1.087,0,0,0-1.494,0l-.747.747-4.2-4.2a24.331,24.331,0,1,0-4.482,4.482l4.2,4.2-.747.747a1.056,1.056,0,0,0,0,1.494L54.555,62.025a5.282,5.282,0,0,0,7.47-7.47ZM2.189,24.37A22.183,22.183,0,1,1,24.371,46.552,22.183,22.183,0,0,1,2.189,24.37ZM42.272,40.775l4.07,4.066-1.5,1.5-4.066-4.066q.78-.718,1.494-1.5Zm18.26,19.753a3.169,3.169,0,0,1-4.482,0l-11.2-11.2,4.482-4.482,11.2,11.2a3.175,3.175,0,0,1,0,4.482Zm0,0"
        transform="translate(0 0)"
        fill="#78849e"
      />
      <path
        id="Path_1459"
        data-name="Path 1459"
        d="M121.875,96.641l-3.565-1.019v-1.34a5.258,5.258,0,0,0,2.113-4.2V89.032a1.056,1.056,0,0,0,1.056-1.056V86.919a6.338,6.338,0,0,0-12.676,0v1.056a1.056,1.056,0,0,0,1.056,1.056v1.056a5.255,5.255,0,0,0,2.113,4.2v1.341l-3.564,1.019a5.3,5.3,0,0,0-3.83,5.083v5.26a1.056,1.056,0,0,0,1.056,1.056h19.013a1.056,1.056,0,0,0,1.056-1.056v-5.26a5.3,5.3,0,0,0-3.83-5.087ZM115.142,82.69a4.225,4.225,0,0,1,4.225,4.2,1.056,1.056,0,0,1-.7-.556l-.47-.942a1.056,1.056,0,0,0-1.531-.408L115.1,86.027a5.27,5.27,0,0,1-2.929.888h-1.251a4.225,4.225,0,0,1,4.225-4.225Zm-2.975,6.342a7.369,7.369,0,0,0,4.1-1.242l.578-.385a3.126,3.126,0,0,0,1.464,1.32v1.364a3.169,3.169,0,1,1-6.338,0V89.032Zm2.975,6.338a5.306,5.306,0,0,0,1.056-.107v1.162a1.045,1.045,0,0,0,.364.781l-1.42,1.067-1.421-1.067a1.047,1.047,0,0,0,.365-.781V95.26a5.3,5.3,0,0,0,1.056.107Zm8.451,10.565h-2.113v-4.225h-2.113v4.225h-8.451v-4.225H108.8v4.225H106.69v-4.2a3.184,3.184,0,0,1,2.3-3.052l2.3-.655,3.222,2.418a1.056,1.056,0,0,0,1.267,0l3.221-2.418,2.3.656a3.183,3.183,0,0,1,2.3,3.051Zm0,0"
        transform="translate(-90.77 -69.939)"
        fill="#78849e"
      />
    </g>
  </svg>
);
