import React, { useEffect } from "react";

export const Loading = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="loader-bg">
      <div className="loader">Loading...</div>
    </div>
  );
};
