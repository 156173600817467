import { Modal } from 'react-bootstrap';
import { Button } from '../Button/Button';

export const DeleteJob = (props) => (
  <Modal
    size="md"
    centered
    show={props.show}
    onHide={props.handleClose}
    aria-labelledby="jobs"
    contentClassName="delete-modal"
  >
    <Modal.Title id="jobs">Deleting Job</Modal.Title>
    <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
      <p>Are you sure you want to delete this job?</p>
      <Button className="my-2" fullWidth onClick={props.handleSubmit}>
        Delete
      </Button>
      <Button fullWidth color="secondary" onClick={props.handleClose}>
        Close
      </Button>
    </Modal.Body>
  </Modal>
);
