// set func
export const setStorageData = (id, data) => {
  return localStorage.setItem(id, JSON.stringify(data));
};

// get func
export const getStorageData = (id) => {
  return JSON.parse(localStorage.getItem(id));
};

// remove func
export const removeStorageData = (id) => {
  return localStorage.removeItem(id);
};
