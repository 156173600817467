import clsx from 'clsx';
import { InfoCard } from './InfoCard';

export const InfoCards = (props) => (
  <div className="container info-cards">
    {props.cards.map((card) => (
      <InfoCard
        key={card.id}
        image={card.image}
        title={card.title}
        description={card.description}
        author={card.author}
        className={clsx(
          props.variant === 'column' && 'info-card-column',
          props.variant === 'flex' && 'info-card-flex',
          props.variant === 'borderless' && 'info-card-borderless',
          props.variant === 'featuredImage' && 'info-card-featuredImage'
        )}
        variant={props.variant}
      />
    ))}
  </div>
);
