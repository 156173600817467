// router
import { NavLink, useHistory } from 'react-router-dom';

// uuid
import { v4 as uuidv4 } from 'uuid';

// query string
import queryString from 'query-string';

// bootstrap
import { Nav } from 'react-bootstrap';

// job tabs
const jobTabs = [
  { title: 'Active', param: 'active' },
  {
    title: 'Upcoming',
    param: 'upcoming',
  },
  { title: 'Past', param: 'past' },
];

export const JobsTabs = () => {
  // hooks
  const {
    location: { search },
  } = useHistory();

  // query string
  const { status } = queryString.parse(search);

  return (
    <Nav justify="start" variant="tabs">
      {jobTabs.map(({ title, param }) => (
        <NavLink
          key={uuidv4()}
          to={{ pathname: '/jobs', search: `?status=${param}` }}
          className="nav__link"
          activeClassName={status === param ? 'nav__link-active' : ''}
        >
          {title}
        </NavLink>
      ))}
    </Nav>
  );
};
