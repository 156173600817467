import React from "react";
import { Footer } from "../../components/organisms/Footer/Footer";
import Animate from "react-smooth";
import { CheckIcon } from "../../components/atoms";
import Subscription from "../../components/Pricing/Subscription";

export const Pricing = () => {
  return (
    <div>
      <Animate to="3" from="0" attributeName="opacity">
        <div className="pricing-banner">
          <div className="container">
            <div className="banner-description">
              <h1>Pricing packs</h1>
              <div className="list-items">
                <span>
                  <CheckIcon />2 minute setup
                </span>
                <span>
                  <CheckIcon />
                  Cancel anytime
                </span>
                <span>
                  <CheckIcon />
                  24/7 Support
                </span>
              </div>
            </div>
            <div className="banner-image">
              <img src="../images/piggy-bank.png" alt="pricing" />
            </div>
          </div>
        </div>
        <div className="package-plan">
          <div className="container">
            <Subscription />
          </div>
        </div>
        <div className="hire-crew">
          <div className="container">
            <div
              className="hire-crew-content"
              style={{
                backgroundImage:
                  "url(https://worqman.fra1.digitaloceanspaces.com/images/banner.png)",
              }}
            >
              <h2>Hire qualified crew in minutes</h2>
              <p>
                Worqman connects your business to a community of qualified
                professionals.
              </p>
            </div>
          </div>
        </div>
        <div className="pricing-testimonials">
          <div className="container">
            <h2>What businesses are saying</h2>
            <p>
              Lorem ipsum dolor sit amet, coectetur adipiscing elit. Suspendisse
              varius enim in eros .
            </p>
            <div className="testimonial-wrapper">
              <img src="../images/testimonial-img.jpg" alt="testimonial" />
              <div className="what-they-say">
                <h4>
                  “My relationship with businesses keeps on growing. The
                  projects get larger and more technical every year.”
                </h4>
                <span>Jenny Rich</span>
              </div>
            </div>
          </div>
        </div>
        <div className="gdpr-wrapper">
          <div className="container">
            <img src="../images/gdpr-icon.png" alt="testimonial" />
            <div className="gdpr-content">
              <h2>GDPR Compliant</h2>
              <p>
                Keeping member privacy at the center of what we do, for
                companies and candidates alike.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </Animate>
    </div>
  );
};
