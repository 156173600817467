import clsx from "clsx";

export const PageSectionHeading = (props) => (
  <div className={clsx("page-section-heading", props.className)}>
    <div className="container">
      <h2 className="main-title">{props.title}</h2>
      <p className="main-text">{props.text}</p>
    </div>
  </div>
);
