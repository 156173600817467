// react
import React from "react";

// molecules
import { HeaderBar } from "../../molecules";

export const ProfileLayout = (props) => (
  <>
    <HeaderBar title="Profile" backButton={props.backButton} />
    <p className="profile_title p mb-3 mt-4 text-uppercase">
      {props.isAuthentication
        ? "YOUR PROFILE"
        : `${props.userRole === 1 ? "COMPANY" : "CREW"} PROFILE`}
    </p>
    <div className="profile_layout bg-white mx-auto">
      <div className="row">{props.children}</div>
    </div>
  </>
);
