import React, { useState, useRef, useEffect } from "react";
import { axiosAPI } from "../../utils/index";
import jwt from "jwt-decode";

import { Modal } from "react-bootstrap";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

export const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [token, setToken] = useState();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  const history = useHistory();
  const d_token = useParams().token;

  useEffect(() => {
    try {
      let decoded = jwt(d_token);
      let exp = new Date(decoded.exp).getTime();
      let now = new Date() / 1000;
      if (exp < now) {
        setError("This link has experied. Please try again");
      } else {
        if (decoded.userId && decoded.email) {
          setToken(decoded);
        } else {
          setError("Something went wrong. Please try again.");
        }
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
    }
  }, [d_token]);

  const onSubmit = async (e) => {
    setLoading(true);
    setError(false);
    setSuccess(false);
    let response;
    let data = {
      email: token.email,
      password: e.password,
      status: token.status,
    };
    var role = token.status !== 1 ? 'users' : 'companies';

    try {
      response = await axiosAPI.put(`/${role}/email-password`, data, {
        headers: { Authorization: "Bearer " + d_token },
      });
      setSuccess(response.data.message);
    } catch (err) {
      setLoading(false);
      return setError(err.response.data.message);
    }

    setLoading(false);
  };

  return (
    <div id="login">
      <Modal
        show={true}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => !loading && history.push("/")}
        centered
      >
        <section>
          {loading && (
            <div className="bar">
              <div></div>
            </div>
          )}
        </section>

        <div className="modal--content">
          <section className="modal-remove-wrapper">
            <span
              className="remove-modal-button"
              onClick={() => !loading && history.push("/")}
            >
              &#x2715;
            </span>
          </section>

          {!error && (
            <section>
              <div className="modal--head text-center">
                <h3 className="modal--title">Enter your new password</h3>
              </div>
            </section>
          )}
          <section>
            {success && (
              <div className="response--success">
                <div>
                  <p>{success}</p>
                </div>
              </div>
            )}
          </section>

          <section>
            {error && (
              <div className="response--error">
                <div>
                  <p>{error}</p>
                </div>
              </div>
            )}
          </section>

          {!error && !success && (
            <section>
              <form className="form--data" onSubmit={handleSubmit(onSubmit)}>
                <div className="form--group">
                  <input
                    type="password"
                    className="form--control"
                    name="password"
                    required
                    placeholder="Password"
                    {...register("password", { required: true, minLength: 8 })}
                  />
                  {errors.password?.type === "required" && (
                    <div className="error-text">Password is required</div>
                  )}
                  {errors.password?.type === "minLength" && (
                    <div className="error-text">Min length is 8 characters</div>
                  )}
                </div>
                <div className="form--group">
                  <input
                    type="password"
                    className="form--control"
                    name="confirm"
                    required
                    placeholder="Confirm Password"
                    {...register("confirm", {
                      validate: (value) => value === password.current,
                    })}
                  />
                  {errors.confirm?.type === "validate" && (
                    <div className="error-text">Password does not match</div>
                  )}
                </div>
                <div className="submit--group">
                  <div className="btn--wrapper w-100 text-center">
                    <button
                      className={`btn-c btn--dark btn--center ${
                        loading && "disabled"
                      }`}
                      disabled={loading && true}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </section>
          )}
          {success && (
            <div className="submit--group mt-3">
              <div className="btn--wrapper w-100 text-center">
                <NavLink
                  to="/login"
                  className={`btn-c btn--dark btn--center ${
                    loading && "disabled"
                  }`}
                  disabled={loading && true}
                >
                  Login
                </NavLink>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
