import React from "react";

const TitleUnderline = (props) => {
  return (
    <div className="row">
      <div
        className={`content-box col-12 ${
          props.center && "content-box__center"
        }`}
      >
        <h1
          className="content-box__title"
          style={{ maxWidth: props.maxWidth || "800px" }}
        >
          {props.title}
        </h1>
        <p
          className="content-box__description desc-gray"
          style={{ maxWidth: props.pMaxWidth || "800px" }}
        >
          {props.description}
        </p>
        {props.children && (
          <div className="content-box__children">{props.children}</div>
        )}
      </div>
    </div>
  );
};

export default TitleUnderline;
