import clsx from 'clsx';
import { Wrapper } from '../../atoms';

export const InfoCard = (props) => (
  <div className={clsx('info-card', props.className)}>
    <img src={props.image} alt="info card svg" className="info-card-image" />
    <Wrapper
      condition={props.variant === 'flex' || props.variant === 'featuredImage'}
    >
      <h3 className="info-card-title">{props.title}</h3>
      <p className="info-card-description">{props.description}</p>
      {props.author && <p className="info-card-author">{props.author}</p>}
    </Wrapper>
  </div>
);
