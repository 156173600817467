// react
import React, {useEffect, useCallback, useState} from "react";

// react icons
import { ImArrowLeft2 } from "react-icons/im";

// router
import { NavLink, useHistory } from "react-router-dom";

// icons
import { BellIcon, Button } from "../../atoms/index";

import { useAuthorization } from '../../../hooks/useAuthorization';
import { axiosAPI } from '../../../utils';

import { NotificationList } from "../Notification/NotificationList";
import { EmptyData } from "../EmptyData/EmptyData";
import { Loading, Ternary } from '../../../components/atoms';


export const HeaderBar = ({ title, backButton = false, customAction }) => {
  // local states
  const { goBack } = useHistory();

  // handlers
  const handleBackButton = () => goBack();

  const [data, setData] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [companyNotification, setCompanyNotification] = useState(0);
  const [crewNotification, setCrewnotification] = useState(0);

  const {
    auth: { token, status },
  } = useAuthorization();
   // hooks
   const getData = useCallback(async () => {
    try {
      if (token) {
        const { data } = await axiosAPI.get(
          'invitation/invitation-notification',
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              isCompany: status === 1,
            },
          }
        );
        if (data) {
          setData(data);
          let count_notifications_company = [];
          let count_notifications_crew = [];
             data.map((e) => {
               if(e.seen_by_company !== 1){
                 count_notifications_company.push(e.seen_by_company);
               }
              if(e.seen_by_company !== 2){
                count_notifications_crew.push(e.seen_by_company);
              }
             })
             setCompanyNotification(count_notifications_company.length)
             setCrewnotification(count_notifications_crew.length)
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [status, token]);

  useEffect(() => {
    getData();
   
  }, [getData,]);

  const toggleDropdown = () => {
    setToggle(!toggle);
  };


  return (
    <>
    <div className="headerbar">
      <div className="headerbar__title-wrapper">
        {backButton && (
          <button onClick={handleBackButton} className="headerbar__backButton">
            <ImArrowLeft2 />
          </button>
        )}
        {title && <h2 className="headerbar__title">{title}</h2>}
      </div>

      {!customAction ? (
        <>
        <Button
          
          className="headerbar__action"
          //activeClassName="headerbar__action-active"
          onClick={toggleDropdown}
        >
          <BellIcon />
          {status !== 2 ?
          companyNotification !== 0 ?<div className="notifications-alert"><p>{companyNotification}</p></div> : ""
          : 
          crewNotification !== 0 ?<div className="notifications-alert"><p>{crewNotification}</p></div> : ""
          }
        </Button>
        </>
      ) : (
        <Button
          onClick={customAction.clickHandler}
          className="headerbar__action"
        >
          {customAction.icon}
        </Button>
      )}
    </div>
    <div className="notifications">
      {
        console.log("dada",data)

      }
    {toggle?
       
       <>
          {/* <h3 className="notifications__title">your chats</h3> */}
          <div className="notifications__wrapper">
            {data != null ?
            data.length < 0 ?<NotificationList data={data} /> : <p className="mt-3">No Notifications</p>
            :<p className="mt-3">No Notifications</p> }
            {/* <hr />
            {id && <NotificationInfo id={id} />} */}
          </div>
          </>
      
      : ""}
       </div>
    </>
  );
};
