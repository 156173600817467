import React, { useState, useRef, useEffect } from "react";
import { axiosAPI } from "../../utils/index";
import { NavLink, useHistory } from "react-router-dom";

import { Formik, Form } from "formik";
import * as Yup from "yup";

const SignUpSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  confirm: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  email: Yup.string().email("Invalid email").required("Required"),
  name: Yup.string().required("Required"),
});

export const SignupCompany = () => {
  const [error, setError] = useState();

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async (data, formikApi) => {
    setError(null);
    axiosAPI
      .post("/companies/signup", data)
      .then((res) => {
        history.push({
          pathname: "/signup-message",
          state: { email: res.data.email },
        });
      })
      .catch((error) => {
        setError(error.response.data.error);
        formikApi.setSubmitting(false);
      });
  };

  return (
    <div id="signup-company">
      <Formik
        initialValues={{
          password: "",
          confirm: "",
          email: "",
          name: "",
        }}
        validationSchema={SignUpSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, setFieldValue, isValid, isSubmitting }) => (
          <>
            <section>
              {isSubmitting && (
                <div className="bar">
                  <div></div>
                </div>
              )}
            </section>

            <div className="modal--content">
              <section>
                <div className="modal--head text-center">
                  <h1 className="modal--title">Company Signup</h1>
                  <p className="modal--description">
                    If you already have an account please...
                  </p>
                  <NavLink
                    to="/login"
                    className={`btn-c btn--underline ${
                      isSubmitting && "disabled"
                    }`}
                  >
                    Click here
                  </NavLink>
                </div>
              </section>

              <section>
                {error && (
                  <div className="response--error">
                    <div>
                      <p>{error}</p>
                    </div>
                  </div>
                )}
              </section>

              <section>
                <Form className="form--data">
                  <div className="form--group">
                    <input
                      type="text"
                      className="form--control"
                      name="name"
                      placeholder="Company Name"
                      onChange={(e) => setFieldValue("name", e.target.value)}
                    />
                    {errors.name && touched.name && (
                      <div className="error-text">{errors.name}</div>
                    )}
                  </div>
                  <div className="form--group">
                    <input
                      type="text"
                      className="form--control"
                      name="email"
                      placeholder="Email"
                      onChange={(e) => setFieldValue("email", e.target.value)}
                    />
                    {errors.email && touched.email && (
                      <div className="error-text">{errors.email}</div>
                    )}
                  </div>
                  <div className="form--group">
                    <input
                      type="password"
                      className="form--control"
                      name="password"
                      required
                      placeholder="Password"
                      onChange={(e) =>
                        setFieldValue("password", e.target.value)
                      }
                    />
                    {errors.password && touched.password && (
                      <div className="error-text">{errors.password}</div>
                    )}
                  </div>
                  <div className="form--group">
                    <input
                      type="password"
                      className="form--control"
                      required
                      name="confirm"
                      placeholder="Confirm Password"
                      onChange={(e) => setFieldValue("confirm", e.target.value)}
                    />
                    {errors.confirm && touched.confirm && (
                      <div className="error-text">{errors.confirm}</div>
                    )}
                  </div>
                  <div className="submit--group">
                    <div className="btn--wrapper w-100 text-center">
                      <button
                        className={`btn-c btn--dark btn--center ${
                          isSubmitting && "disabled"
                        }`}
                      >
                        Signup
                      </button>
                    </div>
                  </div>
                </Form>
              </section>
            </div>
          </>
        )}
      </Formik>
      {/* </Modal> */}
    </div>
  );
};
