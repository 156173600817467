export const Data = [
  {
    title: "Basic",
    price: "10$",
    info: "Lorem Ipsum is simply",
  },
  {
    title: "Premium",
    price: "24$",
    info: "Lorem Ipsum is simply",
  },
  {
    title: "Silver",
    price: "10$",
    info: "Lorem Ipsum is simply",
  },
];
