import React, { useState, useEffect } from "react";
import { Modal, CloseButton } from "react-bootstrap";
import clsx from "clsx";
import { BsCalendarEvent } from "react-icons/bs";
import { Calendar, utils } from "react-modern-calendar-datepicker";

import { MdDeleteForever } from "react-icons/md";
import {
  WorkIcon,
  Button,
  TextareaField,
  InputField,
  JobAvailableDates,
  DeleteJob,
} from "../../atoms";
import { PROFESSIONS } from "../../../static";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { axiosAPI } from "../../../utils";
import { useForm, Controller } from "react-hook-form";
import { FiSettings } from "react-icons/fi";
import { FaLayerGroup } from "react-icons/fa";
import { MdOutlineLocationOn } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { RiArrowDropDownLine } from "react-icons/ri";
import BForm from "react-bootstrap/Form";
import dayjs from "dayjs";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState  } from 'draft-js';
import  draftToHtml  from 'draftjs-to-html'; 
import { stateFromHTML } from 'draft-js-import-html';


const CreateSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  //start: Yup.string().required("Required"),
  //end: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  //description: Yup.string().required("Required"),
  profession: Yup.array().min(1, "Select a profession").required("Required"),
});

export const JOBTYPEEDIT = [
  { value: "full-time", label: "Full Time" },
  { value: "part-time", label: "Part Time" },
  { value: "select-date", label: "Select Date" },
  
];

const customComponents = {
  Control: (rest) => (
    <components.Control {...rest}>
      <>
        <FiSettings className="selectField_icon" />
        {rest.children}
      </>
    </components.Control>
  ),
  IndicatorSeparator: () => null,
  IndicatorsContainer: () => (
    <components.DownChevron>
      <RiArrowDropDownLine />
    </components.DownChevron>
  ),
};

const customStyles = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
    border: "none",
    borderBottom: "1px solid #f4f4f6fd",
    borderRadius: "0",
    cursor: "pinter",
    padding: "4px",
    paddingRight: 0,
    "&:hover": {
      borderColor: "#f4f4f6fd",
    },
  }),
};

export const EditJob = ({ job, isEdit, show, title, handleClose, ...rest }) => {
  const [editorState, setEditorState] = useState(()=>{
    const contentState = stateFromHTML(job.description || '');
    return EditorState.createWithContent(contentState);
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [latLng, setLatLng] = useState(null);
  const [error, setError] = useState(null);
  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);
  const { auth } = useAuthorization();
  const { push } = useHistory();

  const handleStartDate = (value) => () => setOpenStart(value);
  const handleEndDate = (value) => () => setOpenEnd(value);
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  const handleEditorChange = (editorState, setFieldValue) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const html = draftToHtml(rawContentState);
    //setFieldValue("description", html)
  };

  const onSubmit = async (data, formikApi) => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const html = draftToHtml(rawContentState);
    
    var fromValue = selectedDayRange.from;
    var toValue = selectedDayRange.to;
    var dayFrom = String(fromValue.day).padStart(2, '0'); // add leading zero if day is a single digit
    var monthFrom = String(fromValue.month).padStart(2, '0'); // add leading zero if month is a single digit
    var yearFrom = String(fromValue.year);
    var formattedDateFrom = dayjs(`${yearFrom}-${monthFrom}-${dayFrom}`).toISOString(); 
    data.start = formattedDateFrom;

    var dayTo = String(toValue.day).padStart(2, '0'); // add leading zero if day is a single digit
    var monthTo = String(toValue.month).padStart(2, '0'); // add leading zero if month is a single digit
    var yearTo = String(toValue.year);
    var formattedDateTo = dayjs(`${yearTo}-${monthTo}-${dayTo}`).toISOString(); 
    data.end =  formattedDateTo;
    const formData = {
      ...data,
      description: html,
      skill: data.profession?.map((i) => i.value).toString() || "",
      location: data.address,
      latLng,
      job_type: data.job_type.value,
    };
    await axiosAPI
      .put("/job/update-job", formData, {
        params: { slug: job.slug },
        headers: { Authorization: "Bearer " + auth.token },
      })
      .then((res) => {
        push("/jobs?page=1&status=active");
      })
      .catch((err) => {
        setError(err.response.data.error);
        formikApi.setSubmitting(false);
      });
  };

  const deleteJobHandler = async () => {
    setError(null);
    axiosAPI
      .delete("/job/delete-job", {
        data: { job_id: job.id },
        headers: { Authorization: "Bearer " + auth.token },
      })
      .then(() => {
        push("/jobs?page=1&status=active");
      })
      .catch((err) => {
        setError(err.response.data.error);
      });
  };

  const handleDeleteModalOpen = () => {
    handleClose();
    setDeleteModal(true);
  };
  const handleDeleteModalClose = () => setDeleteModal(false);

  const handleAddress = (value, setFieldValue) => {
    if (typeof value === "object") {
      setFieldValue("address", value.formatted_address);
    } else {
      setFieldValue("address", value);
    }
  };

  const jobType = (values, setFieldValue) => {
    setFieldValue("job_type", values);

    if(values.value === "select-date"){
      document.getElementsByClassName("addJob_date")[0].style.display = "block"

    }else{
      document.getElementsByClassName("addJob_date")[0].style.display = "none"

    }
  }

  const handleSelectAddress = (value, setFieldValue) => {
    if (typeof value === "object") {
      setFieldValue("address", value.formatted_address);
    } else {
      setFieldValue("address", value);
    }
    
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => setLatLng(latLng))
      .catch((error) => console.error("Error", error));
  };

  const toolbarOptions = {
    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'embedded', 'emoji'],
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
    blockType: {
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
    //fontFamily: false,
    list: {
      options: ['unordered', 'ordered'],
    },
    textAlign: {
      options: ['left', 'center', 'right'],
    },
    colorPicker: {
      colors: ['rgb(0, 0, 0)', 'rgb(255, 255, 255)', 'rgb(255, 0, 0)', 'rgb(255, 165, 0)', 'rgb(255, 255, 0)', 'rgb(0, 128, 0)', 'rgb(0, 0, 255)', 'rgb(75, 0, 130)', 'rgb(238, 130, 238)'],
    },
    link: {
      options: ['link'],
    },
    embedded: {
      defaultSize: {
        height: 'auto',
        width: 'auto',
      },
    },
    emoji: false,
    image: false, 
  };

  return (
    <>
      <Modal
        size="lg"
        centered
        show={show}
        onHide={handleClose}
        aria-labelledby="add-job"
        className={clsx(
          "addJob",
          openStart || openEnd ? "addJob_hidden" : "addJob_visible"
        )}
        {...rest}
      >
        <section>
          {error && (
            <div className="response--error">
              <div>
                <p>{error}</p>
              </div>
            </div>
          )}
        </section>
        <Formik
          initialValues={{
            name: job?.name,
            description: job?.description,
            address: job?.location,
            start: job?.start ? job.start : null,
            end: job?.end ? new Date(job?.end) : null,
            profession: job?.skill
              ? job.skill.split(",").map((el) => {
                  const tmp = el
                    .replace(",", "")
                    .replace(`"`, "")
                    .replace(`"`, "");
                  return {
                    value: tmp,
                    label: tmp,
                  };
                })
              : [],
          }}
          validationSchema={CreateSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            setFieldValue,
            isValid,
            isSubmitting,
            handleSubmit,
            values,
          }) => (
            <>
              <Modal.Title id="add-job">{title}</Modal.Title>
              <Modal.Body className="d-flex flex-column justify-content-center align-items-center w-100">
                <div className="w-100 my-3">
                  <InputField
                    value={values.name}
                    onChange={(e) => setFieldValue("name", e.target.value)}
                    placeholder="Name of the job"
                    icon={<WorkIcon />}
                  />
                  {errors.name && touched.name && (
                    <div className="error-message">Name is required</div>
                  )}
                  <BForm.Group className="selectField mt-3">
                    <div className="d-flex flex-row justify-content-start align-items-center multiSelect_wrapper">
                      <Select
                        onChange={(values) => {
                          setFieldValue("profession", values);
                        }}
                        value={values.profession}
                        options={PROFESSIONS}
                        isMulti
                        className="selectField_input w-100"
                        components={customComponents}
                        styles={customStyles}
                        placeholder="Professions"
                      />
                    </div>
                  </BForm.Group>
                  {errors.profession && touched.profession && (
                    <div className="error-text m-2">
                      Professions is required
                    </div>
                  )}
                  <PlacesAutocomplete
                    value={values.address}
                    onChange={(value) => handleAddress(value, setFieldValue)}
                    onSelect={(value) => handleSelectAddress(value, setFieldValue)}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div
                        className="search--location"
                        style={{ position: "relative" }}
                      >
                        <div className="search__job__input_control">
                          <InputField
                            {...getInputProps({
                              placeholder: "Search",
                              className: "location-search-input",
                            })}
                            placeholder="Location"
                            icon={<MdOutlineLocationOn />}
                          />
                          {errors.location && (
                            <div className="error-message">
                              Location is required
                            </div>
                          )}
                        </div>
                        {loading && (
                          <div className="autocomplete-dropdown-container">
                            <div style={{ padding: "15px 32px" }}>
                              Loading...
                            </div>
                          </div>
                        )}

                        {suggestions && suggestions.length >= 1 && (
                          <div className="autocomplete-dropdown-container">
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                  style={{
                                    padding: "15px 32px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                  </PlacesAutocomplete>
                  {/* <TextareaField
                    placeholder="Description"
                    value={values.description}
                    onChange={(e) =>
                      setFieldValue("description", e.target.value)
                    }
                    icon={<FaLayerGroup />}
                  /> */}
                  {/* {errors.description && touched.description && (
                    <div className="error-message">Description is required</div>
                  )} */}

                  <div className="d-flex flex-row justify-content-start align-items-center mb-4 ">
                    <Select
                      onChange={(values) => {
                       jobType(values, setFieldValue)
                      }}
                      value={values.job_type}
                      options={JOBTYPEEDIT}
                      className="selectField_input w-100"
                      components={customComponents}
                      styles={customStyles}
                      placeholder="Job Type"
                    />
                  </div>

                   <div className="addJob_date my-3 mb-3">
                <h6 className="fs-6 text_secondary">JOB TIMELINE</h6>
                <div className="d-flex flex-row justify-content-start align-items-center w-100 my-2">
                  <label htmlFor="start-date">
                    <button
                      className="addJob_btn"
                      onClick={handleStartDate(true)}
                    >
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        <BsCalendarEvent className="mr-2" />
                        <p className="m-0 mr-1">Select date </p>
                        {selectedDayRange.from && selectedDayRange.to && (
                          <p className="m-0">
                            {" - "}
                            {/* {dayjs(values.start).format("DD/MM/YYYY")} */}
                        {`${selectedDayRange.from.day+ "/"+selectedDayRange.from.month + "/" + selectedDayRange.from.year} - ${selectedDayRange.to.day+ "/"+selectedDayRange.to.month + "/" + selectedDayRange.to.year}`}
                            

                          </p>
                        )}
                      </div>
                    </button>

                    <Calendar
                      className="crew-calendar"
                      value={selectedDayRange}
                      onChange={setSelectedDayRange}
                      minimumDate={utils().getToday()}
                      shouldHighlightWeekends
                    />
                    {/* <JobAvailableDates
                      id="start-date"
                      title="Available dates"
                      show={openStart}
                      onChange={(value) => setFieldValue("start", value)}
                      value={values.start}
                      handleClose={handleStartDate(false)}
                    /> */}
                  </label>
                  {/* <label htmlFor="end-date">
                    <button
                      className="addJob_btn"
                      onClick={handleEndDate(true)}
                    >
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        <BsCalendarEvent className="mr-2" />
                        <p className="m-0 mr-1">End date </p>
                        {values.end && (
                          <p className="m-0">
                            {" - "}
                            {dayjs(values.end).format("DD/MM/YYYY")}
                          </p>
                        )}
                      </div>
                    </button>

                    <JobAvailableDates
                      id="end-date"
                      title="Available dates"
                      show={openEnd}
                      onChange={(value) => setFieldValue("end", value)}
                      value={values.end}
                      handleClose={handleEndDate(false)}
                    />
                  </label> */}
                </div>
              </div>
                  <div style={{ whiteSpace: 'pre-wrap' }}>

                   <Editor
                    toolbar={toolbarOptions}
                    autoFocus
                    placeholder=" Job Description"
                    required
                     editorState={editorState}
                     onEditorStateChange={handleEditorChange}
                     editorClassName="jobEditor"
                     toolbarClassName="jobToolbar"
                  />
                  </div>

                </div>

                {/* <div className="addJob_date my-3">
                  <h6 className="fs-6 text_secondary">JOB TIMELINE</h6>
                  <div className="d-flex flex-row justify-content-start align-items-center w-100 my-2">
                    <label htmlFor="start-date">
                      <button
                        className="addJob_btn"
                        onClick={handleStartDate(true)}
                      >
                        <div className="d-flex flex-row justify-content-center align-items-center">
                          <BsCalendarEvent className="mr-2" />
                          <p className="m-0 mr-1">Start date </p>
                          {values.start && (
                            <p className="m-0">
                              {" - "}
                              {dayjs(new Date(values.start)).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          )}
                        </div>
                      </button>

                      <JobAvailableDates
                        id="start-date"
                        title="Available dates"
                        show={openStart}
                        onChange={(value) => setFieldValue("start", value)}
                        value={{
                          year: new Date(values.start).getFullYear(),
                          month: new Date(values.start).getMonth(),
                          day: new Date(values.start).getDay(),
                        }}
                        handleClose={handleStartDate(false)}
                      />
                    </label>
                    <label htmlFor="end-date">
                      <button
                        className="addJob_btn"
                        onClick={handleEndDate(true)}
                      >
                        <div className="d-flex flex-row justify-content-center align-items-center">
                          <BsCalendarEvent className="mr-2" />
                          <p className="m-0 mr-1">End date </p>
                          {values.end && (
                            <p className="m-0">
                              {" - "}
                              {dayjs(new Date(values.end)).format("DD/MM/YYYY")}
                            </p>
                          )}
                        </div>
                      </button>

                      <JobAvailableDates
                        id="end-date"
                        title="Available dates"
                        show={openEnd}
                        onChange={(value) => setFieldValue("end", value)}
                        value={{
                          year: new Date(values.end).getFullYear(),
                          month: new Date(values.end).getMonth(),
                          day: new Date(values.end).getDay(),
                        }}
                        handleClose={handleEndDate(false)}
                      />
                    </label>
                  </div>
                </div> */}

                <div className="addJob_actions">

                  <Button
                    onClick={handleDeleteModalOpen}
                    variant="outlined"
                    className="addJob_delete my-4 mx-2 py-2"
                    
                  >
                    <>
                      <MdDeleteForever className="mr-2" />
                      <span>Delete Job</span>
                    </>
                  </Button>

                  <Button
                    onClick={() => handleSubmit()}
                    type="submit"
                    className="my-2 py-2"
                  >
                    Save changes
                  </Button>
                </div>
              </Modal.Body>
              <CloseButton
                      onClick={handleClose}
                      variant="outlined"
                      className="my-2 py-2 modal-close-job"
                    >
                    
                  </CloseButton>
            </>
          )}
        </Formik>
      </Modal>

      <DeleteJob
        show={deleteModal}
        onClose={handleDeleteModalClose}
        handleClose={handleDeleteModalClose}
        handleSubmit={deleteJobHandler}
      />
    </>
  );
};
