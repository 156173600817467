import { useCallback, useEffect, useState, useContext } from "react";

// contexts
import { AuthContext } from "../../context/AuthContext";

// router
import { useParams } from "react-router-dom";

import { Loading } from "../../components/atoms";

// molecules
import { AvatarProfile } from "../../components/molecules";

// organisms
import { EditProfileDetails } from "../../components/organisms/index";

// templates
import { ProfileLayout } from "../../components/templates";

import { axiosAPI } from "../../utils";

export const EditProfile = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState([]);
  const [activeJobs, setActiveJobs] = useState(0);

  // context
  const { auth, isLoggedIn } = useContext(AuthContext);

  const getProfileData = useCallback(
    async (statusRole) => {
      try {
        const { data } = await axiosAPI.get(
          `${statusRole === 1 ? "companies/company" : "users/user"}`,
          { headers: { Authorization: "Bearer " + auth.token } }
        );

        setUser(statusRole === 1 ? data.company : data.user);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    },
    [auth.token]
  );

  const handleUploadProfileImage = async (event) => {
    try {
      if (event.target.files && event.target.files[0]) {
        let formData = new FormData();
        formData.append("file", event.target.files[0]);
        await axiosAPI.post(
          `${auth.status === 1 ? "/companies" : "/users"}/profile-picture`,
          formData,
          { headers: { Authorization: "Bearer " + auth.token } }
        );
      }
    } catch (err) {
      return console.error(err);
    }
  };

  const getCompanyActiveJobs = useCallback(async () => {
    try {
      if (auth.token) {
        const { data } = await axiosAPI.get("/job/company-current-jobs", {
          headers: { authorization: `Bearer ${auth.token}` },
        });
        setActiveJobs(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [auth.token]);

  useEffect(() => {
    if (auth.token) {
      getCompanyActiveJobs();
    }
    if (!!auth.status) {
      getProfileData(auth.status);
    }
  }, [auth, id, getProfileData, getCompanyActiveJobs]);

  if (loading) {
    return <Loading />;
  }

  return (
    <ProfileLayout isAuthentication={isLoggedIn} userRole={auth.status}>
      <>
        <div className="d-flex flex-column align-items-center justify-content-start col-xs-12 col-sm-6 col-md-5 col-lg-5 py-5 md-py-2 border-end">
          <div className="profile_avatar">
            <AvatarProfile
              editMode={true}
              name="file"
              accept="image/png,image/gif,image/jpeg"
              onChange={handleUploadProfileImage}
              src={
                user.image ||
                "https://worqman.fra1.digitaloceanspaces.com/images/profile-placeholder-3.png"
              }
            />
          </div>
          <h3 className="h3 text-center my-3 mt-5">{user.name}</h3>
          <h6 className="h6 m-0">{activeJobs.count} active jobs</h6>
          <p className="m-0">{user?.city}</p>
        </div>
        <EditProfileDetails
          user={user}
          token={auth.token}
          isAuthentication={isLoggedIn}
          userRole={auth.status}
        />
      </>
    </ProfileLayout>
  );
};
