// router
import { NavLink } from "react-router-dom";

// hooks
import { useWindowSize } from "../../../hooks/useWindowSize";

export const Sidebar = ({ routes }) => {
  const { width } = useWindowSize();

  return (
    <ul className="sidebar__items">
      {routes.map(({ title, path, icon }, index) => (
        <li className="sidebar__item" key={index}>
          <NavLink
            to={path}
            className="sidebar__item_link"
            activeClassName="sidebar__item_link-active"
          >
            {icon}
            {width > 1024 && <span>{title}</span>}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
