import { Button } from '../../atoms';

export const DownloadApp = (props) => (
  <div className="container download-card">
    <div className="first-row">
      <h2>Browse hundreds of professionals</h2>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor
      </p>
      <Button>DOWNLOAD APP</Button>
    </div>
    <div className="second-row">
      <img
        src="https://worqman.fra1.digitaloceanspaces.com/images/app-mockup.png"
        alt="app mockup"
      />
    </div>
  </div>
);
