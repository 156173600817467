import React from "react";

// react
import { Modal } from "react-bootstrap";

// react calendar
import { Calendar, utils } from "react-modern-calendar-datepicker";

// atoms
import { Button } from "../../atoms/index";
import dayjs from "dayjs";
import toObject from "dayjs/plugin/toObject";
import { dateRange } from "../../../utils/dates";

dayjs.extend(toObject);

export const AvailableDates = ({
  handleClose,
  dates,
  title,
  show,
  helpText,
  ...props
}) => {
  const disabledDays = [].concat
    .apply(
      [],
      !!dates.length !== 0 &&
        dates.map((date) => dateRange(new Date(date.start), new Date(date.end)))
    )
    .map((item) => {
      const dateObj = dayjs(item).format("DD/MM/YYYY").split("/");

      return { year: +dateObj[2], month: +dateObj[1], day: +dateObj[0] };
    });

  return (
    <Modal
      size="lg"
      centered
      show={show}
      aria-labelledby="available-dates"
      className="availableDates p-0"
      {...props}
    >
      <Modal.Title id="available-dates" className="mb-4">
        {title}
      </Modal.Title>

      <Modal.Body className="d-flex flex-column justify-content-center align-items-center w-100 p-0 crew-calendar npm">
        <Calendar
          className="crew-calendar"
          shouldHighlightWeekends
          disabledDays={disabledDays}
          minimumDate={utils().getToday()}
        />
        <div className="my-2 d-flex flex-row align-items-center">
          <span className="availableDates_icon" />
          <p className="m-0">Shows when the candidate isn't availiable</p>
        </div>
        <div className="my-2 d-flex flex-row align-items-center">
          <span className="freeAvailableDates_icon" />
          <p className="m-0">Shows when the candidate is availiable</p>
        </div>
      </Modal.Body>
      <Button onClick={handleClose} className="mt-2 py-2">
        Close
      </Button>
    </Modal>
  );
};
