import { useState } from "react";

// react icons
import { HiOutlineCalendar } from "react-icons/hi";

// router
// import { Link } from "react-router-dom";

// atoms
import { Button, AvailableDates, EditAvailableDates } from "../../atoms";

// component
import { SendRequest } from "../SendRequest/SendRequest";

export const CrewProfileActions = (props) => {
  const [showSendRequest, setShowSendRequest] = useState(false);
  const [showAvailableDates, setShowAvailableDates] = useState(false);

  const handleAvailableDates = (value) => () => {
    setShowAvailableDates(value);
  };

  const handleSendRequest = (value) => () => setShowSendRequest(value);

  return (
    <div className="crewProfileActions mb-2 mt-3">
      {!props.isAuthentication && (
        <>
          <div className="my-4 w-100 d-flex flex-column align-items-center justify-content-center">
            <Button
              onClick={handleSendRequest(true)}
              className="crewProfileActions_request"
            >
              SEND REQUEST
            </Button>

            <SendRequest
              show={showSendRequest}
              handleClose={handleSendRequest(false)}
              toUser={props.user.id}
            />
          </div>

          {/* <Link to="/chat">
            <Button variant="text" className="profileActions_chat my-2">
              <HiChat /> <span>Start chatting</span>
            </Button>
          </Link> */}

          <AvailableDates
            dates={props.user.appoint_dates || []}
            title="Available dates"
            show={showAvailableDates}
            handleClose={handleAvailableDates(false)}
          />
        </>
      )}
      <Button
        onClick={handleAvailableDates(true)}
        variant="text"
        className="profileActions_chat my-1"
      >
        <HiOutlineCalendar />
        {props.isAuthentication ? (
          <span>Edit available dates</span>
        ) : (
          <span>See available dates</span>
        )}
      </Button>
        {console.log("oha",props)}
      {!props.isCrew && (
        <EditAvailableDates
          dates={props.user.appoint_dates || []}
          title="Available dates"
          show={showAvailableDates}
          handleClose={handleAvailableDates(false)}
        />
      )}
    </div>
  );
};
