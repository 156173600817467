import { useState } from 'react';

// atoms
import { Button, NoFound } from '../../atoms';

// molecules
import { AddJob } from '../../molecules';

export const EmptyData = ({
  placeholder = 'data',
  buttonPlaceholder,
  addButton = false,
}) => {
  const [show, setShow] = useState(false);

  const handleToggle = (value) => () => setShow(value);

  return (
    <div className="empty__data">
      <NoFound />
      <h3>No {placeholder} was found!</h3>
      {addButton && (
        <div>
          <Button onClick={handleToggle(true)}>
            Create new {buttonPlaceholder}
          </Button>
          <AddJob
            show={show}
            title="Add new job"
            handleClose={handleToggle(false)}
          />
        </div>
      )}
    </div>
  );
};
