import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import { AuthContext } from "../../../context/AuthContext";
import { axiosAPI } from "../../../utils";
import { Button } from "../../atoms/index";
import dayjs from "dayjs";
import toObject from "dayjs/plugin/toObject";
import { dateRange } from "../../../utils/dates";
import { useHistory } from "react-router-dom";

dayjs.extend(toObject);

export const EditAvailableDates = ({
  handleClose,
  dates,
  title,
  show,
  ...props
}) => {
  const { auth } = useContext(AuthContext);
  const { push } = useHistory();

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  const handleDateToString = (date) => `${date.year}-${date.month}-${date.day}`;
  const handleDateToJSON = (date) => {
    return dayjs(handleDateToString(date)).add(1, "hour").format();
  };

  const currentDates = !!selectedDayRange &&
    !!selectedDayRange.from &&
    !!selectedDayRange.to && {
      start: handleDateToJSON(selectedDayRange.from),
      end: handleDateToJSON(selectedDayRange.to),
    };

  const handleSubmit = async () => {
    try {
      if (!!currentDates) {
        await axiosAPI.put(
          "/users/appoint-dates",
          {
            dates: [...dates, currentDates],
          },
          { headers: { Authorization: "Bearer " + auth.token } }
        );
        push("/profile");
        handleClose();
      }
    } catch (err) {
      return console.error(err.response.data.message);
    }
  };
  console.log("lalas",currentDates);

  const newDates = currentDates ? [...dates, currentDates] : dates;

  const disabledDays = [].concat
    .apply(
      [],
      !!dates.length !== 0
        ? newDates.map((date) =>
            dateRange(new Date(date.start), new Date(date.end))
          )
        : []
    )
    .map((item) => {
      const dateObj = dayjs(item).format("DD/MM/YYYY").split("/");

      return { year: +dateObj[2], month: +dateObj[1], day: +dateObj[0] };
    });

  return (
    <Modal
      size="lg"
      centered
      show={show}
      aria-labelledby="available-dates"
      className="availableDates p-0"
      {...props}
    >
      <Modal.Title id="available-dates" className="mb-4">
        {title}
      </Modal.Title>

      <Modal.Body className="d-flex flex-column justify-content-center align-items-center w-100 p-0 crew-calendar">
        <Calendar
          className="crew-calendar"
          value={selectedDayRange}
          onChange={setSelectedDayRange}
          shouldHighlightWeekends
          disabledDays={disabledDays}
          minimumDate={utils().getToday()}
        />
        <div className="my-2 d-flex flex-row align-items-center">
          <span className="availableDates_icon" />
          <p className="m-0">Shows when the candidate isn't availiable</p>
        </div>
        <div className="my-2 d-flex flex-row align-items-center">
          <span className="freeAvailableDates_icon" />
          <p className="m-0">Shows when the candidate is availiable</p>
        </div>
      </Modal.Body>
      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <Button variant="outlined" onClick={handleClose} className="m-3 py-2">
          Cancel
        </Button>
        <Button
          disabled={!selectedDayRange}
          onClick={handleSubmit}
          className="m-3 py-2"
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};
