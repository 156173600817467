import React from "react";
import ReactDOM from "react-dom";

export const Backdrop = (props) => {
  const element = (
    <div
      className="app-backdrop"
      style={{ zIndex: props.zIndex ? "1" : 120 }}
      onClick={props.onClick}
    />
  );
  return ReactDOM.createPortal(
    element,
    document.getElementById("backdrop-hook")
  );
};
