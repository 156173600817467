import * as React from "react";

export const WorkIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.774"
    height="22.653"
    viewBox="0 0 25.774 22.653"
    {...props}
  >
    <path
      id="work_3_"
      data-name="work (3)"
      d="M25.024,3.021H18.173V2.265A2.268,2.268,0,0,0,15.908,0H9.867A2.268,2.268,0,0,0,7.6,2.265V3.02H.755A.757.757,0,0,0,0,3.776V20.388a2.268,2.268,0,0,0,2.265,2.265H23.509a2.268,2.268,0,0,0,2.265-2.265V3.788a.733.733,0,0,0-.75-.768ZM9.112,2.265a.756.756,0,0,1,.755-.755h6.041a.756.756,0,0,1,.755.755V3.02H9.112Zm14.86,2.265-2.345,7.035a.754.754,0,0,1-.716.516H16.663v-.755a.755.755,0,0,0-.755-.755H9.867a.755.755,0,0,0-.755.755v.755H4.864a.754.754,0,0,1-.716-.516L1.8,4.531Zm-8.819,7.551v1.51H10.622v-1.51Zm9.112,8.306a.756.756,0,0,1-.755.755H2.266a.756.756,0,0,1-.755-.755V8.429l1.2,3.614a2.262,2.262,0,0,0,2.149,1.549H9.112v.755a.755.755,0,0,0,.755.755h6.041a.755.755,0,0,0,.755-.755v-.755H20.91a2.262,2.262,0,0,0,2.149-1.549l1.2-3.614Zm0,0"
      transform="translate(0 0)"
      fill="#2bd49c"
    />
  </svg>
);
