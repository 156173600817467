import React, { useEffect, forwardRef, useState } from "react";
import Image from "react-bootstrap/Image";
import { MdModeEdit } from "react-icons/md";

export const AvatarProfile = forwardRef((props, ref) => {
  // local state
  const [filePreview, setFilePreview] = useState(props.preview || "");

  // handler
  const handlePreview = (file) => setFilePreview(URL.createObjectURL(file));

  const handleOnChange = (event) => {
    if (event?.target.files?.length) {
      handlePreview(event?.target.files[0]);
    }
    props.onChange && props.onChange(event);
  };

  useEffect(() => {
    if (props.preview) setFilePreview(props.preview);
  }, [setFilePreview, props]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <Image
        className="avatarProfile_img"
        src={filePreview || props.src}
        alt="profile"
      />
      {!!props.editMode && (
        <div className="avatar_label">
          <MdModeEdit height="20" width="20" />
          <label htmlFor={props.name}>Add an image</label>
          <input
            onChange={handleOnChange}
            ref={ref}
            type="file"
            name={props.name}
            id={props.name}
            aria-label="input file"
          />
        </div>
      )}
    </div>
  );
});
