import { InfoCards } from '../Cards/InfoCards';
import { PageSectionHeading } from '../PageSections/PageSectionHeading';

const infoCards = [
  {
    id: 1,
    image:
      'https://worqman.fra1.digitaloceanspaces.com/images/Human%20Resource-Job%20Search-Magnifying%20Glass-Paper-Search.svg',
    title: 'Looking for a job?',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
  },
  {
    id: 2,
    image: 'https://worqman.fra1.digitaloceanspaces.com/images/team.svg',
    title: 'Looking for a crew?',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
  },
];

export const SelectRole = () => (
  <section id="select-role">
    <PageSectionHeading
      title="Select your role"
      text="Lorem ipsum dolor sit amet, coectetur adipiscing elit. Suspendisse varius
  enim in eros."
    />
    <InfoCards cards={infoCards} variant="flex" />
  </section>
);
