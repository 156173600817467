import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import clsx from "clsx";
import { BsCalendarEvent } from "react-icons/bs";
import { Calendar, utils } from "react-modern-calendar-datepicker";

import {
  WorkIcon,
  Button,
  TextareaField,
  InputField,
  JobAvailableDates,
} from "../../atoms";
import { PROFESSIONS } from "../../../static";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { axiosAPI } from "../../../utils";
import { FiSettings } from "react-icons/fi";
import { FaLayerGroup } from "react-icons/fa";
import { MdOutlineLocationOn } from "react-icons/md";
import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { RiArrowDropDownLine } from "react-icons/ri";
import BForm from "react-bootstrap/Form";
import dayjs from "dayjs";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw  } from 'draft-js';
import  draftToHtml  from 'draftjs-to-html'; 
import { dateRange } from "../../../utils/dates";



const CreateSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  //start: Yup.string().required("Required"),
  //end: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  job_type: Yup.string().required("Required"),
  //description: Yup.string().required("Required"),
  //profession: Yup.array().min(1, "Select a profession").required("Required"),
  job_type: Yup.object().required(),
});

export const JOBTYPE = [
  { value: "full-time", label: "Full Time" },
  { value: "part-time", label: "Part Time" },
  { value: "select-date", label: "Select Date" },
  
];

const customComponents = {
  Control: (rest) => (
    <components.Control {...rest}>
      <>
        <FiSettings className="selectField_icon" />
        {rest.children}
      </>
    </components.Control>
  ),
  IndicatorSeparator: () => null,
  IndicatorsContainer: () => (
    <components.DownChevron>
      <RiArrowDropDownLine />
    </components.DownChevron>
  ),
};

const customStyles = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
    border: "none",
    borderBottom: "1px solid #f4f4f6fd",
    borderRadius: "0",
    cursor: "pinter",
    padding: "4px",
    paddingRight: 0,
    "&:hover": {
      borderColor: "#f4f4f6fd",
    },
  }),
};

export const AddJob = ({ isEdit, show, title, handleClose, ...rest }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [latLng, setLatLng] = useState(null);
  const { push } = useHistory();
  const [error, setError] = useState();

  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);
  const { auth } = useAuthorization();
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
   const handleStartDate = (value) => () => setOpenStart(value);
   const handleEndDate = (value) => () => setOpenEnd(value);
   const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  const handleEditorChange = (editorState, setFieldValue) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const html = draftToHtml(rawContentState);
    //setFieldValue("description", html)
    //console.log(html);
  };

  const jobType = (values, setFieldValue) => {
    setFieldValue("job_type", values);

    if(values.value === "select-date"){
      document.getElementsByClassName("addJob_date")[0].style.display = "block"

    }else{
      document.getElementsByClassName("addJob_date")[0].style.display = "none"

    }
  }

  const handleAddress = (value, setFieldValue) => {
    if (typeof value === "object") {
      setFieldValue("address", value.formatted_address);
    } else {
      setFieldValue("address", value);
    }
  };

  const handleSelectAddress = (value) => {
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => setLatLng(latLng))
      .catch((error) => console.error("Error", error));
  };
  const onSubmit = async (data, formikApi) => {
    let today = new Date();

    if(data.job_type.value === "select-date"){
      if(selectedDayRange.from == null || selectedDayRange.to == null){
        return false;
      }

      var fromValue = selectedDayRange.from;
      var toValue = selectedDayRange.to;
      var dayFrom = String(fromValue.day).padStart(2, '0'); // add leading zero if day is a single digit
      var monthFrom = String(fromValue.month).padStart(2, '0'); // add leading zero if month is a single digit
      var yearFrom = String(fromValue.year);
      var formattedDateFrom = dayjs(`${yearFrom}-${monthFrom}-${dayFrom}`).toISOString(); 
      data.start = formattedDateFrom;

      var dayTo = String(toValue.day).padStart(2, '0'); // add leading zero if day is a single digit
      var monthTo = String(toValue.month).padStart(2, '0'); // add leading zero if month is a single digit
      var yearTo = String(toValue.year);
      var formattedDateTo = dayjs(`${yearTo}-${monthTo}-${dayTo}`).toISOString(); 
      data.end =  formattedDateTo;


    }else{
      
      let date =today.getDate() + "/"+ parseInt(today.getMonth()+1) +"/"+today.getFullYear();

      data.start = today;
      // Add 10 years to the current date
      let futureDate = new Date(today.getFullYear() + 10, today.getMonth(), today.getDate());

      data.end =  futureDate;
    }

    setError(null);
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const html = draftToHtml(rawContentState);
    const formData = {
      ...data,
      description: html,
      skill: data.profession.map((i) => i.value).toString() || "",
      location: data.address,
      latLng,
      job_type: data.job_type.value,
    };
    axiosAPI
      .post("/job/create-job", formData, {
        headers: { Authorization: "Bearer " + auth.token },
      })
      .then((res) => {
        push(`/jobs/${res.data.id}`);
      })
      .catch((err) => {
        setError(err.response.data.error);
        formikApi.setSubmitting(false);
      });
  };
  const handleDateChange = (value) => {
    var day = String(value.day).padStart(2, '0'); // add leading zero if day is a single digit
    var month = String(value.month).padStart(2, '0'); // add leading zero if month is a single digit
    var year = String(value.year);
    var formattedDate = `${day}/${month}/${year}`;

    if (startDate === null) {
      setStartDate(formattedDate);
    } else if (endDate === null) {
      if (value < startDate) {
        setStartDate(formattedDate);
        setEndDate(formattedDate);
      } else {
        setEndDate(formattedDate);
        setOpen(false);
      }
    } else {
      setStartDate(formattedDate);
      setEndDate(null);
    }


  };

  
    const toolbarOptions = {
      options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'embedded', 'emoji'],
      inline: {
        options: ['bold', 'italic', 'underline'],
      },
      blockType: {
        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
      },
      fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      },
      //fontFamily: false,
      list: {
        options: ['unordered', 'ordered'],
      },
      textAlign: {
        options: ['left', 'center', 'right'],
      },
      colorPicker: {
        colors: ['rgb(0, 0, 0)', 'rgb(255, 255, 255)', 'rgb(255, 0, 0)', 'rgb(255, 165, 0)', 'rgb(255, 255, 0)', 'rgb(0, 128, 0)', 'rgb(0, 0, 255)', 'rgb(75, 0, 130)', 'rgb(238, 130, 238)'],
      },
      link: {
        options: ['link'],
      },
      embedded: {
        defaultSize: {
          height: 'auto',
          width: 'auto',
        },
      },
      emoji: false,
      image: false, 
    };
  

  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      aria-labelledby="add-job"
      className={clsx(
        "addJob",
        openStart || openEnd ? "addJob_hidden" : "addJob_visible"
      )}
      {...rest}
    >
      <section>
        {error && (
          <div className="response--error">
            <div>
              <p>{error}</p>
            </div>
          </div>
        )}
      </section>

      <Formik
        initialValues={{}}
        validationSchema={CreateSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          setFieldValue,
          isValid,
          isSubmitting,
          handleSubmit,
          values,
        }) => (
          <>
            <Modal.Title id="add-job">{title}</Modal.Title>
            <Modal.Body className="d-flex flex-column justify-content-center align-items-center w-100">
              <div className="w-100 my-3">
                <InputField
                  value={values.name}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                  placeholder="Name of the job"
                  icon={<WorkIcon />}
                  required
                />
                {errors.name && touched.name && (
                  <div className="error-message">Name is required</div>
                )}
                <BForm.Group className="selectField mt-3">
                  <div className="d-flex flex-row justify-content-start align-items-center multiSelect_wrapper">
                    <Select
                      onChange={(values) => {
                        setFieldValue("profession", values);
                      }}
                      value={values.profession}
                      options={PROFESSIONS}
                      isMulti
                      className="selectField_input w-100"
                      components={customComponents}
                      styles={customStyles}
                      placeholder="Professions"
                    />
                  </div>
                </BForm.Group>
                {errors.profession && touched.profession && (
                  <div className="error-text m-2">Professions is required</div>
                )}
                <PlacesAutocomplete
                  value={values.address}
                  onChange={(value) => handleAddress(value, setFieldValue)}
                  //onSelect={handleSelectAddress}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div
                      className="search--location"
                      style={{ position: "relative" }}
                    >
                      <div className="search__job__input_control">
                        <InputField
                          {...getInputProps({
                            placeholder: "Search",
                            className: "location-search-input",
                          })}
                          placeholder="Location"
                          icon={<MdOutlineLocationOn />}
                        />
                        {errors.location && (
                          <div className="error-message">
                            Location is required
                          </div>
                        )}
                      </div>
                      {loading && (
                        <div className="autocomplete-dropdown-container">
                          <div style={{ padding: "15px 32px" }}>Loading...</div>
                        </div>
                      )}

                      {suggestions && suggestions.length >= 1 && (
                        <div className="autocomplete-dropdown-container">
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                                style={{
                                  padding: "15px 32px",
                                  cursor: "pointer",
                                }}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </PlacesAutocomplete>
                {/* <BForm.Group className="selectField mt-3"> */}
                  <div className="d-flex flex-row justify-content-start align-items-center mb-4 ">
                    <Select
                      onChange={(values) => {
                       jobType(values, setFieldValue)
                      }}
                      value={values.job_type}
                      options={JOBTYPE}
                      className="selectField_input w-100"
                      components={customComponents}
                      styles={customStyles}
                      placeholder="Job Type"
                    />
                  </div>
                {/* </BForm.Group> */}
                {/* <TextareaField
                  placeholder="Description"
                  style={{
                    height: "100px"
                  }}
                  value={values.description}
                  onChange={(e) => setFieldValue("description", e.target.value)}
                  icon={<FaLayerGroup />}
                />
                {errors.description && touched.description && (
                  <div className="error-message">Description is required</div>
                )} */}

              <div className="addJob_date my-3 mb-3">
                <h6 className="fs-6 text_secondary">JOB TIMELINE</h6>
                <div className="d-flex flex-row justify-content-start align-items-center w-100 my-2">
                <div>
              <label htmlFor="date-range">
                <button className="addJob_btn" onClick={() => setOpen(true)}>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <BsCalendarEvent className="mr-2" />
                    <p className="m-0 mr-1">Available Dates</p>
                    {selectedDayRange.from && selectedDayRange.to ? (
                      <p className="m-0">
                        {" - "}
                        {`${selectedDayRange.from.day+ "/"+selectedDayRange.from.month + "/" + selectedDayRange.from.year} - ${selectedDayRange.to.day+ "/"+selectedDayRange.to.month + "/" + selectedDayRange.to.year}`}
                      </p>
                    ) : (
                      <p className="m-0">Select Dates</p>
                    )}
          </div>
        </button>
        <Calendar
          className="crew-calendar"
          value={selectedDayRange}
          onChange={setSelectedDayRange}
          minimumDate={utils().getToday()}
        />

        {/* <Calendar
          className="crew-calendar"
          // value={selectedDayRange}
          // onChange={setSelectedDayRange}
          onChange={(e) => handleDateChange(e)}
         // value={{ start: startDate, end: endDate }}
         selectRange={true}
         //value={startDate && endDate ? [startDate, endDate] : null}
         value={{
          from: startDate,
          to: endDate,
        }} 

          //disabledDays={disabledDays}
          minimumDate={utils().getToday()}
        />*/}
       
      </label>
    </div>
                </div>
              </div>
            <div>
                  <Editor
                    toolbar={toolbarOptions}
                    autoFocus
                    placeholder=" Job Description"
                    required
                     editorState={editorState}
                     onEditorStateChange={handleEditorChange}
                     editorClassName="jobEditor"
                     toolbarClassName="jobToolbar"
                  />
                </div>
              </div>

             

              <div className="addJob_actions">
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  className="my-2 py-2"
                >
                  Cancel
                </Button>

                <Button
                  disabled={!isValid}
                  type="submit"
                  className="my-2 py-2"
                  onClick={handleSubmit}
                >
                  Save changes
                </Button>
              </div>
            </Modal.Body>
          </>
        )}
      </Formik>
    </Modal>
  );
};
