import React from "react";

import clsx from "clsx";

// prop-types
import PropTypes from "prop-types";

export const TextDetails = ({ link, icon, children, divider, label, text }) => {
  return (
    <div
      className={clsx(
        "textDetails my-2 d-flex flex-row justify-content-start align-items-center",
        divider && "textDetails_borderBottom my-4 pb-2"
      )}
    >
      {icon && <span className="mr-3 pr-1 textDetails_icon">{icon}</span>}
      {children && children}
      {!children && text && (
        <div className="d-flex flex-column justify-content-center align-items-start">
          <p className="m-0 textDetails_p">{label}</p>
          {link ? (
            <a target="_blank" rel="noreferrer" href={text}>
              {text}
            </a>
          ) : (
            <h3 className="m-0 textDetails_h3">{text}</h3>
          )}
        </div>
      )}
    </div>
  );
};

TextDetails.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.element,
  divider: PropTypes.bool,
  link: PropTypes.bool,
  children: PropTypes.element,
};
