// atoms
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Loading, Ternary } from '../../components/atoms';

// molecules
import {
  HeaderBar,
  NotificationList,
  NotificationInfo,
  EmptyData,
} from '../../components/molecules';

import { useAuthorization } from '../../hooks/useAuthorization';
import { axiosAPI } from '../../utils';

export const Notifications = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const [data, setData] = useState(true);
  const [loading, setLoading] = useState(true);
  const {
    auth: { token, status },
  } = useAuthorization();
  
  // hooks
  const getData = useCallback(async () => {
    try {
      if (token) {
        const { data } = await axiosAPI.get(
          'invitation/invitation-notification',
          {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              isCompany: status === 1,
            },
          }
        );
        if (data) {
          setData(data);
          setLoading(false);
          if (data[0]?.id) {
            push(`/notifications/${data[0]?.id}`);
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [push, status, token]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (loading) return <Loading />;
  return (
    <>
      <HeaderBar title="Notifications" />
      <Ternary
        condition={data.length !== 0}
        fallback={<EmptyData placeholder="notification" />}
      >
        <div className="notifications">
          <h3 className="notifications__title">your chats</h3>
          <div className="notifications__wrapper">
            <NotificationList data={data} />
            {/* <hr />
            {id && <NotificationInfo id={id} />} */}
          </div>
        </div>
      </Ternary>
    </>
  );
};
