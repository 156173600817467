import clsx from "clsx";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { AuthContext } from "../../../context/AuthContext";

const NavLinks = ({ onClick, ...props }) => {
  const { auth } = useContext(AuthContext);
  const { pathname } = useLocation();

  let links;

  if (!!auth.status && auth.status === 1) {
    links = [
      {
        label: "My Jobs",
        route: "/requests/current?page=1",
      },
      {
        label: "Search",
        route: "/search/1",
      },
      {
        label: "Profile",
        route: `/profile`,
      },
    ];
  } else if (!!auth.status && auth.status === 2) {
    links = [
      {
        label: "My Jobs",
        route: "/requests/current?page=1",
      },
      {
        label: "Profile",
        route: `/crew/${auth.slug}`,
      },
    ];
  }
  // else {
  //  links = [
  //    {
  //      label: "HIRE A WORQER",
  //      route: "/hire",
    //  },
      //{
        //label: "BECOME A WORQER",
        //route: "/become-worqer",
      //},
      //{
       // label: "HOW IT WORKS",
        //route: "/how-it-works",
      //},
      //{
        //label: "BLOG",
        //route: "/blog",
      //},
    //];
  //}

  return (
    <ul className="nav-menu">
      {!!links &&
        links.map(({ label, route }, index) => (
          <li key={`${label}-${index}`} className="nav-menu__item">
            <NavLink
              className={clsx(
                "nav-menu__link",
                pathname === route && "nav_active"
              )}
              to={route}
              onClick={onClick}
            >
              {label}
            </NavLink>
          </li>
        ))}
    </ul>
  );
};

export default NavLinks;
