// react
import { useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import queryString from "query-string";
import { BsBriefcase, BsCalendar4 } from "react-icons/bs";
import { TiLocationArrowOutline } from "react-icons/ti";
import { RiArrowDropDownLine } from "react-icons/ri";
import DatePicker, { utils } from "react-modern-calendar-datepicker";

// atoms
import { Button, InputField, SelectField } from "../../atoms";

// proffesions
import { PROFESSIONS } from "../../../static/index";
import { compareDates } from "../../../utils/dates";
import PlacesAutocomplete from "react-places-autocomplete";
import { ranges } from "../../../data";
import { MdOutlineLocationOn } from "react-icons/md";

export const CrewSearch = () => {
  const [fromDay, setFromDay] = useState(null);
  const [toDay, setToDay] = useState(null);
  const [dateErroMessage, setDateErrorMessage] = useState(false);
  const [address, setAddress] = useState(null);
  const [range, setRange] = useState("2");

  const handleSelect = (address) => {
    setAddress(address);
  };

  const { push } = useHistory();
  const search = useLocation().search;
  const job = new URLSearchParams(search).get("job");
  const { register, handleSubmit, control } = useForm();

  // render regular HTML input element
  const FromDatePicker = ({ ref }) => (
    <div className="search__crew__input_control">
      <InputField
        readOnly
        icon={<BsCalendar4 />}
        divider="true"
        placeholder="From"
        value={fromDay ? `${fromDay.year}.${fromDay.month}.${fromDay.day}` : ""}
        ref={ref}
      />
      {dateErroMessage && (
        <span className="error__text text-danger">
          From day can't be greater than To day!
        </span>
      )}
    </div>
  );

  const ToDatePicker = ({ ref }) => (
    <div className="search__crew__input_control">
      <InputField
        readOnly
        icon={<BsCalendar4 />}
        divider="true"
        placeholder="To"
        value={toDay ? `${toDay.year}.${toDay.month}.${toDay.day}` : ""}
        ref={ref}
      />
    </div>
  );

  const customComponents = {
    Control: (rest) => (
      <components.Control {...rest}>
        <>
          <span
            style={{ borderRight: "1px solid #e6e8f0", paddingRight: "14px" }}
          >
            <BsBriefcase className="selectField_icon" />
          </span>
          {rest.children}
        </>
      </components.Control>
    ),
    IndicatorSeparator: () => null,
    IndicatorsContainer: () => (
      <components.DownChevron>
        <RiArrowDropDownLine />
      </components.DownChevron>
    ),
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      boxShadow: "none",
      border: "none",
      borderRadius: "0",
      cursor: "pinter",
      padding: "4px",
      paddingRight: 0,
    }),
  };

  const onSubmit = ({ proffesion, location }) => {
    if (!proffesion && !location && !fromDay && !toDay && !address) return;
    if ((fromDay && !toDay) || (!fromDay && toDay)) {
      return;
    }

    if (fromDay && toDay && compareDates(fromDay, toDay)) {
      setDateErrorMessage(true);
      return;
    }

    push({
      pathname: "/search/results",
      search: `${queryString.stringify({
        ...(proffesion && { proffesion: proffesion.value }),
        ...(location && { location }),
        ...(fromDay && {
          fromDay: `${fromDay?.year}-${fromDay?.month}-${fromDay?.day}`,
        }),
        ...(toDay && {
          toDay: `${toDay?.year}-${toDay?.month}-${toDay?.day}`,
        }),
        ...(job && { job }),
        ...(range && { range }),
        ...(address && { address }),
      })}`,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="search__crew">
        <div className="search__crew__input_control d-flex flex-column align-items-center justify-content-center">
          <Controller
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                onChange={onChange}
                ref={ref}
                value={value}
                onBlur={onBlur}
                options={PROFESSIONS}
                className="selectField_input w-100"
                components={customComponents}
                styles={customStyles}
                placeholder="Skill"
              />
            )}
            control={control}
            onChange={([selected]) => selected}
            name="proffesion"
          />
        </div>
        <div className="search__crew__input_control">
          <PlacesAutocomplete
            value={address}
            onChange={(value) => setAddress(value)}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div
                className="search--location"
                style={{ position: "relative" }}
              >
                <div className="search__job__input_control">
                  <InputField
                    icon={<TiLocationArrowOutline />}
                    divider="true"
                    placeholder="Location"
                    {...register("location")}
                    {...getInputProps({
                      placeholder: "Search",
                      className: "location-search-input",
                    })}
                  />
                </div>
                {loading && (
                  <div className="autocomplete-dropdown-container">
                    <div style={{ padding: "15px 32px" }}>Loading...</div>
                  </div>
                )}

                {suggestions && suggestions.length >= 1 && (
                  <div className="autocomplete-dropdown-container">
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          style={{
                            padding: "15px 32px",
                            cursor: "pointer",
                          }}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        <div className="search__job" style={{ position: "relative" }}>
          <div className="search__job__input_control">
            <SelectField
              onChange={(e) => setRange(e.target.value)}
              name="job"
              className="w-100"
              icon={<MdOutlineLocationOn />}
              options={ranges}
              value={range}
            />
          </div>
        </div>

        <DatePicker
          value={fromDay}
          onChange={setFromDay}
          renderInput={FromDatePicker}
          shouldHighlightWeekends
          minimumDate={utils().getToday()}
        />

        <DatePicker
          value={toDay}
          onChange={setToDay}
          renderInput={ToDatePicker}
          shouldHighlightWeekends
          minimumDate={utils().getToday()}
        />

        <Button type="submit">Find Crew</Button>
      </form>
    </>
  );
};
