import { useContext } from "react";

// contexts
import { AuthContext } from "../context/AuthContext";

export const useAuthorization = () => {
  const auth = useContext(AuthContext);

  return auth;
};
