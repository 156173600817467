import React from "react";
import AliceCarousel from "react-alice-carousel";

import CompanyProfileBox from "../CompanyProfileBox/CompanyProfileBox";

const responsive = {
  0: { items: 1 },
  768: { items: 2 },
};

const FeaturedCrewSlider = (props) => {
  const handleOnDragStart = (e) => e.preventDefault();

  return (
    <div className="row">
      <div className="col-lg-12 crew-slider">
        {props.companies.length !== 0 && (
          <AliceCarousel
            mouseTrackingEnabled
            responsive={responsive}
            keysControlDisabled={true}
            swipeDisabled={false}
          >
            {props.companies.map((el, index) => {
              return (
                <CompanyProfileBox
                  onDragStart={handleOnDragStart}
                  key={index}
                  {...el}
                  border
                />
              );
            })}
          </AliceCarousel>
        )}
      </div>
    </div>
  );
};

export default FeaturedCrewSlider;
