import React, { useEffect } from "react";
import { axiosAPI } from "../../utils/axios";
import { useAuthorization } from "../../hooks/useAuthorization";
import { useLocation } from "react-router-dom";
import { Button } from "../../components/atoms";
function Success(props) {
  const search = useLocation().search;
  const sessionId = new URLSearchParams(search).get("session_id");

  const {
    auth: { token },
  } = useAuthorization();

  console.log(token);
  useEffect(() => {
    if (token) {
      axiosAPI
        .put(
          `/stripe/subscription/${sessionId}`,
          {},
          {
            headers: { Authorization: "Bearer " + token },
          }
        )
        .then((res) => console.log(res));
    }
  }, [sessionId, token]);

  return (
    <div className="wrapper">
      <div className="success_wrapper">
        <p className=".main-title">
          Yu have successfully Subscribed to our web page.
        </p>
      </div>
    </div>
  );
}

export default Success;
