export const JobsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.251"
    height="19.376"
    viewBox="0 0 23.251 19.376"
  >
    <g id="work_1_" data-name="work (1)" transform="translate(0 0)">
      <path
        id="Path_8787"
        data-name="Path 8787"
        d="M14.782,6.36a.969.969,0,0,1-.969-.969V3.938H9.938V5.391A.969.969,0,1,1,8,5.391V3.938A1.94,1.94,0,0,1,9.938,2h3.875A1.94,1.94,0,0,1,15.75,3.938V5.391A.969.969,0,0,1,14.782,6.36Z"
        transform="translate(-0.25 -2)"
        fill="#bab8c6"
      />
      <path
        id="Path_8788"
        data-name="Path 8788"
        d="M12.313,15.263a2,2,0,0,1-.688.116,2.123,2.123,0,0,1-.746-.136L0,11.62v7.392a2.663,2.663,0,0,0,2.664,2.664H20.587a2.663,2.663,0,0,0,2.664-2.664V11.62Z"
        transform="translate(0 -2.3)"
        fill="#bab8c6"
      />
      <path
        id="Path_8789"
        data-name="Path 8789"
        d="M23.251,7.664V9.883l-11.393,3.8a.717.717,0,0,1-.465,0L0,9.883V7.664A2.663,2.663,0,0,1,2.664,5H20.587A2.663,2.663,0,0,1,23.251,7.664Z"
        transform="translate(0 -2.094)"
        fill="#bab8c6"
      />
    </g>
  </svg>
);
