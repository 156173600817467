// molecules
import { Link } from "react-router-dom";
import { HeaderBar } from "../../components/molecules";
import { useAuthorization } from "../../hooks/useAuthorization";

export const ErrorPage = () => {
  const {
    auth: { token },
  } = useAuthorization();
  return (
    <>
      {token !== "" && <HeaderBar title="Opps..." />}
      <div className="error">
        <h1>404</h1>
        <h2>Opps... Seems like you have entered a wrong path!</h2>
        {token === "" && <Link to="/login">Go Back</Link>}
      </div>
    </>
  );
};
