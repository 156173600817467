import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

const CompanyProfileBox = (props) => {
  let image;
  if (props.image) {
    image = (
      <img
        src={process.env.REACT_APP_PUBLIC_URL + props.image}
        alt={props.name}
        className="profile-box__image"
      />
    );
  } else {
    image = (
      <div className="avatar">
        <FontAwesomeIcon icon={faBuilding} />
      </div>
    );
  }

  return (
    <div className={`${props.className} CompanyProfileBox__wrapper`}>
      <div className="profile-box">
        {image}
        <p className="profile-box__name">{props.name}</p>

        <div className="profile-box__data">
          {/* <div className="box">
                        <FontAwesomeIcon icon={faFilter}/>
                        {props.profession && JSON.parse(props.profession).map((el, index) => index === JSON.parse(props.profession).length - 1 ? el.value + ' ' : el.value + ', ' )}
                    </div> */}
          {/* <div className="box">
                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                        {props.city}
                    </div> */}
        </div>
        <NavLink to={`/company/${props.slug}`} className="btn-c btn--underline">
          See Profile
        </NavLink>
      </div>
    </div>
  );
};

export default CompanyProfileBox;
