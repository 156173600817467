export const PROFESSIONS = [
  { value: "Brick Layer", label: "Brick Layer" },
  { value: "Handy Man", label: "Handy Man" },
  { value: "Labourer", label: "Labourer" },
  { value: "Painter", label: "Painter" },
  { value: "Ground Worker", label: "Ground Worker" },
  { value: "Scaffolder", label: "Scaffolder" },
];

export const GENDERS = [
  { value: 1, label: "Male" },
  { value: 2, label: "Female" },
  { value: 3, label: `I'd rather not say` },
];

export const EXPERIENCE = [
  { value: "Apprentice", label: "Apprentice" },
  { value: "Less than 1 year", label: "Less than 1 year" },
  { value: "2 years", label: "2 years" },
  { value: "3 years", label: "3 years" },
  { value: "4 + years", label: "4 + years" },
];
