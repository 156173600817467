import React, { useState } from "react";
import { axiosAPI } from "../../utils/index";

import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

export const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const history = useHistory();

  const onSubmit = async (e) => {
    setLoading(true);
    setError(false);
    setSuccess(false);
    let response;
    try {
      response = await axiosAPI.post("/users/reset-password", e);
      setSuccess(response.data.message);
    } catch (err) {
      setLoading(false);
      return setError(err.response.data.message);
    }

    setLoading(false);
  };

  return (
    <div id="login">
      <Modal
        show={true}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => !loading && history.push("/")}
        centered
      >
        <section>
          {loading && (
            <div className="bar">
              <div></div>
            </div>
          )}
        </section>

        <div className="modal--content">
          <section className="modal-remove-wrapper">
            <span
              className="remove-modal-button"
              onClick={() => !loading && history.push("/")}
            >
              &#x2715;
            </span>
          </section>

          <section>
            <div className="modal--head text-center">
              <h3 className="modal--title">Forgot Password</h3>
              <p className="modal--description">
                {" "}
                Enter your email address t reset your password
              </p>
            </div>
          </section>

          <section>
            {success && (
              <div className="response--success">
                <div>
                  <p>{success}</p>
                </div>
              </div>
            )}
          </section>

          <section>
            {error && (
              <div className="response--error">
                <div>
                  <p>{error}</p>
                </div>
              </div>
            )}
          </section>

          <section>
            <form className="form--data" onSubmit={handleSubmit(onSubmit)}>
              <div className="form--group">
                <input
                  type="text"
                  className="form--control"
                  name="email"
                  placeholder="Your email address"
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+\.\S+$/,
                  })}
                />
                {errors.email?.type === "required" && (
                  <div className="error-text">Email is required</div>
                )}
                {errors.email?.type === "pattern" && (
                  <div className="error-text">Field must be email</div>
                )}
              </div>
              <div className="submit--group">
                <div className="btn--wrapper w-100 text-center">
                  <button
                    className={`btn-c btn--dark btn--center ${
                      loading && "disabled"
                    }`}
                    disabled={loading && true}
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </form>
          </section>
        </div>
      </Modal>
    </div>
  );
};
