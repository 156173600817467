import React, { useCallback, useEffect, useState } from "react";

// react icons
import { MdModeEdit } from "react-icons/md";
import { IoPersonAdd } from "react-icons/io5";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button } from "../../components/atoms";
import dayjs from "dayjs";
import { Helmet } from 'react-helmet';


// molecules
import { HeaderBar, EditJob } from "../../components/molecules";
import { useAuthorization } from "../../hooks/useAuthorization";
import { axiosAPI } from "../../utils";
import MixPanel from "../../utils/MixPanel";


export const SingleJob = () => {
  const [show, setShow] = useState(false);
  const [job, setJob] = useState({});
  const {
    auth: { status, token, email, latitude, longitude },
  } = useAuthorization();
  const history = useHistory();
  const { id } = useParams();
  const isUser = status === 2;

  useEffect(() => {
    if (status === 2) {
      MixPanel.track("Visit_Job", { email: email, latitude, longitude });
    }
  }, [status, email]);

  const getData = useCallback(async () => {
    try {
      if (token) {
        const { data } = await axiosAPI.get("/job/job-by-id", {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            id,
          },
        });

        setJob(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [id, token]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleToggle = (value) => () => setShow(value);
  const redirectToExplore = () => {
    history.push(`/explore?job=${id}`);
  };

  return (
    <>
    
      <HeaderBar backButton />
      <div className="single__job">
        <h3 className="single__job__title">job</h3>
        <div className="single__job__card">
          <div style={{ display: "flex" }}>
            {!isUser && (
              <button
                onClick={handleToggle(true)}
                className="single__job__card__edit"
              >
                <MdModeEdit />
              </button>
            )}
            {!isUser && (
              <button
                onClick={redirectToExplore}
                className="single__job__card__add"
              >
                <IoPersonAdd />
              </button>
            )}
          </div>
              
          {JSON.stringify(job) != '{}' && (
            <EditJob
              isEdit={true}
              job={job}
              show={show}
              title="Edit job"
              handleClose={handleToggle(false)}
            />
          )}


          <SingleJobBanner />
          {job && (
            <SingleJobDetails
              isUser={isUser}
              jobDetails={{
                id: (job.invitations && job.invitations[0]?.id) || id,
                title: job.name,
                location: job.location,
                requiredSkill: job.skill || "",
                startDate: job.start,
                expireDate: job.end,
                description: job.description,
                job_type: job.job_type
              }}
              token={token}
            />
          )}
        </div>
      </div>
    </>
  );
};

const SingleJobBanner = () => (
  <div className="job__banner">
    {/* <img
      src="https://images.unsplash.com/photo-1514589553259-ed2658dad420?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3616&q=80"
      alt="nature"
    /> */}
    <div className="job__banner__overlay" />
  </div>
);

const SingleJobDetails = ({ token, isUser, jobDetails }) => {
  const [hasInvitations, setHasInvitations] = useState(false);
  const { id } = useParams();
  const { push } = useHistory();

  const [crewAccepted, setCrewAccepted] = useState([]);
  const [crewDeclined, setCrewDeclined] = useState([]);

  const handleDecline = async () => {
    try {
      if (token) {
        MixPanel.track("Not_Interested", {});
        const response = await axiosAPI.put(
          "/invitation/decline-invitation",
          {
            invitation_id: jobDetails.id,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response) push("/jobs?status=active");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleAccept = async () => {
    try {
      if (token) {
        MixPanel.track("Interested", {});
        const response = await axiosAPI.put(
          "/invitation/accept-invitation",
          {
            invitation_id: jobDetails.id,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response) push("/jobs?status=active");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleInterested = async () => {
    try {
      if (token) {
        const response = await axiosAPI.post(
          "user-jobs/approve-job-by-id",
          {
            jobId: jobDetails.id,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response) push("/explore");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleReject = async () => {
    try {
      if (token) {
        const response = await axiosAPI.post(
          "user-jobs/reject-job-by-id",
          {
            jobId: jobDetails.id,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response) push("/explore");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getInvitations = useCallback(async () => {
    try {
      if (token) {
        const { data } = await axiosAPI.get("/invitation/user-invitation", {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            id,
          },
        });

        if (data) setHasInvitations(true);
        else setHasInvitations(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [id, token]);

  useEffect(() => {
    getInvitations();
  }, [getInvitations]);

  const getCrewAccepted = useCallback(async () => {
    try {
      if (token) {
        const { data } = await axiosAPI.get("/invitation/user-accepted", {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            id,
          },
        });

        setCrewAccepted(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [id, token]);

  const getCrewDeclined = useCallback(async () => {
    try {
      if (token && id) {
        const { data } = await axiosAPI.get("/invitation/user-declined", {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            id,
          },
        });
        setCrewDeclined(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [id, token]);

  useEffect(() => {
    getCrewAccepted();
    getCrewDeclined();
  }, [getCrewAccepted, getCrewDeclined]);
  const handleClick = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => document.getElementById("copy_job").style.color = "#0e976b")
      .catch((error) => console.log('Error copying:', error));
  };

  return (
    <div className="job__details">
      <div className="job__details__row">
      <a id="copy_job" onClick={handleClick} className="login-btn"
    style={{
      cursor: "pointer",
      color:"#29d49c",
      //fontSize: "17px",
      position: "absolute",
      marginTop: "60px",
      right: "21px",
      top:"30px",
      border: "none",
      background: "transparent"
    }}
    >Copy Job URL</a>
        <JobDetails jobDetails={jobDetails} />
      </div>
      <div className="job__details__row overflow-y">
        {isUser ? (
          hasInvitations ? (
            <div className="job__details__actions">
              <Button variant="outlined" onClick={handleDecline}>
                Decline
              </Button>
              <Button onClick={handleAccept}>Accept</Button>
            </div>
          ) : (
            <div className="job__details__actions">
              <Button variant="outlined" onClick={handleReject}>
                Not Interested
              </Button>
              <Button onClick={handleInterested}>Interested</Button>
            </div>
          )
        ) : (
          <>
            {/* crew Accepted crew Declined should be passed to the components */}
            <div className="crew__accepted">
              <h4>Crew Accepted</h4>
              <ul className="crew__list">
                {crewAccepted &&
                  crewAccepted.map((crew) => (
                    <CrewCard
                      key={crew.user.name}
                      name={crew.user.name}
                      skill={crew.user.user_professions[0]?.name}
                      image={
                        crew.user.image ||
                        "https://worqman.fra1.digitaloceanspaces.com/images/profile-placeholder-3.png"
                      }
                    />
                  ))}
              </ul>
            </div>

            <div className="crew__accepted">
              <h4>Crew Declined</h4>

              <ul className="crew__list">
                {crewDeclined.map((crew) => (
                  <CrewCard
                    key={crew.user.name}
                    name={crew.user.name}
                    skill={crew.user.user_professions[0]?.name}
                    image={
                      crew.user.image ||
                      "https://worqman.fra1.digitaloceanspaces.com/images/profile-placeholder-3.png"
                    }
                  />
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

// Molecules
const JobDetails = ({
  jobDetails: {
    title,
    description,
    location,
    requiredSkill,
    startDate,
    expireDate,
    job_type
  },
}) => (
  <>
    {/* <Helmet>
        <title>{title ? title : " " } - Worqer</title>
       <meta name="title" content={ title ? title : " "  } /> 
       <meta name="description" content={ description ?  description.replace(/(<([^>]+)>)/ig, "") : " " } /> 
    </Helmet> */}
    <h2 className="job__title">{title}</h2>
    <div className="job__body">
      <h4>
        Location
        <span className="location">{location}</span>
      </h4>
      <h4>
        Skill required
        <span className="skill">
          {requiredSkill.replaceAll('"', "").replaceAll(",", ", ")}
        </span>
      </h4>
    </div>
    {job_type != "select-date"?
    <div className="job__body mt-1">
    <h4>
    Job Type
    {job_type != "full-time" ? <span className="skill">Part Time</span>: <span className="skill">Full Time</span>}
  </h4></div>
    :
    <div className="job__date">
      <div className="job__date__start">
        <h4>{dayjs(startDate, "YYYY-MM-DD").format("DD-MM-YYYY")}</h4>
      </div>
      <div className="job__date__expire">
        <h4>{dayjs(expireDate, "YYYY-MM-DD").format("DD-MM-YYYY")}</h4>
      </div>
    </div>}
    <hr></hr>
    <div className="job__description">
      {/* {description} */}
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  </>
);

// Atom
const CrewCard = ({ name, skill, image }) => (
  <li className="crew__list__item">
    <img src={image} alt="crew profile" />
    <div>
      <p>{name}</p>
      <span>{skill}</span>
    </div>
  </li>
);
