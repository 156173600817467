// react
import { useCallback, useContext, useEffect } from "react";

// router
import { useHistory } from "react-router-dom";

import queryString from "query-string";

// molecules
import { HeaderBar, SearchBar } from "../../components/molecules";

// organisms
import { ExploreCards } from "../../components/organisms";

// context
import { AuthContext } from "../../context/AuthContext";
import { useState } from "react";
import { axiosAPI } from "../../utils";
import { Loading } from "../../components/atoms";
import MixPanel from "../../utils/MixPanel";

export const Explore = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    location: { search },
  } = useHistory();
  // auth context
  const {
    auth: { token, status, email, latitude, longitude },
    auth,
  } = useContext(AuthContext);
  useEffect(() => {
    if (status === 2) {
      MixPanel.track("Find_Jobs", { email: email, latitude, longitude });
    }
  }, [status, email]);

  const { company, location } = queryString.parse(search);

  const getFilteredData = useCallback(async () => {
    try {
      if (token) {
        const { data } = await axiosAPI.get("/job/filtered-jobs", {
          headers: { Authorization: `Bearer ${token}` },
          params: { company, location },
        });
        setData(data);
      }
    } catch (e) {
      console.error(e);
    }
  }, [company, location, token]);

  const getData = useCallback(async () => {
    try {
      if (token) {
        const { data } = await axiosAPI.get(
          status === 2 ? "/job/all-jobs" : "/users/all-users",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setData(data);
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  }, [status, token]);

  useEffect(() => {
    if (company || location) getFilteredData();
    else getData();
  }, [company, getData, getFilteredData, location]);

  if (loading) return <Loading />;

  return (
    <>
      <HeaderBar title="Explore" />
      <SearchBar isCompany={status === 1} />
      <ExploreCards
        title={status === 2 ? "jobs recommended for you" : "top freelancers"}
        data={data}
      />
    </>
  );
};
