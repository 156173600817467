import React, { useState, useEffect } from "react";
import Animate from "react-smooth";

import ForCompaniesTexts from "./ForCompaniesTexts";
import TitleUnderline from "../../shared/Components/TitleUnderline/TitleUnderline";
import CrewSlider from "../../shared/Components/CrewSlider/CrewSlider";
import Banner from "../../shared/Components/Banner/Banner";
import PricingCard from "../../shared/Components/PricingCard/PricingCard";

// axios
import { axiosAPI } from "../../utils/index";

// icons
import {
  EasyUseIcon,
  EasyToFindIcon,
  CommunicateIcon,
  SkilledProfessionalIcon,
} from "../../components/atoms/icons/index";

// atoms
import { Fluid, Container } from "../../components/atoms/index";

const TITLE_ONE = {
  title: "Hire in minutes",
  description:
    "Worqman Connects Your Business To A Community Of Qualified Professionals.",
};

const TITLE_TWO = {
  title: "Our Pricing",
  description:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor",
};

export const ForCompanies = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const sendRequest = async () => {
      try {
        let response = await axiosAPI.get("/companies/all-companies");
        setUsers(response.data);
      } catch (err) {}
    };

    sendRequest();
  }, []);

  return (
    <Animate to="3" from="0" attributeName="opacity">
      <div id="for-companies">
        <Banner
          backgroundImage="https://worqman.fra1.digitaloceanspaces.com/images/for-companies-banner.png"
          title="FOR COMPANIES"
          description="We Connect Your Operations Team To Qualified Freelance Crew At A Click Of A Button"
          height="500px"
        />

        <Container>
          <TitleUnderline
            title={TITLE_ONE.title}
            description={TITLE_ONE.description}
            center
          />
          <CrewSlider companies={users || []} />
        </Container>

        <Fluid bgcolor="#F8F8F8">
          <div
            bgcolor="#F8F8F8"
            style={{ maxWidth: 1200, margin: "auto", padding: "50px 0" }}
          >
            <div className="row why-worqman-content">
              <div className="col-lg-6 for-comp-images">
                <div className="row">
                  <div className="col-lg-6 py-3 px-1 for-comp-image-1">
                    <img
                      src="https://worqman.fra1.digitaloceanspaces.com/images/why-worqman-one.png"
                      alt="worqman one"
                    />
                  </div>
                  <div className="col-lg-6 for-comp-double-images">
                    <div className="row">
                      <div className="col-lg-12 col-md-6 py-3 px-1 for-comp-image-2">
                        <img
                          src="https://worqman.fra1.digitaloceanspaces.com/images/why-worqman-two.png"
                          alt="worqman two"
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 py-3 px-1 for-comp-image-3">
                        <img
                          src="https://worqman.fra1.digitaloceanspaces.com/images/why-worqman-three.png"
                          alt="worqman three"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 for-companies-text-container">
                <div className="row">
                  <div className="col-lg-12 for-companies-text-wrapper">
                    <div className="row">
                      <div className="col-lg-12 for-companies-content">
                        <ForCompaniesTexts
                          title="Why should you choose Worqman over other sites"
                          description={TITLE_ONE.description}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-lg-6 p-3 icon--left--text">
                        <SkilledProfessionalIcon />
                        <p>Skilled professional</p>
                      </div>
                      <div className="col-md-6 col-lg-6 p-3 icon--left--text">
                        <EasyToFindIcon />
                        <p>Easy to find professionals</p>
                      </div>
                      <div className="col-md-6 col-lg-6 p-3 icon--left--text">
                        <CommunicateIcon />
                        <p>Easy to communicate</p>
                      </div>
                      <div className="col-md-6 col-lg-6 p-3 icon--left--text">
                        <EasyUseIcon />
                        <p>Easy to use</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fluid>

        <Container>
          <TitleUnderline
            title={TITLE_TWO.title}
            // description={TITLE_TWO.description}
            center
          />
          <div className="d-flex justify-content-center">
            <PricingCard
              period=""
              // secondBenefit="£8.99 P/m or £89.99 p/a - gives you an extra 2 months FOC."
            />
          </div>
        </Container>
      </div>
    </Animate>
  );
};
