// react bootstrap
import { Badge } from "react-bootstrap";

import { GrFormAdd } from "react-icons/gr";

// atoms
import { Button } from "../../atoms";

// prop-types
import PropTypes from "prop-types";

export const CrewSkills = ({ skills, editMode, handleOpen }) => {
  return (
    <div className="d-flex flex-column justify-content-start">
      <p className="text_gray profileDetails_p">Professions</p>
      {!!skills && (
        <div className="crewSkills">
          {skills.map((item) => (
            <Badge key={item} pill variant="primary" className="m-1">
              {item}
            </Badge>
          ))}
        </div>
      )}

      {editMode && (
        <div className="my-2">
          <Badge pill variant="primary" className="my-1 crewSkills_add">
            <Button onClick={handleOpen} className="crewSkills_btn">
              Add skill <GrFormAdd />
            </Button>
          </Badge>
        </div>
      )}
    </div>
  );
};

CrewSkills.propTypes = {
  editMode: PropTypes.bool,
  handleOpen: PropTypes.func,
  skills: PropTypes.arrayOf(PropTypes.string),
};
