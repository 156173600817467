import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Button, SelectField, WorkIcon } from "../../atoms/index";
import { axiosAPI } from "../../../utils";
import { useForm } from "react-hook-form";
import { AddJob } from "../AddJob/AddJob";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useLocation } from "react-router-dom";

export const SendRequest = ({ show, handleClose, toUser, ...rest }) => {
  const [isOpenAddJob, setIsOpenAddJob] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [job, setJob] = useState(0);
  const [companyId, setCompanyId] = useState(null);
  const { auth } = useAuthorization();
  const search = useLocation().search;
  const jobQueryId = new URLSearchParams(search).get("job");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const handleToggle = (value) => () => setIsOpenAddJob(value);

  const handleOnChange = (formData) => setJob(+formData.job);

  const getCompanyActiveJobs = useCallback(async () => {
    try {
      if (auth.token) {
        axiosAPI
          .get("/job/all-my-company-jobs", {
            params: {
              userId: toUser,
            },
            headers: { authorization: `Bearer ${auth.token}` },
          })
          .then(({ data }) => {
            console.log(data);

            if (data && data.length) {
              setCompanyId(data[0]?.company_id);

              setJobs(
                data.map((item) => {
                  return { value: item.id, label: item.name };
                })
              );
            }
          });
      }
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.token]);

  const onSubmit = async () => {
    const data = {
      company_id: companyId,
      user_id: toUser,
      job_id: job || jobs[0].value,
    };

    try {
      await axiosAPI.post("/invitation/create-invitation", data, {
        headers: { Authorization: "Bearer " + auth.token },
      });
    } catch (err) {
      return console.log(err.response.data.message);
    }
    handleClose();
  };

  useEffect(() => {
    if (auth.token) {
      getCompanyActiveJobs();
    }
  }, [auth, getCompanyActiveJobs]);

  useEffect(() => {
    if (jobs.length > 0) {
      jobs.map((el, index) => {
        if (el.value == jobQueryId) {
          setJob(index);
        }
      });
    }
  }, [jobs, jobQueryId]);

  console.log(job);

  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      aria-labelledby="send-request"
      className={clsx(
        "sendRequest",
        isOpenAddJob ? "sendRequest_hidden" : "sendRequest_visible"
      )}
      {...rest}
    >
      <Modal.Title id="send-requestt">Send request</Modal.Title>

      <Modal.Body className="d-flex flex-column justify-content-center align-items-center w-100">
        <div className="mt-3 mb-5 w-100">
          <form onChange={handleSubmit(handleOnChange)}>
            <SelectField
              {...register("job", { required: true })}
              name="job"
              className="w-100"
              icon={<WorkIcon />}
              options={jobs}
              value={jobs[job]?.value}
            />
            {errors.job && <div className="error-message">Job is required</div>}
          </form>
          <Button
            className="mx-auto mb-5 py-2 text_primary"
            variant="text"
            onClick={handleToggle(true)}
          >
            Create new job+
          </Button>
        </div>
        <div className="sendRequest_actions">
          <Button
            onClick={handleClose}
            variant="outlined"
            className="my-2 py-2"
          >
            Cancel
          </Button>

          <Button onClick={onSubmit} className="my-2 py-2">
            SEND REQUEST
          </Button>
        </div>
        <AddJob
          title="Add new job"
          show={isOpenAddJob}
          handleClose={handleToggle(false)}
        />
      </Modal.Body>
    </Modal>
  );
};

SendRequest.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
